import './index.scss';

import React from 'react';
import { LanguageUtility } from 'utilities';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

export default function ShareLinkBuilder(props) {
  const localize = LanguageUtility.getLocalization;

  //ui
  const copySharingLink = function () {
    navigator.clipboard.writeText(props.dsrLink).then(() => {
      toast.success(localize(props, 'Clipboard copy success message'));
    });
  };

  let subtitle = localize(props, 'Share link dialog title', 'Share via a generated link');
  let linkPrivacyLabel = localize(props, 'Share link privacy label', 'Link Privacy');
  let accessLabel = localize(props, 'Access label', 'Access');
  let accessViewerLabel = localize(props, 'Access viewer label', 'Viewer');
  let publicLabel = localize(props, 'Public label', 'Public');
  let linkPrivacyTooltip = localize(
    props,
    'DSR public privacy only tooltip',
    'Public is the only available privacy setting for this content. All recipients will be able to view the content.'
  );
  let accessLabelTooltip = localize(
    props,
    'DSR viewer access only tooltip',
    'Viewer is the only available access setting for this content. Any recipient will be able to view the content but will be unable to edit'
  );
  let sharingLinkLabel = localize(props, 'Sharing link label', 'Sharing link');
  let copyButtonLabel = localize(props, 'Copy link button', 'Copy Link');
  let shareViaEmailLabel = localize(props, 'Or share via email label', 'Or share via email')
  let shareViaEmailButtonLabel = localize(props, 'Share via email button title', 'Share via Email');

  //render
  return (
    <div className="share-link-builder">
      <div className="content">
        <h4 className="subtitle">{subtitle}</h4>
        <div className="section info-container">
          <div className="info-column">
            <div className="title-container">
              <span className="title">{linkPrivacyLabel}</span>
              <button className="fa fa-info-circle tooltip" data-tip data-for="privacy-tooltip">
                <ReactTooltip
                  id="privacy-tooltip"
                  place={'bottom'}
                  className="react-tooltip"
                  effect="solid">
                  {linkPrivacyTooltip}
                </ReactTooltip>
              </button>
            </div>
            <div className="value">{publicLabel}</div>
          </div>
          <div className="info-column">
            <div className="title-container">
              <span className="title">{accessLabel}</span>
              <button className="fa fa-info-circle tooltip" data-tip data-for="access-tooltip">
                <ReactTooltip
                  id="access-tooltip"
                  place={'bottom'}
                  className="react-tooltip"
                  effect="solid">
                  {accessLabelTooltip}
                </ReactTooltip>
              </button>
            </div>
            <div className="value">{accessViewerLabel}</div>
          </div>
        </div>
        <div className="section link">
          <div className="title">{sharingLinkLabel}</div>
          <div className="input-container">
            <div className="input-wrap">
              <input value={props.dsrLink} disabled />
            </div>
            <button className="btn btn-primary" onClick={() => copySharingLink()}>
              <div className="fa fa-link"></div>
              <span>{copyButtonLabel}</span>
            </button>
          </div>
        </div>
      </div>
      {!props.isEmailSharingDisabled && (
        <div className="share-controls">
          <div className="main">{shareViaEmailLabel}</div>
          <button className="btn btn-cancel" style={{ backgroundColor: '#515151' }} onClick={() => props.handleShareOption('template')}>
            <span>{shareViaEmailButtonLabel}</span>
          </button>
        </div>
      )}
    </div>
  );
}
