import './_styles.scss';

import React, { useState } from 'react';
import { Api, AuthenticationContext } from "services";
import { LanguageUtility } from 'utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles, faArrowRight, faShare } from '@fortawesome/free-solid-svg-icons';
import { EmailUtility } from 'utilities';
import { toast } from 'react-toastify';
import CenteredGateWrapper from '../centered-gate-wrapper';
import { getGenericErrorMessage } from 'utilities/helper-methods';

export default function EmailAccess(props) {
  const [showInputArrowBtn, setShowInputArrowBtn] = useState(false);
  const [magicLinkRequested, setMagicLinkRequested] = useState(false);
  const [resendLinkClicked, setResendLinkClicked] = useState(false);
  const [emailInput, setEmailInput] = useState('');

  const { type } = props;
  const isEmailValidationEnabled = props.accountSettings['disableDSREmailValidation'] !== "1";
  const localize = LanguageUtility.getLocalization.bind(null, props);

  const requestMagicLink = () => {
    //Email Validation
    let isValidEmail = EmailUtility.validateEmailFormat(emailInput);
    if (isEmailValidationEnabled && !isValidEmail) {
      toast.error('Please enter a valid email');
      return;
    }

    //Check user is allowed a magic token - shallow verification on clientside
    const { privacy_setting, userDetails } = props;
    if (privacy_setting === 'PRIVATE') {
      if (!userDetails[emailInput]) {
        return props.requestAccess(emailInput);
      }
    }

    //Request the link
    Api.requestMagicLink(emailInput)
      .then((res) => {
        if (res && res.status_code == 200) {
          setMagicLinkRequested(true);
          showFailedGateMessage = false;
        }
      }).catch((err) => { });
  };

  const resendMagicLink = () => {
    try {
      requestMagicLink();
      setResendLinkClicked(true);
      toast.success(localize('Magic Link Sent Label'));
    } catch (e) {
      toast.error(`Could not send a sign-in link. ${getGenericErrorMessage()}`);
    }
  };

  const handleInputChange = (e) => {
    setShowInputArrowBtn(e.target.value.length > 0);
    setEmailInput(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    requestMagicLink()
  }

  const getPrivateGateTypeBasedLabel = (defaultKey, signInKey) => {
    switch (type) {
      case "sign-in":
        return localize(signInKey);
      default:
        return localize(defaultKey);
    }
  }

  //Check for error message to show
  const mToken = AuthenticationContext.getMagicToken();
  let showFailedGateMessage = !props.granted_magic_link_access && mToken;
  let magicLinkErrorLocalizationKey = 'Invalid magic link';
  if (mToken === "failed_linkedin") {
    magicLinkErrorLocalizationKey = 'Invalid LinkedIn magic link';
  }

  const isEasilyDismissible = type === "sign-in";

  return (
    <CenteredGateWrapper isLogoHidden={magicLinkRequested} isEasilyDismissible={isEasilyDismissible} dismissPrivacyGate={props.dismissPrivacyGate} >
      <div className="external-access-view">
        {!magicLinkRequested && (
          <div className="magic-link-container">
            <div className="information">

              {showFailedGateMessage &&
                <div className='alert-error animate__animated animate__fadeInDown'>
                  {localize(magicLinkErrorLocalizationKey)}
                </div>
              }

              <div className="title">
                {getPrivateGateTypeBasedLabel('Verified title', "Sign in to account title")}
              </div>
              <div className="message">
                {getPrivateGateTypeBasedLabel('Verified message', "Collaborate and access content label")}
              </div>
            </div>
            <div className="magic-link">
              <FontAwesomeIcon icon={faWandMagicSparkles} className="magic-link-icon" />
              <span className="magic-link-label">
                {localize('Magic Link Label')}
              </span>
            </div>

            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="input-container">
                <input
                  className="email-input standard-size"
                  placeholder={localize('Invite recipient placeholder')}
                  type={isEmailValidationEnabled ? 'email' : 'text'}
                  onChange={(e) => handleInputChange(e)}
                />
                {showInputArrowBtn && (
                  <FontAwesomeIcon
                    className="input-arrow"
                    onClick={requestMagicLink}
                    icon={faArrowRight}
                  />
                )}
              </div>
            </form>
          </div>
        )}


        {magicLinkRequested && (
          <div className="magic-link-requested animate__animated animate__fadeIn">
            <div className="information">
              <div className="title">
                <FontAwesomeIcon icon={faShare} className="share-icon" />
                {localize('Magic Link Sent Label')}
              </div>
              <div className="message">
                {localize('Magic Link Message')}
              </div>
            </div>
            <div className="seperator">
              <div className="line-div full-width-height"></div>
            </div>
            <div className="message">
              {localize('Magic Link Question')}
              <button className="btn resend-link-btn" onClick={resendMagicLink} disabled={resendLinkClicked}>
                {localize('Resend Link')}
              </button>
            </div>
          </div>
        )}
      </div>
    </CenteredGateWrapper>
  );
}
