import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTools } from '@fortawesome/free-solid-svg-icons';
import Modal from 'components/modal';
import VideoThumbnailGenerator from "./video-thumbnail-generator"
import './index.scss';

export default function VideoPosterPickerModal(props) {
  const [dialogState, setDialogState] = useState("options");

  const onFilestackImageUploaded = (data) => {
    props.onPosterUploaded(data);
    props.onRequestClose();
  }

  const onThumbnailGenerated = (url) => {
    props.onPosterUploaded(url);
    props.onRequestClose();
  }

  const onBrowseSelected = (e) => {
    props.onFilestackRequested(e, onFilestackImageUploaded, null)
  }

  return (
    <Modal id="video_poster_picker_modal" isOpen={true} onRequestClose={props.onRequestClose}>

      <div className="header">
        <h1>Pick or Create a Thumbnail</h1>
      </div>

      <div className={`video-poster-picker-modal ${dialogState}`}>

        {dialogState === "options" &&
          <div className='options'>
            <button className='option' onClick={onBrowseSelected}>
              <FontAwesomeIcon className="icon" icon={faSearch} size="2x" />
              <div className='text'>Browse</div>
            </button>
            <button className='option' onClick={() => { setDialogState("generate") }}>
              <FontAwesomeIcon className="icon" icon={faTools} size="2x" />
              <div className='text'>Generate</div>
            </button>
          </div>
        }

        {dialogState === "generate" &&
          <div className='generate'>
            <VideoThumbnailGenerator
              guid={props.guid}
              videoSrc={props.videoSrc}
              onThumbnailGenerated={onThumbnailGenerated}
              onCancel={() => { setDialogState("options") }}
            />
          </div>
        }

      </div>
    </Modal >
  );
}
