import React, { useState, useEffect} from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './_media-list.scss';
import './_dark.scss';


export default function MediaList(props) {
  const [mediaList, setMediaList] = useState([]);
  const [mediaCount, setMediaCount] = useState(0);
  const [mediaType, setMediaType] = useState('');
  const [allSelected, setAllSelected] = useState(false);
  const [selectionCount, setSelectionCount] = useState(0);

  const { mediaItemsToAdd, media } = props;

  useEffect(() => {
    //check for existing media in mediaItemsToAdd
    props.media.forEach((item) => {
      let index = mediaItemsToAdd.indexOf(item.id);
      item.checked = index >= 0;
    });

    setMediaList([...props.media]);
    setMediaCount(props.media.length);
    setAllSelected(props.mediaItemsToAdd.length !== 0);
    setSelectionCount(props.mediaItemsToAdd.length);
  }, [props.media, props.mediaItemsToAdd]);


  const handleCheckboxChange = (item) => {
    let index = mediaItemsToAdd.indexOf(item.id);
    if (index < 0) {
      mediaItemsToAdd.push(item.id)
      item.checked = true;
    } else {
      mediaItemsToAdd.splice(index, 1);
      item.checked = false;
    }

    index = mediaList.findIndex(x => x.id === item.id);
    mediaList.splice(index, 1, item);

    //update lists
    props.updatemediaItemsToAdd(mediaItemsToAdd);
    setSelectionCount(mediaItemsToAdd.length);
    setMediaList([...mediaList]);
  }

  const handleItemClick = (item) => {
    let index = mediaItemsToAdd.indexOf(item.id);
    if (index < 0) {
      mediaItemsToAdd.push(item.id)
      item.checked = true;
    } else {
      mediaItemsToAdd.splice(index, 1);
      item.checked = false;
    }

    index = mediaList.findIndex(x => x.id === item.id);
    mediaList.splice(index, 1, item);

    //update lists
    props.updatemediaItemsToAdd(mediaItemsToAdd);
    setSelectionCount(mediaItemsToAdd.length);
    setMediaList([...mediaList]);
  }

  const handleSelection = () => {
    let media_ids = [];
    mediaList.forEach((item) => {
      item.checked = !allSelected;
      media_ids.push(item.id);
    });

    // add only media_ids
    setMediaList([...mediaList]);
    setAllSelected(!allSelected);
    setSelectionCount(!allSelected ? mediaList.length : 0);
    !allSelected ? props.updatemediaItemsToAdd(media_ids) : props.updatemediaItemsToAdd([]);
  }

  const countLabel = selectionCount === 0 ? `${mediaCount} Total ${mediaType}` : `${selectionCount} Selected`;

  return (
    <div className="media-list">
      <div className="media-list-header">
        <span className="media-count">{countLabel}</span>
        {allSelected  ? (
          <button className="selection-btn" onClick={() => handleSelection()}>
            Clear All
          </button>
        ) : (
          <button className="selection-btn" onClick={() => handleSelection()}>
            Select All
          </button>
        )}
      </div>
      <div className="list-container">
        <div className="list">
          {mediaList.map((item, i) => {
            const thumbnailStyles = { backgroundImage: `url(${item.thumbnail_url})` };
            const itemStyles = item.checked ? 'list-item selected' : 'list-item'
            return (
              <div key={i} className={itemStyles} onClick={() => handleItemClick(item)}>
                <Checkbox
                  className="checkbox"
                  checked={item.checked}
                />
                <div className="media-thumb" style={thumbnailStyles}></div>
                <div className="info">
                  <div className="title">{item.title}</div>
                  <div className="type">{item.media_type.toUpperCase()}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
