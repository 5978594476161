import React from 'react';
import './index.scss';

export default function ContactTab(props) {
  return (
    <div className="loader-wrapper" style={{display: props.isActive ? 'block' : 'none'}}>
      <div className="loader"></div>
    </div>
  );
}
