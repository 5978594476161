import './index.scss';
import React, { Component } from 'react';
import { Paper, Tabs, Tab, withStyles } from '@material-ui/core';
import CommentsTab from './comments/_comments-tab';
import ContactTab from './contact/_contact-tab';
import ActivityTab from './activity/_activity-tab';
import { UserContext } from 'contexts/user-context';
import { UsersUtility, LanguageUtility } from 'utilities';
import { getSettingsFromParams, getParameterByName } from 'utilities/helper-methods';

const customTabStyle = {
  root: {
    minWidth: '45px'
  },
};

class ActionTray extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSelectedTab: "members",
    };
  }

  componentDidMount() {
    //figure out initial tab
    const openCommentsTab = getParameterByName("c") === "1";
    if (openCommentsTab) {
      this.setState({ userSelectedTab: "comments" });
      this.props.onStatePropertyChanged('isActionTrayOpen', true);
    }
  }

  getTabs() {
    const specialParameterSettings = getSettingsFromParams();
    const { activityFilters, hideMyActivity } = this.state;
    let { isOwner, isLoading, settings } = this.props;

    const showMembersTab = settings.showMembers && isOwner;
    const showActivity = settings.showActivity && isOwner;
    const showCommentsTab = settings.enableCommentsTab && !isLoading;

    //labels
    const localize = LanguageUtility.getLocalization.bind(null, this.props);

    //Members/Contact Tab
    let tabs = [{
      key: "members",
      label: showMembersTab ? localize("Members tab header", "Members") : localize("DSR contact me tab header"),
      component: <ContactTab {...this.props} />
    }];

    //Activity
    if (showActivity) {
      tabs.push({
        key: 'activity',
        label: localize('Activity tab header', 'Activity'),
        component: (
          <ActivityTab {...this.props}
            activeFilters={activityFilters}
            hideMyActivity={hideMyActivity}
            preserveActivityFilters={this.preserveActivityFilters} />
        )
      });
    }

    //Comments
    if (showCommentsTab && specialParameterSettings.showComments !== false) {
      tabs.push({
        key: 'comments',
        label: localize('Comments tab header', 'Comments'),
        component: <CommentsTab {...this.props} />
      });
    }

    return tabs;
  }

  //Events
  handleTabChange = (event, userSelectedTab) => {
    this.setState({ userSelectedTab });
  };

  getMentionableUsers = () => {
    let mentionableUsers = [];
    const everyoneObject = {
      id: 'everyone',
      display: '@everyone'
    };
    mentionableUsers.push(everyoneObject);

    //loop through all the users emails attached to the DSR
    this.props.users.all.forEach((user) => {
      const userObject = {
        id: user,
        display: '@' + user.substr(0, user.indexOf('@'))
      };
      mentionableUsers.push(userObject);
    });
    return mentionableUsers;
  };

  preserveActivityFilters = (filters, hideMyActivity) => {
    this.setState({
      activityFilters: filters,
      hideMyActivity
    });
  };

  //Render
  render() {
    const { userSelectedTab } = this.state;
    const { medias, classes, accountTheme, isOpen } = this.props;
    const user = this.context; //TODO: stop props passing this

    //props
    const mentionableUsers = this.getMentionableUsers();
    const newProps = {
      accountTheme: this.props.accountTheme,
      mentionableUsers: mentionableUsers,
      user: this.props.user,
      medias: this.props.medias,
      isLoading: this.props.isLoading,
      salesContact: this.props.salesContact,
      settings: this.props.settings,
      isOwner: this.props.isOwner,
      userDetails: this.props.userDetails,
      pageActivity: this.props.pageActivity,
      language: this.props.language
    };

    //styles
    const actionTrayClasses = 'action-tray ' + (isOpen ? 'open' : '');
    const customTabStyles = {
      fontWeight: 'bold',
      whiteSpace: 'nowrap'
    };

    //tabs
    const tabs = this.getTabs();
    const activeTabKey = tabs.filter(x => x.key === userSelectedTab).length > 0 ? userSelectedTab : "members";

    return (
      <div className={actionTrayClasses}>
        <div className="tabs-container">
          <Paper className="tabs-root">
            <Tabs
              value={activeTabKey}
              onChange={this.handleTabChange}
              variant="fullWidth"
              TabIndicatorProps={{ className: "active-tab-indicator" }}>
              {tabs.map((tab) => (
                <Tab
                  key={tab.key}
                  value={tab.key}
                  label={tab.label}
                  style={customTabStyles}
                  medias={medias}
                  className={tabs.length > 2 ? classes.root : null}
                  accounttheme={accountTheme}
                />
              ))}
            </Tabs>
          </Paper>
          {tabs.map((tab) => activeTabKey === tab.key ? (
            <div className="tab-container" key={tab.key}>
              {React.cloneElement(tab.component, newProps, user)}
            </div>
          ) : null
          )}
        </div>
      </div>
    );
  }
}

ActionTray.contextType = UserContext;
export default withStyles(customTabStyle)(ActionTray);
