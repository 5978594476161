import LocalStorageModule from 'modules/local-storage-module';
import Api from '../services/api';

const searchHistoryStorage = new LocalStorageModule("searchHistory", false, false);

export default class SearchUtility {
  static SEARCH_HISTORY_LIMIT = 6;

  static getRecentSearchHistory() {
    return searchHistoryStorage.get();
  }

  static search(searchTerm, tag) {
    return Api.search(searchTerm, tag).then((searchResults) => {
      this.addRecentSearchTerm(searchTerm, tag);
      return searchResults;
    });
  }

  //TODO: make this internal/private
  static addRecentSearchTerm(query = null, tag = null) {
    let title = tag || query;
    let type = tag ? 'tag' : 'query';
    let isQueryBlank = type === "query" && (!query || !query.trim());
    let recentlySearchedTerms = searchHistoryStorage.get();

    let alreadyExists = false;
    if (Array.isArray(recentlySearchedTerms)) {
      let index = recentlySearchedTerms.findIndex(term => {
        return term.title === title && term.type === type;
      });
      alreadyExists = index >= 0;
    } else {
      recentlySearchedTerms = [];
    }

    if (isQueryBlank || alreadyExists) return;

    if (typeof title === 'object') title = title.join(', ');

    let recentQuery = {
      title: title.trim(),
      type: type
    }

    if (recentlySearchedTerms.length > this.SEARCH_HISTORY_LIMIT - 1) {
      recentlySearchedTerms.pop();
    }

    recentlySearchedTerms.splice(0, 0, recentQuery);
    searchHistoryStorage.set(recentlySearchedTerms);
  }
}
