import { getCurrentGuid, base64Encode } from 'utilities/helper-methods';

const _getFollowupUrl = (yParam) => {
    let originURL = window.location.origin;
    if (originURL.indexOf('localhost') > -1) {
        originURL = 'https://followups.gomodus.com';
    }
    return `${originURL}/${getCurrentGuid()}?y=${base64Encode(yParam)}`;
}

export class BaseEmailTemplate {
    constructor(user, yParam = null) {
        if (!user) user = "";
        if (!yParam) yParam = user;

        this.baseUrl = `${window.location.origin}/${getCurrentGuid()}`;
        this.hashedUser = base64Encode(user);
        this.followupUrl = _getFollowupUrl(yParam);

        this.user = user;
    }

    _getHTMLReplacementMap = () => {
        return {
            USER: this.user,
            FOLLOWUP_URL: this.followupUrl,
            PREFERENCES_URL: `${this.followupUrl}/preferences`,
            DISCLAIMER: 'This email is a notification only and is not a monitored inbox. Please do not respond to this email.',
        }
    }
}

export class RequestAccessEmailTemplate extends BaseEmailTemplate {
    constructor(user, owner, dsrTitle, companyLogo, message) {
        super(user);

        this.owner = owner;
        this.hashedOwner = base64Encode(owner);
        this.dsrTitle = dsrTitle || `Your digital sales room at: ${this.followupUrl}`;
        this.companyLogo = companyLogo;
        this.message = message;
        this.strippedEmail = user.replace(/@.*/, '');
    }

    getHTMLReplacementMap = () => {
        let baseMap = this._getHTMLReplacementMap();

        return {
            ...baseMap,
            TITLE: this.dsrTitle,
            COMPANY_LOGO: this.companyLogo,
            REQUEST_ACCESS_URL: `${this.baseUrl}?r=${this.hashedUser}&y=${this.hashedOwner}`,
            ACCESS_MESSAGE: this.message,
            STRIPPED_EMAIL: this.strippedEmail
        }
    }
}

export class InviteEmailTemplate extends BaseEmailTemplate {
    constructor(user, sendToEmail, company, customHtml) {
        super(user, sendToEmail);

        this.sendToEmail = sendToEmail;
        this.company = company;
        this.customHtml = customHtml;
    }

    getHTMLReplacementMap = () => {
        let baseMap = this._getHTMLReplacementMap();

        return {
            ...baseMap,
            COMPANY_NAME: this.company,
            CUSTOM_HTML: this.customHtml
        }
    }
}

export class AccessGrantedEmailTemplate extends RequestAccessEmailTemplate {
    constructor(user, owner, dsrTitle, companyLogo) {
        super(user, owner, dsrTitle, companyLogo);
    }
}
