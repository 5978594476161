import "./_contact-tab.scss";
import React, { useState, useContext, useEffect } from 'react';
import Api from 'services/api';
import { SalesContactContext } from 'contexts/sales-contact-context';
import { UserContext } from 'contexts/user-context';
import ReactTooltip from 'react-tooltip';
import Avatar from '../avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEnvelope, faStar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { capitalize } from 'lodash';
import {
  getGenericErrorMessage,
  truncate,
  getLanguageCodeForMoment,
} from 'utilities/helper-methods';

import { LanguageUtility } from "utilities";

import moment from 'moment';
import { toast } from 'react-toastify';

export default function ContactTab(props) {
  const context = useContext(SalesContactContext);
  const user = useContext(UserContext);
  const [showMemberDetails, setShowMemberDetails] = useState(false);
  const [userDetails, setUserDetails] = useState('');
  const [memberDetails, setMemberDetails] = useState('');
  const [promptForDelete, setPromptForDelete] = useState(false);
  const [hasRenderedBefore, setHasRenderedBefore] = useState(false);

  const salesContact = context ? context : {};
  const { isOwner, accountTheme, isLoading, settings, users } = props;
  const { notificationRecipients = [] } = users;
  const showMembersTab = settings.showMembers && isOwner;
  const showNotificationRecipients = showMembersTab && (notificationRecipients.length > 0);

  const localize = LanguageUtility.getLocalization.bind(null, props);

  //sort and set the current "memebers" by last_activity
  const members = props.users.detailed.sort((a, b) =>
    (b.last_activity !== null) - (a !== a.last_activity) ||
    new Date(b.last_activity) - new Date(a.last_activity)
  ).sort((a, b) => (b.status !== 'removed') - (a.status !== 'removed'));

  //Life Cycle -ug
  useEffect(() => {
    setUserDetails(props.userDetails);
  }, [props.userDetails]);

  //ug
  useEffect(() => {
    setHasRenderedBefore(true);
  }, []);

  //Helpers
  const getMemberStatus = (member) => {
    let status = "Inactive";
    if (member?.status === 'removed') {
      return 'Inactive, removed.';
    }

    if (member?.last_activity) {
      status = `${localize('Last active text')} ${moment
        .utc(member.last_activity)
        .locale(getLanguageCodeForMoment(props.language))
        .fromNow()}`;
    }

    return status;
  }

  const getSalesContactLabel = () => {
    if (salesContact.first_name && salesContact.last_name) {
      return salesContact.first_name + ' ' + salesContact.last_name;
    }
    return props.owner;
  };

  //UI Interactions
  const handleMemberRowClick = function (e, index) {
    e.preventDefault();
    const memberDetails = members[index];
    setMemberDetails(memberDetails);
    setShowMemberDetails(true);
  };

  const handleRemoveMember = function (e, member) {
    if (!promptForDelete) {
      setPromptForDelete(true);
      return;
    }
    e.preventDefault();

    //Update the UI
    const index = members.indexOf(member);
    let updatedMembers = [...members];
    updatedMembers[index].status = 'removed';
    props.onUsersUpdate(updatedMembers);
    setPromptForDelete(false);

    //Call the api and remove the members data.
    Api.updateMemberStatus(member.email, 'removed').then((res) => {
      setShowMemberDetails(false);
      toast.success(`${member.email.toLowerCase()} removed successfully.`);
    }).catch((err) => {
      console.error(err);
      toast.error(`Failed to remove ${member.email}. ${getGenericErrorMessage()}`);
    });
  };

  const handleBackToMembersClick = function (e) {
    e.preventDefault();
    setShowMemberDetails(false);
    setPromptForDelete(false);
    setMemberDetails('');
  };

  const emailClick = function (e, email) {
    e.stopPropagation();
    const str = 'mailto:' + email;
    window.open(str);
  };

  const removeNotificationRecipient = function (e, email) {
    e.stopPropagation();
    Api.removeNotificationRecipient(props.guid, email).then(() => {
      props.onUsersUpdate(notificationRecipients.filter((x) => x !== email), "notificationRecipients")
      toast.success(`${email.toLowerCase()} removed successfully.`);
    }).catch(() => {
      toast.error(`Failed to remove ${email}. ${getGenericErrorMessage()}`);
    });
  };

  //Sub Components
  const ContactHeaderRow = function (props) {
    const { isEmphasized, value } = props;
    let skeletonClasses = 'contact-header-row skeleton';
    let classes = `contact-header-row ${hasRenderedBefore ? '' : 'animate fadeIn'}`;

    if (isEmphasized) {
      classes += ' emphasized';
      skeletonClasses += ' emphasized';
    }

    if (isLoading) {
      return <div className={skeletonClasses}></div>;
    }

    if (!value) {
      return null;
    }
    return <div className={classes}>{value}</div>;
  };

  const ContactDetailRow = function ({ label = "", ...props }) {
    const showLabelTooltip = label.length >= 9;
    let skeletonClasses = 'row skeleton';
    let classes = `row ${hasRenderedBefore ? '' : 'animate fadeIn'}`;
    if (isLoading) {
      return <div className={skeletonClasses}></div>;
    }
    return !props.value ? null : (
      <div className={classes}>
        <div
          className="label"
          data-for="contact-detail-label-tooltip"
          data-tip={showLabelTooltip ? label.toUpperCase() : null}>
          {truncate(label, 9)}
        </div>
        {showLabelTooltip && <ReactTooltip id="contact-detail-label-tooltip" />}
        <div className="value">{props.clickable ? <a className="mailto" href={`mailto:${props.value}`}>{props.value}</a> : props.value}</div>
      </div>
    );
  };

  const MemberAvatar = (props) => {
    const { email } = props;
    const presenceClass = userDetails ? userDetails[email]?.presence : '';
    const tooltipContent = capitalize(presenceClass);
    const tooltipId = `presence-tooltip-${email}`;

    return (
      <div className="member-avatar"      >
        <Avatar user={email} />
        <div className={`member-presence ${presenceClass}`} data-tip data-for={tooltipId} />
        <ReactTooltip className='presence-tooltip' id={tooltipId}>
          {tooltipContent}
        </ReactTooltip>
      </div>
    );
  };

  const NotificationRecipients = function () {
    return (
      <div className='member-list'>
        {notificationRecipients.map((email, index) => {
          return (<div
            className={`member-row`}
            key={index}
            onClick={(e) => handleMemberRowClick(e, index)}>
            <MemberAvatar className="member-avatar" email={email} />
            <div className="member-details">
              <div
                className="member-email"
                data-for="email-tooltip"
                data-tip={email.toLowerCase()}>
                {truncate(email.toLowerCase(), 24)}
              </div>
              {true && <ReactTooltip id="email-tooltip" />}
            </div>
            <button className="btn-delete" onClick={(e) => removeNotificationRecipient(e, email)}>
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </button>
          </div>);
        })}
      </div>
    )
  }

  const Members = () => {
    return (
      <div className="member-list">
        {members.map((member, index) => {
          const memberStatus = getMemberStatus(member);
          const showTooltip = member.email.length >= 24;
          return (
            <div className={`member-row ${hasRenderedBefore ? '' : 'animate__animated animate__fadeIn'} `}
              key={index}
              onClick={(e) => handleMemberRowClick(e, index)}>
              <MemberAvatar className="member-avatar" email={member.email} />
              <div className="member-details">
                <div
                  className="member-email"
                  data-for="email-tooltip"
                  data-tip={showTooltip ? member.email.toLowerCase() : null}>
                  {truncate(member.email.toLowerCase(), 24)}
                </div>
                {showTooltip && <ReactTooltip id="email-tooltip" />}
                <div className="member-activity">{memberStatus}</div>
              </div>
              <div
                className="contact-me-button"
                onClick={(e) => emailClick(e, member.email.toLowerCase())}>
                <FontAwesomeIcon icon={faEnvelope} size="sm" />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const MemberDetails = function () {
    const memberStatus = getMemberStatus(memberDetails);
    const isRemoved = memberDetails.status === 'removed';

    return (
      <div
        className={`member-details-container ${hasRenderedBefore ? '' : 'animate__animated animate__fadeIn'
          } `}>
        <div className="top-row" onClick={(e) => handleBackToMembersClick(e)}>
          <button className="btn-back btn-no-style">
            <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            <span>
              {localize('Back to all members button')}
            </span>
          </button>
        </div>
        <div className="member-details-content">

          <Avatar
            accountTheme={accountTheme}
            user={memberDetails.email}
            userDetails={props.userDetails} />

          <div id="member-email">{memberDetails.email.toLowerCase()}</div>
          <div className="last-activity">{memberStatus}</div>
          {promptForDelete && (
            <div className="member-delete-prompt animate__animated animate__slideInLeft">
              Remove {memberDetails.email} from this conversation?
            </div>
          )}

          {isOwner && !isRemoved && (
            <button
              className="btn btn-primary btn-remove-member"
              onClick={(e) => handleRemoveMember(e, memberDetails)}
              style={{
                backgroundColor: accountTheme.primaryColor,
                color: accountTheme.secondaryColor
              }}>
              Remove Member
            </button>
          )}

        </div>
      </div>
    );
  };

  //Render
  const salesContactName = getSalesContactLabel();

  //Member Render Case
  if (showMemberDetails) {
    return (
      <div className="contact-tab">
        <MemberDetails />
      </div>
    );
  }

  //Default
  return (
    <div className="contact-tab">
      <div className="contact-header">

        <Avatar
          isLoading={isLoading}
          accountTheme={accountTheme}
          user={user?.email}
          userDetails={props.userDetails}
          useRandomColor={false}
          isHighlighted={isOwner}
          profilePicture={salesContact.profile_picture}
          hasRenderedBefore={hasRenderedBefore}
        />

        <div className="contact-header-name-container">
          <ContactHeaderRow value={salesContactName} isEmphasized={true} />
          {!isLoading && (
            <div
              className="admin-star-tooltip"
              data-for="admin-tooltip"
              data-tip={'Digital Sales Room Admin'}
              place={'top'}>
              <FontAwesomeIcon
                id="star-icon"
                size="xs"
                icon={faStar}
                color="#ffffff"
                style={{ borderRadius: '50%', padding: '4px', backgroundColor: '#4182FF' }}
              />
            </div>
          )}
          {!isLoading && <ReactTooltip id="admin-tooltip" effect="solid" clickable />}
        </div>
        <ContactHeaderRow
          value={settings.companyName ? settings.companyName : salesContact.company} />
        <ContactHeaderRow value={salesContact.jobTitle} />
      </div>
      <div className="contact-details">
        <ContactDetailRow
          label={localize('Sales contact email label', 'Email')}
          value={salesContact.email}
          clickable={true} />
        <ContactDetailRow
          label="DO NOT RENDER"
          value={salesContact.doNotRender} />
        <ContactDetailRow
          label={localize('Sales contact phone label', 'Phone')}
          value={salesContact.phone_number}
        />
        <ContactDetailRow
          label="address"
          value={salesContact.address} />
        <ContactDetailRow
          label={localize('Sales contact city label', 'City')}
          value={salesContact.city} />
        <ContactDetailRow
          label={localize('Sales contact state label', 'State')}
          value={salesContact.state} />
        <ContactDetailRow label="zip" value={salesContact.zip} />
      </div>

      {showMembersTab && (
        <div className={`members-container`}>
          <div className="members-header">
            {`${localize('Member list subheader')} (${members.length})`}
          </div>
          <Members />
        </div>
      )}
      {showNotificationRecipients && (
        <div className={`notification-recipients-container`}>
          <div className="members-header">
            {`${localize('Notification Recipients list subheader')} (${notificationRecipients.length})`}
          </div>
          <NotificationRecipients />
        </div>
      )}
    </div>
  );
}
