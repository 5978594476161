import { getCurrentGuid, base64Encode, getCacheBustKey } from './helper-methods';
import Api from 'services/api';
import Restricted from '../assets/restricted_email_domains.json';
import { LanguageUtility } from "utilities";
import { toast } from 'react-toastify';

export default class EmailUtility {
  static getFollowupUrl(user) {
    let originURL = window.location.origin;
    if (originURL.indexOf('localhost') > -1) {
      originURL = 'https://followups.gomodus.com';
    }
    return `${originURL}/${getCurrentGuid()}?y=${base64Encode(user)}`;
  }

  //Email Validations
  static async validateEmail(email) {
    const result = { valid: true, reason: null };
    if (!email) {
      result.reason = "EMPTY";
    }

    if (!this.validateEmailFormat(email)) {
      result.reason = "FORMAT";
    }

    if (!this.validateDomain(email)) {
      result.reason = "DOMAIN";
    }

    const validatedBySendgrid = await Api.validateWithSendgrid(email);
    if (!validatedBySendgrid) {
      result.reason = "SENDGRID";
    }

    result.valid = !!!result.reason;
    return result;
  }
  static validateDomain(email) {
    const emailParts = email.split('@');
    const domain = emailParts[1] ? emailParts[1] : null;

    //check against a list of Modus competitor domains
    const domains = Restricted.domains;
    const isCompetitorDomain = domains.includes(domain);

    //check against a list of regexs that represent black listed domains
    const regexes = Restricted.regexes;
    let regexMatch = false;
    regexes.forEach((expr) => {
      const regex = new RegExp(expr);
      const isMatch = regex.test(domain);
      if (isMatch) regexMatch = true;
    });
    return domain && !isCompetitorDomain && !regexMatch;
  }
  static validateEmailFormat(email) {
    const re = new RegExp("^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*");
    return re.test(email.toLowerCase());
  }

  //Prebuilt Template Senders
  static async sendInviteEmail(inviteEmailProps) {
    const inviteBody = await EmailUtility.getEmailHtmlFromTemplate('invite', inviteEmailProps);
    const obj = {
      send_to: inviteEmailProps.sendToEmail,
      from: inviteEmailProps.user,
      body: inviteBody,
      subject: `Materials from ${inviteEmailProps.company}`,
      type: 'invited'
    };


    try {
      await Api.sendNotificationEmail(obj);
      return { success: true };
    } catch (ex) {
      console.error("invite email failed: ", ex);
      return { success: false, error: ex };
    }
  }
  static async sendRequestAccessEmail(requestAccessProps, subject = 'Digital Sales Room Access Requested', type = 'invite',) {
    //TODO: this request access link needs to be cleaned up in a way that actual uses a token
    const accessRequestBody = await this.getEmailHtmlFromTemplate('access_request', requestAccessProps);

    const obj = {
      send_to: requestAccessProps.owner,
      from: 'notice@gomodus.com',
      body: accessRequestBody,
      subject: subject,
      type: type
    };

    let result = true;

    try {
      await Api.sendNotificationEmail(obj);
      return { success: true };
    } catch (ex) {
      result = false;
      console.error("request access email failed: ", ex);
      toast.error('Something went wrong. If this issue persists, please contact support@gomodus.com');
      return { success: false, error: ex };
    }
  }
  static async sendAccessApprovedNotificationEmail(accessGrantedProps) {
    const body = await this.getEmailHtmlFromTemplate('access_granted', accessGrantedProps);
    const email = {
      send_to: accessGrantedProps.user,
      from: accessGrantedProps.owner,
      body: body,
      subject: 'Your request has been approved',
      type: 'invited'
    };

    let result = true;

    try {
      await Api.sendNotificationEmail(email);
    } catch (ex) {
      result = false;
      toast.error('Something went wrong. If this issue persists, please contact support@gomodus.com');
    }

    return result;
  }

  //Send Grid Email Builders
  static async getEmailHtmlFromTemplate(templateName, emailProps) {
    const replacementMap = emailProps.getHTMLReplacementMap();

    //get the template
    let cacheKey = getCacheBustKey()
    cacheKey = cacheKey ? `/${cacheKey}` : "";
    let response = await fetch(`${cacheKey}/templates/${templateName}.html`);
    let html = await response.text();

    Object.keys(replacementMap).forEach((key) => {
      var regex = new RegExp(`{%${key}%}`, "g");
      html = html.replace(regex, replacementMap[key]);
    });

    return html;
  }

  //TODO: get this out of here.
  //--- Mail To Link Builder Functions ---//
  static generateBcc(accountSettings) {
    let settingsToCheckAgainst = ['isTrackingBccAddressDisabled', 'bccAddress'];
    let settings = {};

    settingsToCheckAgainst.forEach((key) => {
      if (key in accountSettings) {
        settings[key] = parseInt(accountSettings.key);
      }
      settings[key] = null;
    });

    let bccs = [];

    //get the separator - proper separator is comma no space
    const isOutlookDefaultEmailClient = settings['web_windows_isOutlookDefaultEmailClient'];
    let separator = isOutlookDefaultEmailClient ? ';' : ',';

    //custom email sepeator override
    const customEmailSeperator = settings['web_emailSeperatorForMailToLink'];
    if (customEmailSeperator) separator = customEmailSeperator;

    //encode the separator for use.
    separator = encodeURIComponent(separator);

    //add the tracking address
    const hideTrackingBccAddress = settings['isTrackingBccAddressDisabled'];
    const isBccTrackingDisabled = hideTrackingBccAddress && hideTrackingBccAddress == 1;
    if (!isBccTrackingDisabled) {
      bccs.push('analytics@mailtrack.appdataroom.com');
    }

    //BCC set in Media manager
    const bccMediaManager = settings['bccAddress'];
    if (bccMediaManager) {
      bccs.push(bccMediaManager);
    }

    //create string
    let bccLine = bccs.join(separator);
    return bccLine.replaceAll(' ', '%20');
  }

  static generateEmailBody(items, template, dsrLink, accountSettings) {
    let body = template.plaintext.replace(/(?:\r\n|\r|\n)/g, '%0D%0A');

    let useMultipleLinksInDSREmail = parseInt(
      accountSettings['useMultipleLinksInDSREmail']
    );

    if (items) {
      //Loop through items to create list
      let linkContent = !useMultipleLinksInDSREmail ? (dsrLink + '%0D%0A') : '';
      items.forEach((item, i) => {
        useMultipleLinksInDSREmail ?
          linkContent += (item.title + ' - ' + dsrLink + '%20').replaceAll(' ', '%20')
          :
          linkContent += (item.title + '%20');

        if (i < items.length - 1) {
          linkContent += '%0D%0A';
        }
      });

      //format body with link content
      const breakReg = new RegExp(/(?:\\[rn]|[\r\n]+)+/g);
      let formattedBody = body.replace('%1$s', linkContent);
      formattedBody = formattedBody
        .replaceAll(breakReg, '%0D%0A')
        .replaceAll('?', '%3F')
        .replaceAll('&', '%26');

      return Promise.resolve(formattedBody);
    }

    return Promise.resolve('');
  }

  static union(x, y) {
    // TODO: move to helpers
    let obj = {};
    for (let i = x.length - 1; i >= 0; --i) obj[x[i]] = x[i];
    for (let i = y.length - 1; i >= 0; --i) obj[y[i]] = y[i];
    let res = [];
    for (const k in obj) {
      if (obj.hasOwnProperty(k))
        // <-- optional
        res.push(obj[k]);
    }
    return res;
  }

  static getTemplateIds(followupItems) {
    let templateIds = [];

    for (let i = 0; i < followupItems.length; i++) {
      let item = followupItems[i];
      item.followup_templates = item.followup_templates || [];
      if (templateIds.length === 0 || item.followup_templates.length === 0) {
        templateIds = this.union(templateIds, item.followup_templates);
      } else {
        templateIds = [...new Set(templateIds.concat(item.followup_templates))];
      }
    }

    return templateIds;
  }

  static getDefaultTemplate(props) {
    const localize = LanguageUtility.getLocalization;
    return {
      id: 0,
      title: "Default Email Template",
      subject: localize(props, 'Email default subject', 'Subject'),
      plaintext: localize(props, 'Email template plaintext', 'Text'),
    }
  }

  static getTemplatesByIds(templateIds, templates) {
    templates = templates
      .filter(function (template) {
        return templateIds.indexOf(template.id) > -1;
      })
      .map((template) => {
        return {
          id: template.id,
          title: template.title,
          subject: template.subject,
          plaintext: template.plaintext || ''
        };
      });
    return templates;
  }

  static getEmailTemplates(itemsToShare, templates, accountSettings, dsrLink, props) {
    let templateIds = this.getTemplateIds(itemsToShare, templates);
    templates = this.getTemplatesByIds(templateIds, templates);
    templates.push(this.getDefaultTemplate(props));

    let templatePromises = templates.map((template) => {
      return this.generateMailToLink(itemsToShare, template, dsrLink, accountSettings).then(
        (mailto) => {
          template.mailto = mailto;
          return template;
        }
      );
    });
    return Promise.all(templatePromises);
  }

  static validateMailTo(items, template, templates, accountSettings) {
    const OUTLOOK_CHARACTER_LIMIT = 2000;

    if (!Array.isArray(items)) items = [items];
    if (!items || items.length === 0) {
      return Promise.resolve(false);
    }

    let isOutlookDefaultEmailClient = parseInt(
      accountSettings['web_windows_isOutlookDefaultEmailClient']
    );

    if (isOutlookDefaultEmailClient === 1) {
      let dsrStubbLink = 'https://followup.gomodus.com/aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa';
      let mailto = template.mailto;

      let itemsCount = items.length;

      if (mailto.length > OUTLOOK_CHARACTER_LIMIT && itemsCount > 1) {
        let difference = mailto.length - OUTLOOK_CHARACTER_LIMIT;

        let averageTitleLength =
          items.map((x) => x.title.length).reduce((a, b) => a + b) / items.length;
        let averageLineItemLength = averageTitleLength + ' - '.length + dsrStubbLink.length;

        let itemsToRemove = Math.ceil(difference / averageLineItemLength);
        itemsToRemove = Math.min(itemsCount - 1, itemsToRemove);
        return itemsToRemove;
      }
    }

    return [];
  }

  static generateMailToLink(items, template, dsrlink, accountSettings) {
    let bccPromise = this.generateBcc(accountSettings);
    let bodyPromise = this.generateEmailBody(items, template, dsrlink, accountSettings);

    return Promise.all([bccPromise, bodyPromise]).then((data) => {
      let bcc = data[0];
      let body = data[1];
      const subject = template.subject.replaceAll('&', '%26').replaceAll(' ', '%20');

      if (!body) return '';

      let mailtoLink = 'mailto:?bcc=' + bcc + '&body=' + body + ' %0D%0A&subject=' + subject;
      return mailtoLink;
    });
  }

  //--- End of Mail To Link Builder Functions ---//

}
