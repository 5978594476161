import './index.scss';
import React, { useState } from 'react';
import Footer from 'components/footer';
import PasscodeGate from './passcode-gate';
import LinkedinCallback from './linkedin-callback';
import ExternalAuthentication from './external-authentication-gate';
import RequestAccessGate from './request-access-gate';

export default function PrivacyGate(props) {
  //TODO update this to use the router
  const [requestAccessEmail, setRequestAccessEmail] = useState(null);
  const [isRequestAccessGate, setIsRequestAccessGate] = useState(false);

  const openRequestAccessGate = (userEmail = null, forceClose = false) => {
    setRequestAccessEmail(forceClose ? null : userEmail);
    setIsRequestAccessGate(forceClose ? false : true);
  }

  const { type } = props.privacyGateSettings;

  const isValidatingLinkedin = window.location.pathname.indexOf('linkedin/callback') > -1;
  const isPasscodeGated = !isRequestAccessGate && props.privacy_setting === "PRIVATE-PASSCODE";
  const isGeneralGated = !isRequestAccessGate && !isPasscodeGated && !isValidatingLinkedin;

  return (
    <div className="privacy-gate">
      <div className="privacy-overlay"></div>
      <div className="blur-overlay"></div>

      <div className="body">
        {isPasscodeGated && <PasscodeGate {...props} requestAccess={openRequestAccessGate} />}

        {isGeneralGated &&
          <ExternalAuthentication {...props}
            type={type}
            requestAccess={openRequestAccessGate} />
        }

        {isValidatingLinkedin && <LinkedinCallback {...props} />}

        {isRequestAccessGate &&
          <RequestAccessGate  {...props}
            email={requestAccessEmail}
            requestAccess={openRequestAccessGate} />
        }
      </div>
      <Footer {...props} privacy={true} />
    </div>
  );
}
