import React, { useState, useContext } from 'react';
import filesize from 'filesize';
import AppNotesModal from 'components/app-notes-modal';
import { UserContext } from 'contexts/user-context';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faDownload, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Api from 'services/api';
import { truncate } from '../../utilities/helper-methods';

export default function ViewerInfoBar(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { email: userEmail } = useContext(UserContext);

  if (!props.media) {
    return null;
  }

  let media = props.media;
  let { title, size } = media;
  const isSmallScreen = window.innerWidth < 768;
  const showTitleTooltip = title.length >= 26;
  let hasAppNotes = media.hasOwnProperty('app_notes') && media.app_notes;

  let formattedSize = size ? filesize(size, { round: 1 }) : null;
  const downloadable = media.url && (media?.external_access_options?.download ?? true);

  const onAboutButtonClicked = function (e) {
    const newValue = !isModalOpen;
    setIsModalOpen(newValue);
  };

  const onRequestClose = function (e) {
    setIsModalOpen(!isModalOpen);
  };

  const handleDownloadClick = async function (e) {
    e.preventDefault();

    let isValid = await props.validateOrGateUser(true);
    if (isValid) {
      //analtyics
      if (media && props.guid) {
        Api.sendMediaAction(media, 'download', userEmail);
      }

      //open download window
      window.open(media.url, "_blank");
    }
  };

  //NOTE: the title truncation could probably be managed all in css
  return (
    <div className="viewer-info-bar animate fadeInDown">
      <button className="btn back-btn" onClick={props.onBackButtonClicked}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <div
        className="title-container"
        data-for="viewer-title-tooltip"
        data-tip={showTitleTooltip ? title : null}>
        {isSmallScreen ? truncate(title, 26) : title}
      </div>
      {showTitleTooltip && <ReactTooltip id="viewer-title-tooltip" place="bottom" />}
      <div className="size-container">{formattedSize}</div>
      <div className="options-container">
        {media.url && downloadable && (
          <a
            id="download-icon"
            href="#"
            onClick={handleDownloadClick}
            rel="noopener noreferrer"
            download>
            <FontAwesomeIcon icon={faDownload} />
          </a>
        )}
        {hasAppNotes && (
          <FontAwesomeIcon
            id="notes-icon"
            icon={faInfoCircle}
            onClick={onAboutButtonClicked}
          />
        )}
      </div>
      <AppNotesModal
        isOpen={isModalOpen}
        notes={props.media.app_notes}
        onRequestClose={onRequestClose}
      />
    </div>
  );
}
