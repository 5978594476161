import Api from 'services/api';
import LocalStorageModule from 'modules/local-storage-module';
import { getParameterByName, removeURLParameter, hasAccessToken, base64Decode } from './helper-methods';

const _storedUserEmail = new LocalStorageModule("y", false, true); //TODO: eventually make this internal

export default class UsersUtility {

  //Initialize Helpers
  static #_initializeUserEmail = (recipient, viewed) => {
    let email = _storedUserEmail.get();

    //Argument manipulation
    if (!Array.isArray(recipient)) {
      recipient = recipient ? [recipient] : [];
    }

    //Query Parameter User Email
    const userParam = getParameterByName('y');
    if (userParam) {
      removeURLParameter('y');
      return base64Decode(userParam);
    }

    //Use Case: if a user is the first person to visit then we assume they are the intended recipient.
    if (!viewed && recipient.length > 0) {
      email = recipient[0];
      this.setNewUserEmail(email);
    }

    return email;

  }
  static #_findUserInDetailedUsers = (userEmail, initialAppData) => {
    const allDetailedUsers = initialAppData?.users?.detailed || [];
    let detailedUser = allDetailedUsers.find(element => {
      return element.email === userEmail;
    })

    //fallback
    if (!detailedUser) {
      detailedUser = { email: userEmail }
    }

    //Apend some additional properties
    detailedUser.isOwner = false;//(userEmail === initialAppData.user.email || userEmail === initialAppData.owner) && hasAccessToken();
    detailedUser.isAdmin = false//detailedUser.isOwner;

    return detailedUser;
  }

  //User Initialization
  static async initialize(initialAppData) {
    const { recipient, viewed } = initialAppData;

    //Authenticated User
    if (hasAccessToken()) {
      let user = await Api.getUserDetails();
      user.isOwner = user.email === initialAppData.owner;
      user.isAdmin = user.isOwner;
      return user;
    }

    //Not Authenticated User - figure out the user email
    let userEmail = this.#_initializeUserEmail(recipient, viewed);
    let userObject = this.#_findUserInDetailedUsers(userEmail, initialAppData);

    return userObject;
  }

  //Stored User

  //this can be removed
  static getStoredUserEmail() {
    return _storedUserEmail.get();
  }
  static setNewUserEmail(userEmail = null) {
    if (!userEmail) {
      const userParam = getParameterByName('y');
      removeURLParameter("y");
      userEmail = userParam ? base64Decode(userParam) : null;
    }

    if (userEmail) {
      _storedUserEmail.set(userEmail);
    }
  }

  //User Helpers
  static async createInitialUserDetails(initialAppData) {
    if (!initialAppData?.users?.detailed) {
      return {};
    }

    //build the userDetails map
    let users = initialAppData.users.detailed || [];
    let userDetailsMap = {};
    users.forEach(user => {
      if (user.email) { userDetailsMap[user.email] = user; }
    });

    //update details for the owner
    //TODO: i don't think this needs to exist, there should just be an owner object that does this.
    let ownerUser = initialAppData?.user;
    let ownerEmail = ownerUser?.email;
    if (userDetailsMap[ownerEmail] && ownerUser?.profile_picture) {
      userDetailsMap[ownerEmail].profile_picture = ownerUser?.profile_picture;
    }

    return userDetailsMap;
  }
  static getInitials = function (userFullName, userEmail) {
    let initials = "";

    if (userFullName) {
      let names = userFullName.split(' ');
      initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
    }
    else if (userEmail) {
      initials = userEmail.charAt(0).toUpperCase();
    }

    return initials;
  };

  //Member Functions
  static getMemberEmails(userDetails, currentUser) {
    if (userDetails && currentUser) {
      return Object.keys(userDetails).filter((user) => user !== currentUser);
    }
    return [];
  }

  //Users Object
  static formatUsersObjects(intialUsersObject, currentUserEmail) {
    //format the detailed users
    let detailedUsers = intialUsersObject.detailed.filter(x => {
      return x.email !== currentUserEmail;
    }).map(d => {
      d.last_activity = d.last_activity || null;
      d.status = d.status || 'inactive';
      d.presence = d.presence || 'offline';
      return d;
    });

    intialUsersObject.detailed = detailedUsers;
    return intialUsersObject;
  }

  //Invited User Helpers
  static canUserBeInvited(email, currentMembers, currentUser) {
    let result = { valid: true, message: null };

    // Make sure we still allow users that have been "removed" to be reinvited.
    let notAllowedUsers = currentMembers
      .filter((member) => {
        return member.email && member.status !== 'removed';
      })
      .map((x) => {
        return x.email.toLowerCase();
      });

    //fail case 0
    if (!email) {
      result.valid = false;
      result.message = `Email is a required field`
    }

    //fail case 1
    if (notAllowedUsers.indexOf(email.toLowerCase()) > -1) {
      result.valid = false;
      result.message = `${email} has already been invited or is already active on this page.`;
    }

    //fail case 2
    if (email === currentUser) {
      result.valid = false;
      result.message = 'Cannot invite yourself.';
    }

    return result;
  }
}
