import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ThemeUtility from '../../utilities/theme-utility';
import { useEditorStore } from 'stores/editorStore';
import './index.scss';

//Modal
ReactModal.defaultStyles.overlay = {};
ReactModal.defaultStyles.content = {};

export default (props) => {
  const editorStore = useEditorStore();
  return <Modal editorStore={editorStore} {...props}></Modal>
}


function ModalTitle(props) {
  if (!props.title) {
    return null;
  }

  return <div className="base-modal-header">{props.title}</div>;
}

class Modal extends Component {
  constructor(props) {
    super(props);
    this.baseState = this.state;
  }

  onRequestClose = () => {
    this.setState(this.baseState);
  };

  render() {
    //The theming in here needs to change

    let {
      title,
      isOpen,
      onRequestClose,
      onAfterOpen,
      hideCloseButton,
      useDarkOverlay
    } = this.props;

    const { darkMode } = this.props.editorStore;

    let reactModalStyles = {
      overlay: {
        backgroundColor: `rgba(0, 0, 0, ${useDarkOverlay ? '0.7' : '0.5'})`
      }
    };

    //class
    let className = 'base-modal';

    //helpers
    if (darkMode) {
      className += ' dark';
    }
    if (this.props.v2) className += ' v2';
    //fullscreen modal display for web
    if (this.props.fullScreen) className += ' full-screen';
    if (this.props.hideBackground) className += ' no-background';

    return (
      <ReactModal
        id={this.props.id || null}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={true}
        style={reactModalStyles}
        className={className}
        onAfterOpen={onAfterOpen}
      >
        {!hideCloseButton && (
          <button className="btn-no-style btn-close" onClick={onRequestClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        <ModalTitle title={title} />
        {this.props.children}
      </ReactModal>
    );
  }
}