import React from 'react';
import './spinner.scss';

export default function Spinner(props) {
  let spinnerStyle = { display: props.isActive ? 'block' : 'none' };

  return (
    <div className="spinner-wrapper" style={spinnerStyle}>
      <div className="spinner">
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
