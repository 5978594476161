import React, { Component } from 'react';
import './index.scss';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  componentDidUpdate() {
    if (this.props.isOpen !== this.state.isOpen) {
      this.setState({
        isOpen: this.props.isOpen
      });
    }
  }

  setDropdownRef = (node) => {
    this.dropdownRef = node;
  };

  handleClickOutside = (event) => {
    const element = document.getElementById(this.props.parent);
    if (
      this.dropdownRef &&
      !this.dropdownRef.contains(event.target) &&
      !element.contains(event.target)
    ) {
      this.props.handleClickOutside();
    }
  };

  getDropdownStyles = () => {
    const { isOpen } = this.state; //this seems like it could just be based on props

    const styles = {
      display: isOpen ? 'block' : 'none'
    };

    return styles;
  };

  render() {

    return (
      <div className={`dropdown-container ${this.props.className}`}>
        <div
          className="filter-dropdown-container"
          ref={this.setDropdownRef}
          style={this.getDropdownStyles()}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Dropdown;
