//Move to media utiltiy
export const getFilenameExtension = (filename) => {
  const REGEX = /(?:\.([^.]+))?$/;
  return REGEX.exec(filename)[1];
};

export const convertFileExtToLowerCase = (url) => {
  if (!url || typeof url !== 'string') {
    return url;
  }
  const fileExtension = url.split('.').pop();
  const lowerCaseFileExtension = fileExtension.toLowerCase();
  const convertedUrl = url.replace(fileExtension, lowerCaseFileExtension);
  return convertedUrl;
}

/*
export const isOfficeFile = (file) => {
  const filename = file?.name || '';
  const ext = getFilenameExtension(filename);
  const officeExts = ["ppt", "pptx", "doc", "docx", "xls", "xlsx"];
  return ext && officeExts.includes(ext.toLowerCase()) || false;
}
*/
