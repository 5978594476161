import React, { useState, useRef, useEffect, useContext } from 'react';
import { Paper, Tabs, Tab, withStyles } from '@material-ui/core';
import Api from 'services/api';
import { toast } from 'react-toastify';
import SearchTab from './search';
import RecentTab from './recent';
import MyStuffTab from './my-stuff';
import { UserContext } from 'contexts/user-context';
import Spinner from '../spinner';
import { LanguageUtility } from 'utilities';
import './index.scss';
import './_dark.scss';

const customTabStyle = {
  root: {
    minWidth: '45px'
  }
};

function useOutsideAlerter(ref, props) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        props.dismissAddMediaTray();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function AddMediaTray(props) {
  const user = useContext(UserContext);
  const [userSelectedTab, setUserSelectedTab] = useState('myStuff');
  const [isLoading, setIsLoading] = useState(false);
  const [mediaItemsToAdd, setmediaItemsToAdd] = useState([]);
  const [showActions, setShowActions] = useState(true);
  const localize = LanguageUtility.getLocalization.bind(null, props);

  useEffect(() => {
    setShowActions(userSelectedTab !== 'myStuff');
  }, [userSelectedTab]);

  const updatemediaItemsToAdd = (newList) => {
    setmediaItemsToAdd([...newList]);
  };

  const getTabs = () => {
    let tabs = [];

    //MyStuff
    tabs.push({
      key: 'myStuff',
      label: localize('My Stuff tab header', 'My Stuff'),
      component: <MyStuffTab {...props} updatemediaItemsToAdd={updatemediaItemsToAdd} mediaItemsToAdd={mediaItemsToAdd} />
    });

    //Recent. This feature needs revision.
    tabs.push({
      key: 'recent',
      label: localize('Recent tab header', 'Recent'),
      component: <RecentTab {...props} updatemediaItemsToAdd={updatemediaItemsToAdd} mediaItemsToAdd={mediaItemsToAdd} />
    });

    //Search
    tabs.push({
      key: 'search',
      label: localize('Search tab header', 'Search'),
      component: <SearchTab {...props} updatemediaItemsToAdd={updatemediaItemsToAdd} mediaItemsToAdd={mediaItemsToAdd} />
    });
    return tabs;
  };

  const { classes, accountTheme } = props;
  const tabs = getTabs();

  const customTabStyles = {
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  };

  const handleTabChange = (event, userSelectedTab) => {
    setUserSelectedTab(userSelectedTab);
    clearSelection();
  };

  const clearSelection = () => {
    setmediaItemsToAdd([]);
  };

  const addMedia = async () => {
    setIsLoading(true);
    let userEmail = user.email;
    if (mediaItemsToAdd.length > 0) {

      try {
        await Api.addMedia(mediaItemsToAdd, userEmail);
        let refreshedMedia = await Api.refreshMedia();
        props.refreshMedia(refreshedMedia);
        toast.success('Media added');
        props.dismissAddMediaTray();
      } catch (err) {
        toast.error('Failed to add media');
        props.dismissAddMediaTray();
      }
    }
  };

  const newProps = {
    accountTheme: props.accountTheme,
    medias: props.medias,
    user: user,
    isLoading: props.isLoading,
    salesContact: props.salesContact,
    settings: props.settings,
    isOwner: props.isOwner,
    userDetails: props.userDetails,
    pageActivity: props.pageActivity,
    language: props.language,
    dismissAddMediaTray: props.dismissAddMediaTray,
  };

  const activeTab = tabs.find((x) => x.key === userSelectedTab) || tabs[0];
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props);
  const assetCount = mediaItemsToAdd.length;

  return (
    <div className="add-media-tray-container">
      <div className="overlay"></div>
      <div ref={wrapperRef} className="add-media-tray animate fadeInRight">
        <Paper className="tabs-root">
          <Tabs
            value={activeTab.key}
            onChange={handleTabChange}
            variant="fullWidth"
            TabIndicatorProps={{ className: 'active-tab-indicator' }}>
            {tabs.map((tab) => (
              <Tab
                key={tab.key}
                value={tab.key}
                label={tab.label}
                style={customTabStyles}
                medias={props.medias}
                className={tabs.length > 2 ? classes.root : null}
                accounttheme={accountTheme}
              />
            ))}
          </Tabs>
        </Paper>

        {isLoading ? (
          <div className="loader-container">
            <Spinner isActive={isLoading} />
          </div>
        ) : (
          <div className="tab-container">
            {!!activeTab && (
              <div className="tab-content" key={activeTab.key}>
                {React.cloneElement(activeTab.component, newProps, user)}
              </div>
            )}

            {showActions && (
              <div className="button-container">
                {assetCount === 0 && (
                  <button className="cancel-btn" onClick={() => props.dismissAddMediaTray()}>
                    {localize('Cancel label', 'Cancel')}
                  </button>
                )}

                {assetCount > 0 && (
                  <>
                    <button className="clear-btn" onClick={() => clearSelection()}>
                      {localize('Clear Selection label', 'Clear Selection')}
                    </button>

                    <button className="add-asset-btn" onClick={() => addMedia()}>
                      {`Add ` + assetCount + ` Asset`}
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default withStyles(customTabStyle)(AddMediaTray);
