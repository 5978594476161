import './index.scss';

import React, { useEffect, useState } from 'react';
import Modal from 'components/modal';
import ShareTemplateSelector from './share-template-selector';
import ShareLinkBuilder from './share-link-builder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons';
import { LanguageUtility, EmailUtility } from 'utilities';
import PropTypes from 'prop-types';

export default function ShareModal(props) {
  const localize = LanguageUtility.getLocalization;
  const [shareOption, setShareOption] = useState('default');
  const [templates, setTemplates] = useState([]); //(props.templates);

  const { accountSettings } = props;
  const isEmailSharingDisabled = parseInt(accountSettings['share_isEmailSharingDisabled']) === 1;

  let originURL = window.location.origin;
  if (originURL.indexOf('localhost') > -1) {
    originURL = 'https://followups.gomodus.com';
  }
  const dsrLink = `${originURL}/${props.guid}`;

  useEffect(() => {
    const { templates, accountSettings, medias } = props;

    if (isEmailSharingDisabled) {
      handleShareOption('link');
    } else if (templates) {
      EmailUtility.getEmailTemplates(medias, templates, accountSettings, dsrLink, props).then(
        (templates) => {
          setTemplates(templates);
          //handleShareOption('template');
        }
      );
    }
  }, []);

  const shareOptions = [
    {
      icon: faEnvelope,
      title: localize(props, 'Share via email button title', 'Share via Email'),
      details: localize(
        props,
        'Share via email button description',
        'Use your default mail app to send your landing page link.'
      ),
      view: 'template'
    },
    {
      icon: faLink,
      title: localize(props, 'Share via link button title', 'Copy Sharing Link'),
      details: localize(
        props,
        'Share via link button description',
        'Create a sharing link URL with user access options'
      ),
      view: 'link'
    }
  ];

  //UI
  const handleShareOption = function (shareOption) {
    setShareOption(shareOption);
  };

  //render
  return (
    <Modal
      id="share_modal_base"
      onRequestClose={props.onRequestClose}
      isOpen={true}
      v2={true}
      hideBackground={true}>
      <div className="share-modal-header">
        <div className="title-bar">
          <div className="title">{localize(props, 'Share now label', 'Share Now')}</div>
        </div>
      </div>
      <div className="share-modal">
        {shareOption === 'default' && (
          <div className="default-view">
            <div className="message">
              {localize(
                props,
                'Share dialog message',
                'How would you like to share this landing page?'
              )}
            </div>
            <ul>
              {shareOptions.map((option, i) => (
                <li
                  className="option"
                  role="button"
                  key={i}
                  onClick={() => handleShareOption(option.view)}>
                  <div className="icon">
                    <FontAwesomeIcon size="sm" icon={option.icon} />
                  </div>
                  <div className="content">
                    <div className="title">{option.title}</div>
                    <div className="details">{option.details}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {shareOption === 'template' && (
          <ShareTemplateSelector
            {...props}
            dsrLink={dsrLink}
            settings={props.settings}
            accountSettings={props.accountSettings}
            medias={props.medias}
            setShareOption={setShareOption}
            templates={templates}
          />
        )}

        {shareOption === 'link' && (
          <ShareLinkBuilder
            {...props}
            dsrLink={dsrLink}
            settings={props.settings}
            templates={props.templates}
            accountSettings={props.accountSettings}
            medias={props.medias}
            isEmailSharingDisabled={isEmailSharingDisabled}
            handleShareOption={handleShareOption}
          />
        )}
      </div>
    </Modal>
  );
}

ShareModal.propTypes = {
  accountSettings: PropTypes.object,
  guid: PropTypes.string,
  medias: PropTypes.object,
  settings: PropTypes.object,
  templates: PropTypes.array,
  onRequestClose: PropTypes.function
};
