import './index.scss';

import React, { useState, useEffect, useMemo, useContext } from 'react';
import { ReactComponent as UserCircle } from '../../../assets/icons/user-circle.svg';
import { ReactComponent as StarCircle } from '../../../assets/icons/star-bg.svg';
import { useEditorStore } from 'stores/editorStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faXmark, faImage } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import Filestack from '../../filestack';
import { convertFileExtToLowerCase } from 'utilities/file-utility';
import { LanguageUtility } from 'utilities';
import { SalesContactContext } from 'contexts/sales-contact-context';
import Api from 'services/api';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import Video from 'components/Video';
import Spinner from 'components/spinner';
import VideoPosterPickerModal from 'components/video-poster-picker-modal';

const localize = LanguageUtility.getLocalization;

const schema = yup.object().shape({
  title: yup.string().max(50, 'Title character limit: 50'),
  description: yup.string().max(280, 'Description character limit: 280')
});

const MediaHeader = ({ mediaHeader, subdomain, owner, userDetails, language, ...props }) => {

  if (!mediaHeader) mediaHeader = {};

  //store
  const editContent = useEditorStore((state) => state.editContent);

  //state
  const headerExists = !!(mediaHeader.title || mediaHeader.videoUrl || mediaHeader.description);
  const [hasHeader, setHasHeader] = useState(headerExists);
  const [title, setTitle] = useState(mediaHeader?.title);
  const [description, setDescription] = useState(mediaHeader?.description);
  const [videoUrl, setVideoUrl] = useState(convertFileExtToLowerCase(mediaHeader?.videoUrl));
  const [isVideoPosterPickerOpen, setIsVideoPosterPickerOpen] = useState(false);
  const [videoPoster, setVideoPoster] = useState(mediaHeader?.videoPoster);
  const [filestackUploadedVideoPoster, setFilestackUploadedVideoPoster] = useState(null);
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  const [invalidVideoUrl, setInvalidVideoUrl] = useState(false);
  const [validationError, setValidationError] = useState({ title: null, description: null });
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [filestackOptions, setFilestackOptions] = useState({ open: false });

  const videoJsOptions = useMemo(() => {
    return {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      controlBar: {
        liveDisplay: true,
        pictureInPictureToggle: false
      },
      poster: videoPoster,
      sources: [{ src: videoUrl }]
    };
  }, [videoUrl, videoPoster]);

  //Lifecycle
  useEffect(() => {

    //save changes (probably should make sure it's an admin?)
    if (!editContent && shouldUpdate) {

      if (validationError?.title?.message || validationError?.description?.message) {
        toast.error('Unable to save the changes to header - please fix the highlighted errors to save your changes');
        return;
      }

      //used so filestack temp path isn't saved.
      let finalVideoPoster = filestackUploadedVideoPoster !== null ? filestackUploadedVideoPoster : videoPoster;

      let headerUpdateObj = { media_header: { videoUrl, description, title, videoPoster: finalVideoPoster } };
      if (props.title === '') headerUpdateObj.title = title;

      //try and save
      Api.update(headerUpdateObj).then(() => {
        //Update app state
        Object.entries(headerUpdateObj).forEach(([key, value]) => {
          if (key === 'media_header') key = 'mediaHeader';
          props.onStatePropertyChanged(key, value);
        });
      }).catch(ex => {
        toast.error('An error occured while trying to save your changes. Please try again later');
      });
      setShouldUpdate(false);
    }

    //If you enter edit mode with an invalid video, reset the video input for the user
    if (editContent && invalidVideoUrl) {
      setInvalidVideoUrl(false);
      handleClearVideoUrl();
    }

  }, [editContent]);

  useEffect(async () => {

    //Validate the video url
    if (videoUrl) {
      try {
        var response = await Api.makeHeadRequest(videoUrl);
        if (!response.ok) { throw "invalid video url"; }
      } catch (e) {
        setInvalidVideoUrl(true);
      }
    }

  }, []);

  //UI
  const handleTitleOnChange = (e) => {
    const newTitle = e.target.value;
    setTitle(newTitle);
    try {
      schema.validateSync({ title: newTitle });
      setValidationError({ ...validationError, title: null });
    } catch (err) {
      setValidationError({ ...validationError, title: { message: err.message } });
    }
    setShouldUpdate(true);
  };
  const handleDescriptionOnChange = (e) => {
    const newDesc = e.target.value;
    setDescription(newDesc);
    try {
      schema.validateSync({ description: newDesc });
      setValidationError({ ...validationError, description: null });
    } catch (err) {
      const { message } = err;
      setValidationError({ ...validationError, description: { message } });
    }
    setShouldUpdate(true);
  };
  const handleClearVideoUrl = () => {
    setVideoUrl(null);
    setVideoPoster(null);
    setShouldUpdate(true);
  };
  const onVideoUploaded = (fsResponse) => {
    setIsVideoLoading(true);
    //filestack's temp url for videos doesn't appear to work so using a delay.
    setTimeout(() => {
      setVideoUrl(fsResponse.url);
      setIsVideoLoading(false);
    }, 5200);
    setVideoPoster(null);
    setShouldUpdate(true);
  }
  const onPosterUploaded = (newThumbObj) => {
    setVideoPoster(newThumbObj.temp);
    setFilestackUploadedVideoPoster(newThumbObj.url);
    setShouldUpdate(true);
  }
  const handleVideoError = () => {
    setInvalidVideoUrl(true);
  }
  const getSalesContactLabel = () => {
    if (salesContact.first_name && salesContact.last_name) {
      return salesContact.first_name + ' ' + salesContact.last_name;
    }
    return props.owner;
  };

  //Filestack
  const openFilestack = (e, onUploadSuccess = onVideoUploaded, acceptedSources = 'video/*') => {
    e.preventDefault();
    setFilestackOptions({ open: true, onUploadSuccess, acceptedSources });
  }

  //Render
  const { isOwner, navbar_hideActions } = props;
  const isEditable = isOwner && editContent && !navbar_hideActions;
  const context = useContext(SalesContactContext);
  const salesContact = context ? context : {};
  const { profile_picture: profilePicture } = salesContact || {};
  const hasVideo = videoUrl && videoUrl !== null;
  const hasAvatar = !!profilePicture;
  const salesContactName = getSalesContactLabel();

  //labels
  const replaceVideoTooltip = localize(props, 'Replace header video tooltip', 'Replace Welcome Header Asset');
  const addVideoThubmnailTooltip = localize(props, '', 'Add or Replace Video Thumbnail');
  const addVideoTooltip = localize(props, 'Add header video tooltip', 'Add Welcome Header Asset');

  //styling
  let titleClasses = validationError?.title ? "error" : "";
  let descriptionClasses = validationError?.description ? "error" : "";

  //layout help
  let editorClasses = isEditable ? "editable" : '';
  let layoutClasses = '';
  if (!isEditable && !hasVideo) layoutClasses += " collapse-video";
  if (!isEditable && !title && !description) layoutClasses += " collapse-text";

  if (isEditable && !hasHeader) {
    return (
      <div className="add-media-header">
        <button onClick={() => { setHasHeader(true) }}>
          <span className="fa fa-plus"></span>
          <span>Add a "Hero" Header</span>
        </button>
      </div >
    );
  }

  if (hasHeader) {
    return (
      <div className={`media-header ${layoutClasses}`} >
        {/*Video Stuff*/}
        {isVideoLoading && (
          <div className={`video-container no-video`}>
            <Spinner isActive={true} />
          </div>
        )}

        {(hasVideo || isEditable) && !isVideoLoading && (
          <div className={`video-container ${hasVideo ? "" : 'no-video'} ${editorClasses}`}>
            {hasVideo &&
              <Video poster={videoPoster} options={videoJsOptions} onVideoError={handleVideoError}>
              </Video>
            }
            {invalidVideoUrl &&
              <div className='video-error-overlay'>
                {`This video could not be loaded. ${isOwner ? 'To replace it, please use Editor Mode.' : ''}`}
              </div>
            }

            {isEditable &&
              <div className={`media-video-btns ${hasVideo ? '' : 'add'}`}>
                {!hasVideo &&
                  <>
                    <button
                      data-for="header-edit-button-tooltip"
                      data-tip={addVideoTooltip}
                      place="top"
                      onClick={(e) => { openFilestack(e) }}>
                      <FontAwesomeIcon icon={faPencilAlt} size="1x" />
                    </button>
                    <ReactTooltip id="header-edit-button-tooltip"
                      backgroundColor="#646464"
                      effect="solid" clickable />

                    <div className='info'>
                      Add a video here by selecting the edit icon above
                    </div>
                  </>
                }
                {hasVideo &&
                  <>
                    <VideoEditButton id='btn1'
                      className='edit'
                      icon={faPencilAlt}
                      tooltip={replaceVideoTooltip}
                      onClick={openFilestack} />

                    <VideoEditButton id='btn2'
                      className='clear'
                      icon={faXmark}
                      tooltip={'Remove Welcome Header Asset'}
                      onClick={handleClearVideoUrl} />

                    <div className='seperator'></div>

                    <VideoEditButton id='btn3'
                      className='thumb'
                      icon={faImage}
                      tooltip={addVideoThubmnailTooltip}
                      onClick={() => { setIsVideoPosterPickerOpen(true) }} />
                  </>
                }

              </div>
            }

            {isVideoPosterPickerOpen && (
              <VideoPosterPickerModal
                guid={props.guid}
                language={language}
                subdomain={subdomain}
                videoSrc={videoUrl}
                onFilestackRequested={openFilestack}
                onPosterUploaded={onPosterUploaded}
                onRequestClose={() => { setIsVideoPosterPickerOpen(false) }}
              />
            )}

            {filestackOptions.open && (
              <Filestack
                language={language}
                subdomain={subdomain}
                acceptedSources={filestackOptions.acceptedSources}
                onUploadSuccess={filestackOptions.onUploadSuccess}
                onClose={() => { setFilestackOptions({ open: false }) }}
              />
            )}
          </div>
        )}

        <div className={`text-content ${editorClasses}`}>
          {/*Title Stuff*/}
          {(title || isEditable) && (
            <div className={`title ${editorClasses}`}>
              {isEditable ? (
                <>
                  <input className={titleClasses}
                    placeholder="Enter a title here (optional)"
                    onChange={handleTitleOnChange}
                    type="text"
                    value={title || ''} />
                  {validationError?.title &&
                    <span className={`input-error-message`}>
                      {validationError?.title?.message}
                    </span>
                  }
                </>
              ) : (
                <h3 className={titleClasses}>{title || ''}</h3>
              )}
            </div>
          )}

          {/*Description Stuff*/}
          {(description || isEditable) && (
            <div className={`description ${editorClasses}`}>
              {isEditable ? (
                <>
                  <textarea
                    className={descriptionClasses}
                    placeholder="Enter a description here (optional)"
                    onChange={handleDescriptionOnChange}
                    value={description} />
                  {validationError?.description &&
                    <span className={`input-error-message`}>
                      {validationError?.description?.message}
                    </span>
                  }
                </>
              ) : (
                <p className={descriptionClasses}>{description || ''}</p>
              )}
            </div>
          )}

          {/*Author Stuff*/}
          {(title || description || isEditable) && (
            <div className="author">
              <div className="avatar">
                {hasAvatar ? (
                  <img src={profilePicture} />
                ) : (
                  <UserCircle />
                )}
              </div>
              <div className='name'>{salesContactName}</div>
              <StarCircle className='star-icon' />
            </div>
          )}
        </div>
      </div>
    )
  }

  //Default - show nothing
  return null;
};

const VideoEditButton = ({ id, className, icon, tooltip, onClick }) => {
  const datatipId = `header-video-button-${id}`;
  const tooltipBackground = "#646464"

  return (
    <>
      <button
        className={className}
        data-for={datatipId}
        data-tip={tooltip}
        onClick={onClick}
        place={'top'}>
        <FontAwesomeIcon icon={icon} size="1x" />
      </button>
      <ReactTooltip id={datatipId} backgroundColor={tooltipBackground} effect="solid" />
    </>
  );
}

export default MediaHeader;
