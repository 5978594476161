import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import config from 'assets/configuration/firebase-config';
import { getCurrentGuid, base64Encode, base64Decode } from 'utilities/helper-methods';

export default class FirebaseUtility {
  static firebaseUsers(user, setUserDetailPresence) {
    const guid = getCurrentGuid();
    const firebase_user_id = base64Encode(user);

    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    const ref = `followups/${guid}/users/${firebase_user_id}`;
    const usersRef = `followups/${guid}/users`;
    const userListRef = firebase.database().ref(usersRef);
    if (firebase_user_id) {
      firebase.database().ref(ref).set({
        status: 'online'
      });
    }
    if (user) {
      firebase.database().ref(ref).onDisconnect().set({
        status: 'offline'
      });
    }

    userListRef.on('value', (snapshot) => {
      const userList = snapshot.val() || [];
      const users = Object.entries(userList) || [];
      users.forEach((user) => {
        if (!user[0]) return;
        const [base64User, statusObject] = user || [];
        const decodedUser = base64Decode(base64User);
        const status = statusObject.status || 'offline';
        setUserDetailPresence(decodedUser, status);
      });
    });
  }

  static attachFirebasePresenceListener(guid, userEmail) {

    if (!userEmail) return;

    document.addEventListener('visibilitychange', (event) => {
      if (!userEmail) return;
      const firebase_user_id = base64Encode(userEmail);
      const ref = `followups/${guid}/users/${firebase_user_id}`;
      if (document.visibilityState == 'visible') {
        firebase.database().ref(ref).set({ status: 'online' });
      } else {
        firebase.database().ref(ref).set({ status: 'away' });
      }
    });
  }
}
