//styling
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import 'react-toastify/dist/ReactToastify.css';

//polyfills
import 'url-search-params-polyfill'; // this really only needs to be imported one time in a helper but it's in quite a few places

//configuration
import DEFAULT_SETTINGS from 'assets/configuration/settings.json';

//react things
import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

//vendor
import 'moment/min/locales';
import { TrackJS } from 'trackjs';

//state management
import { useEditorStore } from 'stores/editorStore';

//Pages / Components
import { Archived, Error, Grant, Main, Preferences, Unauthorized } from 'pages';
import Navbar from 'components/navbar';
import Footer from 'components/footer';
import PrivacyGate from 'components/privacy-gate';
import AddMediaTray from 'components/add-media-tray';

import Api from 'services/api';
import AuthenticationContext from 'services/authentication-context';
import { SalesContactContext } from 'contexts/sales-contact-context';
import { UserContext } from 'contexts/user-context';
import { LanguageUtility, UsersUtility, ConnectedDeviceUtility, ThemeUtility, PrivacyUtility } from 'utilities';
import FirebaseUtility from 'utilities/firebase-utility';
import { toast, ToastContainer } from 'react-toastify';
import {
  getCurrentGuid,
  getParameterByName,
  removeURLParameter,
  mapNewSettingValues,
} from 'utilities/helper-methods';

toast.configure({});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountSettings: { enableDSR_2_0: false },
      accountTheme: ThemeUtility.getBlankTheme(),
      granted_magic_link_access: false,
      isOwner: false,
      language: 'en',
      medias: null,
      privacy_setting: PrivacyUtility.DEFAULT_PRIVACY_SETTING,
      recipient: null,
      salesContact: '',
      settings: DEFAULT_SETTINGS,
      title: null,
      user: null,
      users: { all: [], detailed: [], notificationRecipients: [] },
      managers: null,

      //this is just a derevative of users object, convert to use that instead of this.
      userDetails: null,

      //ui
      isLoading: true,
      isActionTrayOpen: false,

      //privacy
      privacyGateSettings: { isGateEnabled: false },
      showNewUserModal: false, //todo: remove this in favor of something like privacyGateSettings.isLegacyGateEnabled

      //determined by route - todo: nest all the navbar crap in an object
      isBackgroundImageVisible: true,
      navbar_hideActions: false,
      navbar_hideHamburger: false,
      navbar_hideSettings: false,
      navbar_hideTitle: false,
    };
  }

  //Lifecycle
  componentDidMount = async () => {
    const guid = getCurrentGuid();

    //intial page routing
    const initialPageType = this.getPageType();

    if (initialPageType === 'authentication') {
      return this.routeDidChange();
    } else if (guid && initialPageType === 'no-access') {
      this.navigate(`/${guid}`);
    } else {
      this.routeDidChange();
    }

    //Validate guid
    if (!guid) {
      return this.navigateToErrorPage();
    }

    //Load the data
    let data;
    try {
      AuthenticationContext.initialize();
      data = await Api.view(guid);
    } catch (error) {
      console.error(error);
      //todo: change this to navigate with an error message AND the guid in the addressbar
      return this.navigateToErrorPage();
    }

    //Exit Case Check
    if (data.deleted) {
      return this.navigateToErrorPage();
    }

    //buld the user
    const currentUserObject = await UsersUtility.initialize(data);
    const userEmail = currentUserObject?.email;
    const isOwner = currentUserObject?.isOwner; //change to isAdmin because we mostly care if you can edit, not really if you "own" it

    //User Validation
    let userValidationResults = await AuthenticationContext.validateUserAccess(currentUserObject, data.privacy_setting);
    if (!userValidationResults.valid) {
      this.setState({ user: currentUserObject });
      return this.navigate(`/${guid}/${userValidationResults.redirect}`, false);
    }

    //mapping- do we need userDetails feels like this could be consolidated?
    const userDetails = await UsersUtility.createInitialUserDetails(data);

    //manual data mapping
    this.setState({
      guid: data.guid,
      accountId: data.account_id,
      accountSettings: mapNewSettingValues(this.state.accountSettings, data.account_settings),
      accountTheme: ThemeUtility.getValidTheme(data.ui.theme),
      archived: data.archived,
      company: data.user && data.user.company ? data.user.company : '',
      granted_magic_link_access: data.granted_magic_link_access,
      language: LanguageUtility.getInitialLanguage(data.ui),
      medias: data.media,
      mediaHeader: data.ui.media_header,
      owner: data.owner, //email; change to the "user" object instead -> remove salesContact and update the SalesContactContext to be AdminContext
      ownerUserId: data.user.id, //id - TODO: remove?
      isOwner: isOwner,//change to isAdmin?
      pageActivity: data.activity,
      privacy_setting: data.privacy_setting,
      recipient: data.recipient,
      salesContact: data.user,
      settings: mapNewSettingValues(this.state.settings, data.ui.settings),
      subdomain: data.subdomain,
      title: data.title,
      templates: data.templates,
      user: currentUserObject,
      users: UsersUtility.formatUsersObjects(data.users, userEmail),
      managers: data.managers,

      //all of these things are just unneeded derivatives of "users" - remove and just leverage the users object
      userDetails: userDetails, //todo: users data - get rid of this and just leverage users
    });

    //Deep Link - Open the mobile application on device if applicable
    ConnectedDeviceUtility.attemptDeepLink(guid, this.state.settings);

    //--- REDIRECTS ---//
    //Grant Access
    let showRequestAccessPage = !!getParameterByName("r");
    if (showRequestAccessPage) {
      return this.navigate(`/${guid}/grant`, false);
    }

    //Archived Check
    if (data.archived === 1 && !isOwner) {
      return this.navigate(`/${data.guid}/archived`, false);
    }

    //Single Asset Redirect
    let mediaHeader = data.ui.media_header;
    const headerExists = !!(mediaHeader.title || mediaHeader.videoUrl || mediaHeader.description)
    if (data.media && data.media.length === 1 && !getParameterByName('view') && !headerExists) {
      this.navigate(`/${data.guid}/${data.media[0].id}`);
    }

    //Privacy Gate Check
    this.validateOrGateUser();

    //Startup Helpers
    this.initFirebase();
    this.initTrackJS();

    //Analytics
    if (userEmail) {
      Api.sendVisitAction(userEmail, guid);
    }

    //Indicate loading is complete
    this.setState({ isLoading: false });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.routeDidChange();
    }
  };

  //Routing
  getPageType = () => {
    const pages = {
      'no-access': ['/archived', '/403', '/error'],
      'limited-view': ['/preferences', '/grant'],
      'authentication': ['/linkedin/callback']
    };

    let pathname = this.props.location.pathname;
    let keys = Object.keys(pages);
    let type;

    for (var i = 0; i < keys.length; i++) {
      let subkeys = pages[keys[i]];
      let matches = subkeys.filter(x => { return pathname.indexOf(x) > -1; })
      if (matches.length > 0) {
        type = keys[i];
        break;
      }
    }

    return type;
  };
  routeDidChange = () => {
    const newStateProps = {};
    const pageType = this.getPageType();

    switch (pageType) {
      case 'no-access':
        newStateProps.navbar_hideSettings = true;
        newStateProps.navbar_hideTitle = true;
        newStateProps.navbar_hideActions = true;
        newStateProps.navbar_hideHamburger = true;
        newStateProps.isBackgroundImageVisible = false;
        newStateProps.isLoading = false;
        break;
      case 'limited-view':
        newStateProps.navbar_hideActions = true;
        newStateProps.navbar_hideHamburger = true;
        newStateProps.isBackgroundImageVisible = false;
        break;
      case 'authentication':
        newStateProps.isLoading = false;
        newStateProps.privacyGateSettings = { isGateEnabled: true };
        break;
    }

    this.setState(newStateProps);
  };

  //Privacy Gate Validation
  validateOrGateUser = async (actionRequiresUser = false, type = null) => {
    const privacyGateSettings = await PrivacyUtility.getPrivacyGateProperties(this.state, actionRequiresUser);
    privacyGateSettings.type = type; //TODO: i don't think i like type....but i don't have a better way to solve this right now.

    this.setState({
      privacyGateSettings,
      showNewUserModal: privacyGateSettings.isLegacyGateEnabled
    });

    return !privacyGateSettings.isGateEnabled && !privacyGateSettings.isLegacyGateEnabled;
  };

  //Initializers
  initFirebase = () => {
    const { guid, user } = this.state;

    const setUserDetailPresence = (email, presence) => {
      let userList = this.state.userDetails;
      if (userList[email]) {
        userList[email].presence = presence;
        this.setState({ userDetails: userList });
      }
    };

    if (user) {
      FirebaseUtility.firebaseUsers(user.email, setUserDetailPresence);
      FirebaseUtility.attachFirebasePresenceListener(guid, user?.email);
    }
  }
  initTrackJS = () => {
    if (window.location.host.indexOf('localhost') !== 0) {
      TrackJS.install({
        token: 'f9ba96ebbc284f80a0c22f8c222d82a5',
        application: 'microsite'
      });
      const { user, accountId, subdomain } = this.state;
      const guid = getCurrentGuid();
      TrackJS.addMetadata('account_id', accountId);
      TrackJS.addMetadata('subdomain', subdomain);
      TrackJS.addMetadata('user_email', user.email || 'unknown');
      TrackJS.addMetadata('guid', guid);
    }
  };

  // ------ Save Helpers ------ //
  refreshMedia = async () => {
    useEditorStore.setState({ isMediaReloading: true });

    let refreshedMedia = await Api.refreshMedia();
    useEditorStore.setState({ isMediaReloading: false });
    this.setState({ medias: refreshedMedia });
    return refreshedMedia;
  }
  trySaveMedia = async () => {
    const deletedMediaIds = useEditorStore.getState().deletedMediaIds;
    const changesMade = useEditorStore.getState().changesMade;

    if (!changesMade) return;

    //Handle deleted medias
    if (deletedMediaIds.length > 0) {
      try {
        await Api.deleteMedia(deletedMediaIds);
        await this.refreshMedia();
      } catch (err) {
        toast.error(`Failed to remove media`);
      }
    }

    //Save media indexing
    const mediaIndexes = {};
    this.state.medias.forEach(e => {
      mediaIndexes[e.id] = e.index;
    });

    Api.indexFollowupMedia(mediaIndexes).catch((err) => { toast.error(`Failed to update media order`); });

    this.onStatePropertyChanged('changesMade', false);
  }

  //Navigation
  navigate = (path, isLoading = null) => {
    const qp = this.props.location.search;
    const formattedPath = `${path}${qp}`;
    this.props.history.push(formattedPath);

    if (isLoading !== null) {
      this.setState({ isLoading: isLoading });
    }
  };
  navigateToErrorPage = () => {
    this.props.history.push('/error');
  };

  //Editing
  onChangesMade = () => {
    //NOTE: idk how i feel about this. Seems to broad, what changed?
    useEditorStore.setState({ changesMade: true });
  };

  //Media
  onMediaOrderChanged = (mediaOrder) => {
    const { medias } = this.state;

    medias.map(x => {
      x.index = mediaOrder.indexOf(x.id.toString());
    })

    this.setState({ medias: medias });
    this.onChangesMade();
  };
  onMediaDeleted = (mediaId) => {
    const deletedMediaIds = useEditorStore.getState().deletedMediaIds;
    if (!deletedMediaIds.includes(mediaId)) {
      useEditorStore.setState({ deletedMediaIds: [...deletedMediaIds, mediaId] });
      this.onChangesMade();
    }
  };

  //Other State Updates
  onStatePropertyChanged = (statePropertyName, newValue, saveableChangeMade = false) => {
    let newState = {};

    if (saveableChangeMade) {
      useEditorStore.setState({ changesMade: true });
    }

    newState[statePropertyName] = newValue;
    this.setState(newState);
  };

  updateShareableSettings = (shareSettings, newPrivacyLevel) => {
    //TODO: change to use the onStatePropertyChanged function?
    const newSettings = mapNewSettingValues(this.state.settings, shareSettings);
    this.setState({ settings: newSettings, privacy_setting: newPrivacyLevel });
  };

  onUsersUpdate = (users, subproperty = "detailed") => {
    //this doesn't accept the full object, but the array of detailed users
    let newUsersObject = this.state.users;
    newUsersObject[subproperty] = users;
    this.setState({ users: newUsersObject });
  };

  dismissPrivacyGate = () => {
    this.setState({ privacyGateSettings: { isGateEnabled: false, type: null } })
  }

  showAddMediaTrayFunc = () => {
    this.setState({ showAddMediaTray: true });
  }

  dismissAddMediaTray = () => {
    this.setState({ showAddMediaTray: false });
  }

  render() {
    const { darkMode } = this.props.editorStore;
    const { salesContact, privacyGateSettings, showAddMediaTray, user } = this.state;
    const minimalPreviewMode = getParameterByName('mediamanagermode') === '1';
    const fullScreenModalView = getParameterByName('view');
    const backgroundImage = ThemeUtility.getBackgroundImage(this.state);

    //App Classes
    let appContainerClasses = darkMode ? 'dark' : "";
    if (backgroundImage) { appContainerClasses += " has-background"; }

    //App Styles
    const appStyles = { backgroundImage: backgroundImage };

    let allPageFunctions = {
      navigate: this.navigate,
      onStatePropertyChanged: this.onStatePropertyChanged
    };

    return (
      <div className={`app ${appContainerClasses}`} style={appStyles} >

        {privacyGateSettings.isGateEnabled && (
          <PrivacyGate {...this.state} dismissPrivacyGate={this.dismissPrivacyGate} />
        )}

        {showAddMediaTray && (
          <UserContext.Provider value={user}>
            <AddMediaTray {...this.state} dismissAddMediaTray={this.dismissAddMediaTray} refreshMedia={this.refreshMedia} />
          </UserContext.Provider>
        )}

        {/* Change the SalesContactContext to be OwnerContext */}
        <SalesContactContext.Provider value={salesContact} >
          <UserContext.Provider value={user}>
            <ToastContainer theme={darkMode ? 'dark' : 'light'} />
            {!minimalPreviewMode &&
              <Navbar
                {...this.state}
                {...allPageFunctions}
                validateOrGateUser={this.validateOrGateUser}
                fullScreenModalView={fullScreenModalView}
                onUsersUpdate={this.onUsersUpdate}
                updateShareableSettings={this.updateShareableSettings}
                trySaveMedia={this.trySaveMedia}
              />
            }

            {/*  React Router */}
            <div className={minimalPreviewMode ? 'app-body minimal-view' : 'app-body'}>
              <Switch>

                <Route path="/linkedin/callback">
                  <div></div>
                </Route>

                <Route path="/error">
                  <Error {...allPageFunctions} />
                </Route>

                {/* */}
                <Route path="/:guid/preferences" render={(props) => (
                  <Preferences {...props} {...this.state} {...allPageFunctions} />
                )} />

                {/* note the logo isn't loading on this page for some reason */}
                <Route
                  exact
                  path="/:guid/archived"
                  render={(props) => (
                    <Archived {...allPageFunctions} guid={props.match.params.guid} />
                  )}
                ></Route>

                <Route exact path="/:guid/grant" render={(props) => (
                  <Grant {...allPageFunctions}
                    owner={this.state.owner}
                    title={this.state.title}
                    accountTheme={this.state.accountTheme}
                    userDetails={this.state.userDetails} />
                )}></Route>

                <Route path="/:guid/403/:type?"
                  exact
                  render={(props) => (
                    <Unauthorized {...this.state} type={props.match.params.type} />
                  )}>
                </Route>

                {/* I think main should be in charge of loading the data */}
                <Route path="/:guid" render={(props) => (
                  <Main
                    {...props}
                    {...this.state}
                    {...allPageFunctions}
                    minimalPreviewMode={minimalPreviewMode}
                    validateOrGateUser={this.validateOrGateUser}
                    onUsersUpdate={this.onUsersUpdate}
                    onMediaOrderChanged={this.onMediaOrderChanged}
                    showAddMediaTrayFunc={this.showAddMediaTrayFunc}
                    onMediaDeleted={this.onMediaDeleted}
                    refreshMedia={this.refreshMedia} />
                )} />

                <Route path="*">
                  <Error {...allPageFunctions} />
                </Route>
              </Switch>
            </div>

            <Footer {...this.state} isFullWidth={minimalPreviewMode} />
          </UserContext.Provider>
        </SalesContactContext.Provider>
      </div >
    );
  }
}

const functionalAppWrapper = (p) => {
  const editorStore = useEditorStore();
  return <App {...p} editorStore={editorStore} />
}

export default withRouter(functionalAppWrapper);
