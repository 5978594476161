import './_styles.scss';

import React from 'react';
import ModusLogo from 'assets/modus-logo.jpg';

export default function CenteredGateWrapper(props) {

  const closeGate = (e) => {
    if (props.isEasilyDismissible && props.dismissPrivacyGate) {
      if (e.target !== e.currentTarget) return;
      props.dismissPrivacyGate();
    }
  }

  return (
    <div className="centered-gate-wrapper" onClick={closeGate}>
      <div className='gate-content'>
        {!props.isLogoHidden && <img className="modus-logo" src={ModusLogo} />}
        <div className='child-content'>
          {props.children}
        </div>
      </div>
    </div>
  );
}
