import React from 'react';
import './index.scss';

export default function ArchivedView(props) {
  return (
    <div className="archived-page">
      <p>
        Sorry, the digital sales room you are trying to access has been archived. Please verify the
        link you entered is correct. If you still need access please contact the owner.
      </p>
    </div>
  );
}
