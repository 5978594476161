import './index.scss';
import 'react-quill/dist/quill.snow.css';

import React, { useState, useContext } from 'react';
import Modal from 'components/modal';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Loader from 'components/loader';
import Api from 'services/api';
import { useFormInput } from 'utilities/hooks';
import ReactQuill from 'react-quill';
import ReactTooltip from 'react-tooltip';
import { UserContext } from 'contexts/user-context';
import { UsersUtility, LanguageUtility, EmailUtility } from 'utilities';
import { getGenericErrorMessage } from 'utilities/helper-methods';
import { InviteEmailTemplate } from 'models/email-templates';

export default function InviteModal(props) {
  const localize = LanguageUtility.getLocalization;
  const existingUsers = props.users.detailed;
  const user = useContext(UserContext);

  const [email, emailRef] = useFormInput('');
  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [showShareableLink, setShowShareableLink] = useState(false);
  const [shareableLink, setShareableLink] = useState('');

  const defaultInvite = `<p>${localize(props, "Default invite text")}</p>`;
  const [customInvite, setCustomInvite] = useState(defaultInvite);

  const sendInvite = async function (e) {
    setIsSendingInvite(true);

    //validate email
    let emailValidation = email.validate();
    if (!emailValidation.isValid) {
      toast.error(emailValidation.message);
      setIsSendingInvite(false);
      return;
    }

    let inviteEmailProps = new InviteEmailTemplate(user?.email, email.value, props.company, customInvite);
    let response = await EmailUtility.sendInviteEmail(inviteEmailProps);

    if (!response.success) {
      toast.error('Invitation failed to send, please try again later.');
      return;
    }

    const existingUser = existingUsers.find((u) => u.email === email.value);
    if (existingUser) {
      Api.updateMemberStatus(existingUser.email, 'invited').then((res) => {
        const index = existingUsers.indexOf(existingUser);
        existingUsers[index].status = 'invited';
        props.onUsersUpdate(existingUsers);
      }).catch((err) => {
        toast.error(`Failed to invite ${member.email}. ${getGenericErrorMessage()}`);
        return;
      });
    }

    setIsSendingInvite(false);
    toast.success('Invitation sent successfully');
    props.onRequestClose();
  };

  const handleCustomInviteChange = async function (e) {
    setCustomInvite(e);
  };

  const submitForm = function (e) {
    e.preventDefault();
    const emailInput = email.value.toLowerCase();
    const inviteValidation = UsersUtility.canUserBeInvited(emailInput, existingUsers, user?.email?.toLowerCase());

    if (inviteValidation.valid) {
      sendInvite();
    } else {
      toast.error(inviteValidation.message);
    }
  };

  const onAfterOpen = function () {
    emailRef.current.focus();
  };

  const toggleShare = function () {
    const link = window.location.href.split('?')[0];
    setShareableLink(link);
    setShowShareableLink(true);
  };

  const handleCopyLink = function (e) {
    e.preventDefault();

    navigator.clipboard.writeText(shareableLink).then(() => {
      toast.success(localize(props, 'Link copied alert'));
    });
  };

  return (
    <Modal {...props} isOpen={true} onAfterOpen={onAfterOpen}>
      <div className="invite-modal-header">
        <div className="invite-label">{localize(props, 'Invite button')}</div>
        <div onClick={toggleShare} className="share-link-toggle">
          {localize(props, 'Invite share link text')}
          <FontAwesomeIcon className="link-icon" size="sm" icon={faLink} />
        </div>
      </div>

      <div className="invite-modal">
        {showShareableLink && (
          <div className="share-link-row">
            <input className="share-link-input" readOnly value={shareableLink} />
            <button className="copy-link-button" onClick={handleCopyLink}>
              {localize(props, 'Copy link button')}
            </button>
          </div>
        )}

        <form className="email-input-form" onSubmit={submitForm} noValidate>
          <input
            ref={emailRef}
            placeholder={localize(props, 'Invite recipient placeholder')}
            type="email"
            required
            maximum-scale="1"
            disabled={isSendingInvite}
            className={email.isValid ? 'email-input' : 'error'}
            {...email.bind} />
          <Loader isActive={isSendingInvite} />
        </form>

        <div className="email-template-container">
          <div className="invite-template-label">
            {localize(props, 'Customize invite header')}
            <FontAwesomeIcon
              data-tip
              data-for="invite-info"
              id="customize-invite-info"
              icon={faInfoCircle}
              size="sm"
            />
            <ReactTooltip id="invite-info">
              <span>
                {localize(props, 'Custom invite tooltip')}
              </span>
            </ReactTooltip>
          </div>
          <ReactQuill
            value={customInvite}
            onChange={handleCustomInviteChange}
            className="template-editor"
          />
        </div>

        <div className="invite-modal-bottom-row">
          <button
            onClick={submitForm}
            className="btn btn-primary"
            type="submit"
            disabled={isSendingInvite}>
            {localize(props, 'Send invite button')}
          </button>
        </div>
      </div>
    </Modal>
  );
}
