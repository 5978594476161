import './index.scss';
import INTERNAL_DEFAULT_EMAIL_PREFERENCES from 'assets/configuration/default_email_preferences_internal.json';
import EXTERNAL_DEFAULT_EMAIL_PREFERENCES from 'assets/configuration/default_email_preferences_external.json';

import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from 'react-switch';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import Api from '../../services/api';
import { UserContext } from 'contexts/user-context';

const guidRegex = new RegExp('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$');

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guid: props.match.params.guid,
      user: null,
      notificationPreferences: [],
      userType: null,

      //checkboxes
      unsubscibeFromAllChecked: false,
      hasLeftConversationChecked: false
    };
  }

  componentDidUpdate() {
    const user = this.context;
    //when the user get's set in App.js we want to load user preferences
    if (!this.state.user && user?.email) {
      this.setState({ user: user?.email });
      this.loadPreferences();
    }
  }

  //Helpers
  checkSettingsForGuid() {
    let settingExists = false;
    this.state.settings.forEach((setting) => {
      if (setting.key === this.state.guid) settingExists = true;
    });
    return settingExists;
  }
  getPreferencesContent() {
    const company = this.props.company;
    const salesContact = this.props.salesContact || {};
    const user = `${salesContact.first_name} ${salesContact.last_name}`;

    if (this.state.userType === 'internal') {
      // Interal
      return {
        header: 'Notification Preferences',
        subheaderText: `Manage system generated notifications for all of your digital sales rooms right here. Let ${company} know what you want to be notified about, when it happens.`,
        updatesHeader: 'Digital Sales Room Updates'
      };
    } else {
      // External
      return {
        header: 'Notification Preferences',
        subheaderText: '',
        updatesHeader: user
          ? `Your conversation with ${user} from ${company}`
          : `Your conversation with ${company}`,
        leaveHeader: 'Leave this conversation',
        leaveTooltip: `You will be removed from this conversation and ${
          user ? user : 'the page owner'
        } will be notified that you have requested to be removed`,
        leaveText: 'Remove me from this conversation.'
      };
    }
  }

  //Loaders
  async loadPreferences() {
    const user = this.context;
    const preferences = await Api.getNotificationPreferences(user.email, this.props.accountId);
    let userType = preferences.type;
    let settings = preferences.settings;

    if (settings.length < 1) {
      switch (userType) {
        case 'internal':
          settings = INTERNAL_DEFAULT_EMAIL_PREFERENCES;
          break;
        default:
          settings = EXTERNAL_DEFAULT_EMAIL_PREFERENCES;
          break;
      }
    }

    //unsubscribed from all check
    let isUnsubscribedFromAll = settings.filter((x) => x.value).length === 0;

    //can receive dsr emails check
    let dsrPreference = settings.filter((x) => x.key === this.state.guid);
    let hasLeftConversation = dsrPreference.length > 0 ? dsrPreference[0].value : false;

    //make sure all values are set - edge case
    if (hasLeftConversation) {
      settings.forEach((x) => {
        if (x.value === undefined) {
          x.value = false;
        }
      });
    }

    //set the state;
    this.setState({
      isLoading: false,
      notificationPreferences: settings,
      userType: userType,
      unsubscibeFromAllChecked: isUnsubscribedFromAll,
      hasLeftConversationChecked: hasLeftConversation
    });
  }

  //Interaction Functions
  handleSwitchChange = (previousValue, key) => {
    let { notificationPreferences } = this.state;
    const preference = notificationPreferences.find((setting) => setting.key === key);
    const index = notificationPreferences.indexOf(preference);
    // Obtain a clone of the current state
    let temp = [...notificationPreferences];
    temp[index].value = !previousValue;
    const settingsEnabled = temp.filter((setting) => setting.value === true).length > 0;
    this.setState({
      notificationPreferences: temp,
      unsubscibeFromAllChecked: !settingsEnabled,
      hasLeftConversationChecked: !settingsEnabled
    });
  };

  handleUnsubscribeChange = () => {
    this.setState({
      unsubscibeFromAllChecked: !this.state.unsubscibeFromAllChecked
    });
    let preferences = this.state.notificationPreferences;
    preferences.forEach((preference) => {
      preference.value = this.state.unsubscibeFromAllChecked;
    });
    this.setState({
      notificationPreferences: preferences
    });
  };

  handleLeaveMicrositeChange = () => {
    let { guid } = this.state;

    let hasLeftConversationChecked = !this.state.hasLeftConversationChecked;
    let newPreferences = this.state.notificationPreferences.map((preference) => {
      if (preference.key !== guid) preference.value = !hasLeftConversationChecked;
      else preference.value = hasLeftConversationChecked;
      return preference;
    });

    this.setState({
      hasLeftConversationChecked: hasLeftConversationChecked,
      notificationPreferences: newPreferences
    });
  };

  handleSavePreferences = () => {
    let { notificationPreferences } = this.state;
    const user = this.context;
    const guidSettingExists = this.checkSettingsForGuid(this.state.notificationPreferences);
    if (!guidSettingExists) {
      // TODO: Signal to the API that this guid should be unsubscribed for the user
      notificationPreferences.push({
        key: this.state.guid,
        title: 'Leave this conversation',
        value: this.state.hasLeftConversationChecked,
        description: 'Remove me from this conversation.'
      });
    }
    try {
      Api.saveNotificationPreferences(
        this.state.guid,
        user.email,
        this.props.accountId,
        notificationPreferences
      ).then((res) => {
        toast.success('Preferences updated successfully.');
      });
    } catch (err) {
      toast.error(
        'There was an error updating your preferences. If this issue persists, please contact support@gomodus.com'
      );
    }
  };

  render() {
    const { company } = this.props;
    const user = this.context;
    const isExternalUser = this.state.userType === 'external';
    const isInternalUser = this.state.userType === 'internal';

    const preferencesContent = this.getPreferencesContent();
    const switchColor = this.props.accountTheme.primaryColor;

    //filter the preferences to only DSR specific preferences
    const filteredPreferences = this.state.notificationPreferences.filter((x) => {
      return !guidRegex.test(x.key);
    });

    return (
      <div className="preferences">
        <h1>{preferencesContent.header}</h1>
        <h3>{preferencesContent.subheaderText}</h3>
        <div className="separator"></div>

        <div className="preferences-content">
          <div className="user-information">
            <div className="label">Your email</div>
            <div>{user?.email}</div>
          </div>
          <div className="separator"></div>
          <div className="preferences-header">
            <div className="header-title">{preferencesContent.updatesHeader}</div>
            <div className="header-methods">
              <FontAwesomeIcon icon={faEnvelope} color="#dedede" /> EMAIL
            </div>
          </div>
          {filteredPreferences.map((setting, index) => (
            <div className="setting-row" key={index}>
              <div className="setting-content">
                <div className="setting-title">{setting.title}</div>
                <div className="setting-description">{setting.description}</div>
              </div>
              <Switch
                onChange={() => this.handleSwitchChange(setting.value, setting.key)}
                checked={setting.value}
                onColor={switchColor}
              />
            </div>
          ))}
          {isExternalUser && (
            <PreferencesCheckboxRow
              title={preferencesContent.leaveHeader}
              description={preferencesContent.leaveText}
              tooltip={preferencesContent.leaveTooltip}
              switchColor={this.props.switchColor}
              onChange={this.handleLeaveMicrositeChange}
              checked={this.state.hasLeftConversationChecked}
            />
          )}

          <div className="separator"></div>

          {isInternalUser && (
            <PreferencesCheckboxRow
              title="All Communications"
              description={`Unsubscribe me from all ${company} digital sales room communications.`}
              className="all-communications"
              switchColor={this.props.switchColor}
              onChange={this.handleUnsubscribeChange}
              checked={this.state.unsubscibeFromAllChecked}
            />
          )}

          <div
            className="save-button"
            style={{
              backgroundColor: switchColor,
              color: '#ffffff'
            }}
            onClick={this.handleSavePreferences}
          >
            SAVE
          </div>
        </div>
      </div>
    );
  }
}

class PreferencesCheckboxRow extends Component {
  render() {
    let className = 'setting-row';
    if (this.props.className) className += ` ${this.props.className}`;

    return (
      <div className={className}>
        <div className="setting-content">
          <div className="setting-title">
            {this.props.title}

            {this.props.tooltip && (
              <ReactTooltip id="tooltip-1" className="tooltip" effect="solid" multiline={true}>
                {this.props.tooltip}
              </ReactTooltip>
            )}

            {this.props.tooltip && (
              <FontAwesomeIcon
                className="tooltip-icon"
                size="1x"
                icon={faInfoCircle}
                data-tip
                data-for="tooltip-1"
              />
            )}
          </div>
          <div className="setting-description">{this.props.description}</div>
        </div>
        <Checkbox
          style={{ color: this.props.switchColor }}
          onChange={this.props.onChange}
          checked={this.props.checked}
        />
      </div>
    );
  }
}

Preferences.contextType = UserContext;
export default Preferences;
