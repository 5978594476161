import React, { useState} from 'react';
import Api from 'services/api';
import MediaList from '../media-list';
import { LanguageUtility, SearchUtility } from 'utilities';
import Spinner from '../../spinner';
import './_search-tab.scss';
import './_dark.scss';
let _debounceTimer;

export default function SearchTab(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [titleSuggestions, setTitleSuggestions] = useState(null);
  const [tagSuggestions, setTagSuggestions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableSearch, setDisableSearch] = useState(false);

  const clearSuggestions = () => {
    setTagSuggestions(null);
    setTitleSuggestions(null);
  }

  const clearSearch = () => {
    setSearchTerm('');
    setSearchResults(null);
    clearSuggestions();
  }

  const search = (searchTerm, tag = null) => {
    props.updatemediaItemsToAdd([]);
    setIsLoading(true);
    setDisableSearch(true);
    if(searchTerm) searchTerm = searchTerm.trim();
    SearchUtility.search(searchTerm, tag).then((results) => {
      clearSuggestions();
      setSearchResults(results);
      setIsLoading(false);
      setDisableSearch(false);
    }).catch(() => {
      clearSuggestions();
      setIsLoading(false);
      setDisableSearch(false);
    });
  };

  const handleTitleSuggestionClick = (suggestion) => {
    let doc = new DOMParser().parseFromString(suggestion, 'text/html');
    let query = doc.body.textContent || '';
    search(query);
  }

  const handleTagSuggestionClick = (tag) => {
    let doc = new DOMParser().parseFromString(tag, 'text/html');
    let query = doc.body.textContent || '';
    search(null, query);
  }

  const handleSearchHistoryClick = (searchItem) => {
    if (searchItem.type === 'query') {
      search(searchItem.title, null);
      setSearchTerm(searchItem.title);
    } else {
      search(null, searchItem.title);
    }
  }

  //Helpers
  const debounce = (func, timeout = 250) => {
    clearTimeout(_debounceTimer);
    _debounceTimer = setTimeout(() => {
      func.apply(null);
    }, timeout);
  }

  const getSearchSuggestions = (text) => {
    if (text.length < 3 || disableSearch) {
      clearSuggestions();
      return;
    }
    setIsLoading(true);
    props.updatemediaItemsToAdd([]); //clear selection
    Api.getSearchSuggestions(text).then((suggestions) => {
      setIsLoading(false);
      setTagSuggestions(suggestions.tag_hits);
      setTitleSuggestions(suggestions.title_hits);
    }).catch(() => {
      setIsLoading(false);
    });
  }

  const searchKeyPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      search(searchTerm);
      clearTimeout(_debounceTimer);
      return;
    }
  }

  const handleSearchInputChange = (e) => {
    setSearchResults();
    setSearchTerm(e.target.value);
    debounce(getSearchSuggestions.bind(null, e.target.value));
  }

  //labels
  const localize = LanguageUtility.getLocalization.bind(null, props);
  const searchHistory = SearchUtility.getRecentSearchHistory();

  return (
    <div className="search-container">
      <div className="search-input-container">
        <i className="fa fa-search"></i>
        <input
          disabled={disableSearch}
          value={searchTerm}
          onChange={handleSearchInputChange}
          onKeyDown={searchKeyPress}
        />
        {searchTerm && (
          <button className="clear-search-button" onClick={clearSearch}>
            <i className="fa fa-times"></i>
          </button>
        )}
        <button
          className={searchTerm === '' ? 'search-button' : 'search-button active'}
          disabled={searchTerm === '' || disableSearch}
          onClick={() => search(searchTerm)}>
          <i className="fa fa-arrow-right"></i>
        </button>
      </div>

      {isLoading || disableSearch ? (
        <div className="loader-container">
          <Spinner isActive={isLoading}/>
        </div>
      ) : (
        <>
          {titleSuggestions && tagSuggestions && (
            <div className="suggestion-container">
              {tagSuggestions && tagSuggestions.length > 0 && (
                <>
                  <span className="sub-title">{localize('Search group by tags title', 'By Tags').toUpperCase()}</span>
                  {tagSuggestions.map((suggestion, i) => {
                    return (
                      <div
                        key={suggestion + i}
                        className="suggestion"
                        onClick={() => handleTagSuggestionClick(suggestion)}>
                        <span className="suggestion-icon">
                          <i className="fa fa-tag"></i>
                        </span>
                        <div
                          className="suggestion-text"
                          dangerouslySetInnerHTML={{ __html: suggestion }}></div>
                      </div>
                    )
                  })}
                </>
              )}
              {titleSuggestions && titleSuggestions.length > 0 && (
                <>
                  <span className="sub-title">{localize('Search group by asset title', 'By Asset Title').toUpperCase()}</span>
                  {titleSuggestions.map((suggestion, i) => {
                    return (
                      <div
                        key={suggestion + i}
                        className="suggestion"
                        onClick={() => handleTitleSuggestionClick(suggestion)}>
                        <div
                          className="suggestion-text"
                          dangerouslySetInnerHTML={{ __html: suggestion }}></div>
                      </div>
                    )
                  })}
                </>
              )}
            </div>
          )}

          {searchResults && <MediaList {...props} media={searchResults} />}

          {!searchResults && !tagSuggestions && !titleSuggestions && searchHistory && (
            <div className="suggestion-container">
              <span className="sub-title">{localize('Search recent terms title', 'RECENTLY SEARCHED TERMS')}</span>
              {searchHistory.map((item, i) => {
                return (
                  <div
                    key={item + i}
                    className="suggestion"
                    onClick={() => handleSearchHistoryClick(item)}>
                    {item.type === 'tag' && (
                      <span className="suggestion-icon">
                        <i className="fa fa-tag"></i>
                      </span>
                    )}
                    <div className="suggestion-text">{item.title}</div>
                  </div>
                )
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}
