import './index.scss';

import React, { Component } from 'react';
import Sortable from '../sortable/index.js';
import { CSSTransition } from 'react-transition-group';
import { hasAccessToken } from '../../utilities/helper-methods';
import { useEditorStore } from 'stores/editorStore';
import MediaHeader from './media-header';

class MediaGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDeleteModalOpen: false,
      isSortable: false
    };

    this.onMediaItemClicked = this.onMediaItemClicked.bind(this);
  }

  onMediaItemClicked = (mediaItem) => {
    //navigateToMedia
    let path = `${this.props.match.url}/${mediaItem.id}`;
    this.props.navigate(path);
  };

  onAddMediaClose = () => {
    this.setState({ addMediaModalOpen: false });
  };

  onMediaOrderChanged = (medias) => {
    this.props.onMediaOrderChanged(medias);
  };

  render() {
    const { medias, settings, isOwner, accountSettings } = this.props;
    const allowAddContent =
      (isOwner && settings.enableAddContent) ||
      (settings.enableExternalAddContent && hasAccessToken());
    const isHeaderVisible =  settings?.enablePageHeader && accountSettings?.enableDSR_2_0;
    
    return (
      //TODO: probably remove this transition
      <CSSTransition in={true} timeout={1000} classNames="fade">
        <div className="media-grid-container">
          {isHeaderVisible &&
            <MediaHeader {...this.props} />
          }

          <div className="media-grid">
            <Sortable
              {...this.props}
              onMediaItemClicked={this.onMediaItemClicked}
              onAddMediaClicked={this.props.showAddMediaTrayFunc}
              data={medias}
              enable={this.state.isSortable}
              onChange={this.onMediaOrderChanged}
              allowAddContent={allowAddContent}
              onMediaDeleted={this.props.onMediaDeleted}
              refreshMedia={this.props.refreshMedia}
            />
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default MediaGrid;
