import { getParameterByName } from './helper-methods';

const isMacOS = function () {
  return navigator.maxTouchPoints === 0 && /MacIntel/.test(navigator.platform);
};

export default class ConnectedDeviceUtility {
  //NOTE: maybe just put this straight in the helpers function?
  static attemptDeepLink(guid, settings) {
    //return;
    const os = getParameterByName('os');
    const allowDeepLinking = settings.enableDeepLinks;

    // Prevent Safari desktop crash
    if (!os && allowDeepLinking && !isMacOS()) {
      try {
        setTimeout(function () {
          window.location = `x-appdataroom:///followup?guid=${guid}`;
        }, 100);
      } catch {}
    }
  }
}
