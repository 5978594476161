import React, { useRef } from 'react';

export default function ImageViewer(props) {
  let imgRef = useRef();

  const { src } = props;

  const imageLoaded = function (e) {
    const parentHeight = e.target.parentElement.clientHeight;

    let modifiedHeight = parentHeight * 0.9;
    let sourceHeight = e.target.height;
    let childHeight = Math.min(sourceHeight, modifiedHeight);
    let diff = parentHeight - childHeight;

    if (diff > 0) {
      let marginTop = diff / 2;
      if (marginTop > 40) marginTop = 40;
      imgRef.current.style['margin-top'] = marginTop + 'px';
      imgRef.current.style['opacity'] = '1';
    }
  };

  return (
    <div className="image-viewer">
      <img ref={imgRef} src={src} alt="" onLoad={imageLoaded} />
    </div>
  );
}
