import './index.scss';

import React, { useState, useContext } from 'react';
import { Api, AuthenticationContext } from 'services';
import { EmailUtility } from 'utilities';
import { UserContext } from 'contexts/user-context';
import { toast } from 'react-toastify';

export default function UnauthorizedPage(props) {
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const showRequestAccessButton = props.type === 'REMOVED';
  const showLogOutButton = props.type === 'INVALIDEMAIL';

  const { email: userEmail } = useContext(UserContext);

  const requestAccess = async () => {
    if (!user) {
      toast.error('Owner not found, unable to send email');
      return;
    }

    const requestAccessBody = await EmailUtility.getEmailHtmlFromTemplate('access_request', props);

    const obj = {
      send_to: props.owner,
      from: 'notice@gomodus.com',
      body: requestAccessBody,
      subject: `New activity on a followup you sent`,
      type: 'invite'
    };

    Api.sendNotificationEmail(obj).catch((ex) => {
      toast.error('Something went wrong. If this issue persists, please contact support@gomodus.com');
    });
  };

  const onRequestAccessClick = () => {
    setRequestSubmitted(true);
    setButtonDisabled(true);
    requestAccess();
  };

  const getMessage = () => {
    switch (props.type) {
      case "REMOVED":
        return 'The owner of this page has chosen to remove you from this conversation. If you wish to request access, click the button below to notify the page owner.';
      case "FORBIDDEN":
        return 'You do not have access to this page.';
      case "INVALIDEMAIL":
        return `Sorry, the user you are currently logged in as has an invalid email address ( ${user} ), please log out to continue`;
      default:
        return "Sorry you don't seem to have access to this content";
    }
  }

  return (
    <div className="unauthorized-page">
      <p className="content">{getMessage()}</p>
      {showRequestAccessButton && (
        <button disabled={buttonDisabled} className="btn" onClick={() => onRequestAccessClick()}>
          Request Access
        </button>
      )}
      {showLogOutButton && (
        <button disabled={buttonDisabled} className="btn" onClick={AuthenticationContext.logout}>
          Log Out
        </button>
      )}
      {requestSubmitted && (
        <div className="success-message animate__animated animate__fadeInDown">
          The page owner has been notified of your request. You will receive an email if access is
          granted.
        </div>
      )}
    </div>
  );
}
