import { LanguageUtility } from 'utilities';
import Api from 'services/api';

let _inverseExtensionMap;
const _extensions = {
  video: ['mp4', 'avi', 'm4v', 'mov', 'wmv', 'swf'],
  image: ['png', 'jpg', 'jpeg', 'gif', 'tif', 'svg', 'eps'],
  audio: ['mp3', 'm4a'],
  powerpoint: ['ppt', 'pptx', 'ppsx'],
  document: ['doc', 'docx', 'pages'],
  text: ['key', 'txt', 'vcf'],
  igs: ['igs'],
  spreadsheet: ['xlsx', 'xls', 'csv', 'numbers', 'xlsm'],
  pdf: ['pdf'],
  zip: ['zip'],
  link: ['html'],
  other: ['epub', 'ppsx'],
  vpt: ['vpt']
};

const _typeLocalizationKeys = {
  video: "Video type label",
  image: "Image type label",
  audio: "Audio type label",
  powerpoint: "Presentation type label",
  document: "Word type label",
  text: "Text type label",
  igs: "IGS type label",
  spreadsheet: "Spreadsheet type label",
  pdf: "PDF type label",
  zip: "ZIP type label",
  link: "Website type label",
  other: "Other type label",
  vpt: "VPT type label",
  youtube: "Website type label"
};

const _matchRegExp = function (url) {
  if (url) {
    const vptRegExp = /(https:\/\/mxstudio.appdataroom.com\/\?f=.*)/;

    const youtubeRegExp = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/;
    if (url.match(youtubeRegExp)) {
      return 'youtube';
    }
    if (url.match(vptRegExp)) {
      return 'vpt';
    }
    return 'website';
  }

  return null;
};

export default class MediaUtility {

  static getMediaType(media) {
    //create an inverse extension map which will make find the media type easier
    if (!_inverseExtensionMap) {
      _inverseExtensionMap = {};
      Object.keys(_extensions).forEach(key => {
        let values = _extensions[key];
        values.forEach((value) => { _inverseExtensionMap[value] = key; })
      });
    }

    const ext = media.ext;
    const urlMatchType = _matchRegExp(media.link);

    //url based type check
    if (urlMatchType && !ext) {
      return urlMatchType;
    }

    //return based on extension
    let mediaType = _inverseExtensionMap[ext];

    return mediaType || "other";
  }

  static getMediaTypeLabel(props, media) {
    let mediaType = this.getMediaType(media);
    let localizationKey = _typeLocalizationKeys[mediaType] || null;
    let localization = LanguageUtility.getLocalization(props, localizationKey, mediaType);
    return localization;
  }

  static getMediaIds(media) {
    return media.map(x => {
      return x.media_id;
    });
  }

  static async getRecentlyViewedMedia(pageActivity) {
    return await Api.getRecentlyViewedMedia();
  }
}
