import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AccessGrantedEmailTemplate } from 'models/email-templates';
import { EmailUtility } from 'utilities';
import { Api } from 'services';
import { getParameterByName, removeURLParameter, base64Decode } from 'utilities/helper-methods';
import Spinner from 'components/spinner';
import './index.scss';

export default function GrantPage({ owner, title, userDetails, accountTheme }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(async () => {
    let userRequestingAccess = getParameterByName("r");

    //Process invited user
    if (userRequestingAccess) {
      removeURLParameter("r");
      userRequestingAccess = base64Decode(userRequestingAccess);
      const invitedUser = userDetails[userRequestingAccess] || { email: userRequestingAccess, status: "removed" };

      if (invitedUser?.status === "removed") {

        //Add user
        try {
          let updateMemberResponse = await Api.updateMemberStatus(userRequestingAccess, 'invited');
          setMessage(`${userRequestingAccess} has been invited to join the conversation.`);
        } catch (ex) {
          console.error(ex);
          setIsLoading(false);
          setIsError(true);
          setMessage(`There was an unexpected error trying to invite that user to the conversation. Please try again later`);
          return;
        }

        //Alert Email
        try {
          //NOTE: this email should get fired from the api call i think and not the frontend
          const logo = accountTheme?.logo;
          const accessGrantedEmailProps = new AccessGrantedEmailTemplate(userRequestingAccess, owner, title, logo);
          const emailResponse = await EmailUtility.sendAccessApprovedNotificationEmail(accessGrantedEmailProps);
        } catch (ex) {
          console.error(ex);
          toast.error(`There was an unexpected error sending an email to the user ${userRequestingAccess}`)
        }
      }
      else {
        setIsError(true);
        setMessage(`${userRequestingAccess} is either active or has already been invited to this conversation.`);
      }

    } else {
      setIsError(true);
      setMessage(`Unable to give access, username was blank`);
    }

    setIsLoading(false);
  }, [])

  return (
    <div className="grant-page">
      {isLoading &&
        <div className='spinner-container'>
          <Spinner isActive={true} />
        </div>
      }

      {!isLoading &&
        <p className={isError ? "error" : ""}>
          {message}
        </p>
      }
    </div>
  );
}
