import './_styles.scss';
import React, { Component } from 'react';
import { UsersUtility } from 'utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { UserContext } from 'contexts/user-context';
import BeatLoader from 'react-spinners/BeatLoader';

class NavbarTitle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditor: false
    };

    this.getDefaultTitle = this.getDefaultTitle.bind(this);
    this.enableEditMode = this.enableEditMode.bind(this);
    this.exitEditMode = this.exitEditMode.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  getDefaultTitle() {
    const { isOwner, userDetails, salesContact, owner, company } = this.props;
    const user = this.context;
    const members = UsersUtility.getMemberEmails(userDetails, user?.email);
    let title = `${company} Digital Sales Room.`;

    if (isOwner && members.length > 0) {
      const firstMember = members[0];
      const membersCount = members.length;

      title = `Your conversation with ${firstMember}`;

      if (membersCount > 1) {
        title += ` and ${membersCount - 1} other${membersCount - 1 > 1 ? 's' : ''}`;
      }
    } else if (!isOwner) {
      let ownerName = owner;

      if (salesContact && salesContact.first_name && salesContact.last_name) {
        ownerName = salesContact.first_name + ' ' + salesContact.last_name;
      }

      title = `Your conversation with ${ownerName} at ${company}.`;
    }

    return title;
  }

  //Edit
  enableEditMode() {
    if (!this.props.isEditable) return;
    this.props.setPrevTitle(this.props.title);
    this.setState({ showEditor: true });
  }
  exitEditMode() {
    if (!this.props.isEditable) return;
    this.setState({ showEditor: false });
  }
  handleTitleChange(e) {
    const value = e.target.value;
    this.props.onStatePropertyChanged('title', value, true);
  }
  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.exitEditMode();
    }
  }

  render() {
    //visibility check
    if (this.props.isLoading || !this.props.settings.showTitle || this.props.navbar_hideTitle) {
      return null;
    }

    //variables
    const { isEditable } = this.props;
    const title = this.props.title === null ? this.getDefaultTitle() : this.props.title;
    const isEditIconVisible = isEditable && !this.props.isSaving;
    const editClass = isEditable ? ' editable' : '';

    return (
      <div className={'navbar-title ' + this.props.className}>
        {/* Plain Text */}
        {!this.state.showEditor && (
          <div className={'title-container' + editClass} onClick={this.enableEditMode}>
            <div className="title"> {title} </div>
            {isEditIconVisible && (
              <FontAwesomeIcon className="title-icon" icon={faPencilAlt} size="sm" />
            )}
          </div>
        )}

        {/*Editing */}
        {this.state.showEditor && (
          <div className="edit-title-container">
            <input
              value={title}
              autoFocus
              onBlur={this.exitEditMode}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleTitleChange}
            />
            <button
              className="btn-icon"
              onClick={this.exitEditMode}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}>
              <FontAwesomeIcon className="title-icon" icon={faCheck} size="sm" />
            </button>
          </div>
        )}

        {/*Add spinner */}
        {this.props.isSaving && (
          <div className="spinner-container">
            <BeatLoader size={8} />
          </div>
        )}
      </div>
    );
  }
}

NavbarTitle.contextType = UserContext;
export default NavbarTitle;
