import './_styles.scss';
import React, { Component } from 'react';
import DealerLogo from './dealer/_dealer-logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useEditorStore } from 'stores/editorStore';
const DEFAULT_LOGO = 'https://s3.amazonaws.com/resources.appdataroom.com/branding/modus-logo-no_tagline.png';

function Logo(props) {
  const hasPathname = window.location.pathname !== '/';
  const { isLoading, accountTheme } = props;
  const { darkMode } = useEditorStore();

  //If logo exists overide the default
  let logo;
  if (!darkMode) logo = accountTheme?.altLogo;
  if (!logo) logo = accountTheme?.logo;
  if (!logo) logo = DEFAULT_LOGO;

  //Only return a skeleton if we are not missing a followup code, and we don't have an error or rejected promise
  if (isLoading && hasPathname) {
    return <div className="logo-skeleton skeleton" />;
  }

  if (accountTheme.logo === 'hide') {
    return null;
  }

  return <img className="logo animate fadeIn" alt="company logo" src={logo} />;
}

export default class Logos extends Component {
  render() {
    const { isEditable } = this.props;
    const editClass = isEditable ? ' editable' : '';

    return (
      <div className={'logos-container' + editClass} onClick={this.props.onClick}>
        <div className="company-logo">
          <Logo {...this.props} />
        </div>
        <div className="dealer-logo">
          <DealerLogo />
        </div>

        {/* Editable Indicator */}
        {isEditable && <FontAwesomeIcon icon={faPencilAlt} size="sm" />}
      </div>
    );
  }
}
