import './_styles.scss';

import React, { useState, useRef, useEffect, useContext } from 'react';
import Api from 'services/api';
import { LanguageUtility } from 'utilities';
import { UserContext } from 'contexts/user-context';
import Modus from 'modus-javascript-bridge/dist/modus-js-bridge.js';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faUpload, faLink } from '@fortawesome/free-solid-svg-icons';
import { getCurrentGuid, getParameterByName } from 'utilities/helper-methods';
import Spinner from 'components/spinner';

export default function RecentTab(props) {
  //state
  const [innerView, setInnerView] = useState(null); //null | 'link'
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);

  const [linkName, setLinkName] = useState('');
  const [linkUrl, setLinkUrl] = useState('');

  //other variables
  const { email: userEmail } = useContext(UserContext);
  const guid = getCurrentGuid();
  const os = getParameterByName('os');
  const isWebView = os === 'web' || !os; //?
  const localize = LanguageUtility.getLocalization.bind(null, props);
  const hasAddAppMediaSupport = ['windows', 'rn'].indexOf(os) === -1;
  let inputElement = useRef(null);

  useEffect(() => {
    if (!innerView) {
      setLinkName('');
      setLinkUrl('');
    }
  }, [innerView]);

  //builds the buttons
  const buttons = [
    {
      type: 'link',
      label: localize('Add External Link Label', 'Add External Link'),
      icon: faLink
    }
  ];

  if (os && hasAddAppMediaSupport) {
    buttons.splice(0, 0, {
      type: 'app_media',
      label: localize('App Media Label', 'App Media'),
      icon: faImage
    });
  }

  if (hasAddAppMediaSupport) {
    buttons.push({
      type: isWebView ? 'web_external_media' : 'external_media',
      label: localize('Upload from Device Label', 'Upload from Device'),
      icon: faUpload
    });
  }

  //UI
  const MediaButton = function (props) {
    switch (props.type) {
      case 'app_media':
        return (
          <button className="media-button" onClick={() => onMediaButtonClick(props.type)}>
            <FontAwesomeIcon className="icon" id="add-media-icon" icon={props.icon} />
            <div id="label">{props.label}</div>
          </button>
        );
      case 'link':
        return (
          <button className="media-button" onClick={() => setInnerView('link')}>
            <FontAwesomeIcon className="icon" id="add-media-icon" icon={props.icon} />
            <div id="label">{props.label}</div>
          </button>
        );
      case 'web_external_media':
        return (
          <button className="media-button" onClick={() => inputElement.click()}>
            <FontAwesomeIcon id="add-media-icon" className="icon" icon={props.icon} />
            <div id="media-label">{props.label}</div>
            {isWebView && (
              <input
                type="file"
                name="file"
                id="external-media-input"
                ref={(input) => (inputElement = input)}
                onChange={(e) => handleWebUploadClick(e)}></input>
            )}
          </button>
        );
      case 'external_media':
        return (
          <button className="media-button" onClick={() => onMediaButtonClick(props.type)}>
            <FontAwesomeIcon id="add-media-icon" className="icon" icon={props.icon} />
            <div id="media-label">{props.label}</div>
            {isWebView && (
              <input
                type="file"
                name="file"
                id="external-media-input"
                ref={(input) => (inputElement = input)}
                onChange={(e) => handleWebUploadClick(e)}></input>
            )}
          </button>
        );
      default:
        return;
    }
  };

  //TODO: make seperate methods for each button
  const onMediaButtonClick = function (type) {
    type = type.toLowerCase();
    switch (type) {
      case 'app_media':
        const medias = props.medias.map((a) => a.id).filter((b) => b != null);
        setIsUploadingMedia(true);
        Modus.getAssetsWithPicker(medias)
          .then((newAssets) => {
            //need to handle conversion of media array to support old iOS
            if (Array.isArray(newAssets)) {
              newAssets = { mediaIds: newAssets };
            }
            return addAssetsToFollowup(newAssets);
          }).catch((e) => {
            setIsUploadingMedia(false);
            console.log(e);
          });
        return;
      case 'external_media':
        try {
          const dict = {
            type: 'followup_media',
            guid: guid,
            user: userEmail
          };
          Modus.getDeviceFilePicker(dict)
            .then(() => {
              window.location.reload();
            })
            .catch((e) => {
              console.log(e);
            });
        } catch (e) {
          console.log(e);
        }
        return;
      default:
        return;
    }
  };

  const addAssetsToFollowup = async function (newAssets) {
    const { mediaIds, vptIds } = newAssets;
    try {
      await Api.addItemsToFollowup(guid, userEmail, mediaIds, vptIds);
      setIsUploadingMedia(false);
      window.location.reload();
    } catch (e) {
      setIsUploadingMedia(false);
      console.log('failed to add media to followup', e);
    }
  };

  const handleWebUploadClick = async function (e) {
    e.preventDefault();
    setIsUploadingMedia(true);
    const file = e.target.files[0];
    try {
      const res = await Api.uploadExternalMedia(file, getCurrentGuid(), userEmail);
      const media_id = res.data.media_id || false;
      await props.refreshMedia();
      setIsUploadingMedia(false);
      let iterations = 0;
      const poller = setInterval(async () => {
        iterations++;
        let refreshedMedia = await props.refreshMedia();
        const mediaItem = refreshedMedia.filter(obj => obj.id === media_id)[0];
        if ((mediaItem && !mediaItem.pending && mediaItem.thumbnail_url) || iterations > 7) {
          clearInterval(poller);
        }
      }, 5000);
    } catch (err) {
      setIsUploadingMedia(false);
      if (err.response) {
        toast.error(`Media upload failed. Response code ${err.response.status}`);
      }
    }
  };

  const onSaveLinkButtonClick = async function (e) {
    e.preventDefault();
    // TODO: Sanitze URL and make sure it includes a protocol
    setIsUploadingMedia(true);
    const data = await Api.uploadLink(getCurrentGuid(), userEmail, linkName, linkUrl)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        setIsUploadingMedia(false);
        let message = `Link upload failed.`;
        if (err.response) {
          message += ` Response code ${err.response.status}`;
        }

        toast.error(message);
      });
    return data;
  };

  const onCancelClicked = () => {
    if (innerView) {
      setInnerView(null);
      return;
    } else {
      props.dismissAddMediaTray();
    }
  };

  const validateURL = function (urlString) {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const isLinkValid = validateURL(linkUrl);
  const thumbnailStyles = isLinkValid ? { backgroundImage: `url(${linkUrl})` } : false;
  const showAddAction = !!innerView;
  const isAddButtonDisabled = !isLinkValid || !linkName;

  return (
    <div className="my-stuff">
      <div className="content">
        {innerView === null && (
          <div className="buttons">
            {buttons.map((button) => (
              <MediaButton
                icon={button.icon}
                label={button.label}
                type={button.type}
                key={button.type}
              />
            ))}
          </div>
        )}

        {innerView === 'link' && (
          <>
            <div className="bread-crumb">
              <button onClick={() => setInnerView(null)}>My Stuff</button>
              <i className="fa fa-chevron-right"></i>
              <span>{localize('Add External Link Label', 'Add External Link')}</span>
            </div>
            <div className="add-link-view">
              <form>
                <div className="form-row">
                  <label>{localize('External Link Label', 'External Link')}</label>
                  <input
                    value={linkUrl}
                    placeholder="http://"
                    type="url"
                    onChange={(e) => setLinkUrl(e.target.value)}
                  />
                </div>
                <div className="form-row">
                  <label>{localize('Name Label', 'Name')}</label>
                  <input
                    value={linkName}
                    type="text"
                    id="link-name-input"
                    autoComplete="off"
                    onChange={(e) => setLinkName(e.target.value)}
                  />
                </div>
              </form>

              <div className="preview-label">
                {localize('Link Preview Label', 'Link Preview Image')}
              </div>

              {thumbnailStyles ? (
                <div className="image-preview" style={thumbnailStyles}></div>
              ) : (
                <div className="image-preview-placeholder">
                  <span>
                    <i>{localize('Link Preview Label', 'Image generated once link is added')}</i>
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      {isUploadingMedia && (
        <div className="loader-container">
          <Spinner isActive={isUploadingMedia} />
        </div>
      )}

      <div className={`actions ${showAddAction ? 'active' : ''}`}>
        <button className="cancel" onClick={onCancelClicked}>
          {localize('Cancel Label', 'Cancel')}
        </button>

        {showAddAction && (
          <button
            className="add"
            disabled={isAddButtonDisabled}
            onClick={(e) => onSaveLinkButtonClick(e)}>
            {localize('Add Asset Label', 'Add Asset')}
          </button>
        )}
      </div>
    </div>
  );
}
