import React from 'react';
import { LanguageUtility } from 'utilities';
import { paramExists, getSettingsFromParams } from 'utilities/helper-methods';
import UserDetails from "components/navbar/user-details";
import './styles.scss';

const LOCAL_SETTINGS = getSettingsFromParams();

class BasicAction extends React.Component {
  render() {
    if (!this.props.visible) return null;
    let className = `btn ${this.props.className}`;

    return (
      <button className={className} onClick={this.props.onClick} disabled={this.props.disabled}>
        {this.props.icon && <i className={this.props.icon}></i>}
        <span className='label'>{this.props.label}</span>
      </button>
    );
  }
}

class EditorModeAction extends React.Component {
  render() {
    if (!this.props.visible) return null;

    return (
      <button className="btn alt btn-edit-content" onClick={this.props.onClick}>
        <span className="sm-label">Edit</span>
        <span className="label">Editor Mode</span>
      </button>
    );
  }
}

class InviteAction extends React.Component {
  //TODO: render the invite modal here - conditionally
  render() {
    let inviteLabel = LanguageUtility.getLocalization(this.props, 'Invite button');

    return (
      <BasicAction
        className="btn-invite"
        onClick={this.props.onClick}
        label={inviteLabel}
        disabled={this.props.disabled}
        visible={this.props.visible}
      />
    );
  }
}

class NavbarActions extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    //exit clause
    if (this.props.hidden) return null;

    //grandparent props
    const { isOwner, editorStore } = this.props;
    const { changesMade, editContent } = editorStore;

    const { navbar_hideActions, navbar_hideSettings, settings } = this.props;

    //direct props
    let { onShareClick, onInviteClick, onDoneClick, disabled, handleCancelClick } = this.props;

    //derived
    let isEditable = isOwner && !navbar_hideActions;

    //buttons
    const isShareButtonVisible = isEditable && !editContent && paramExists('os');

    const isDSRPrivate = this.props.privacy_setting === 'PRIVATE';
    const isInviteVisible = !isDSRPrivate && !isOwner && !navbar_hideActions && LOCAL_SETTINGS.showInviteButton;

    let isEditorModeToggleVisible = isEditable && !editContent;
    let isDoneButtonVisible = isEditable && editContent;

    //labels - TODO: localize
    let saveButtonLabel = LanguageUtility.getLocalization(this.props, 'Save button');
    let shareButtonlabel = "Share";

    return (
      <div className="navbar-actions">
        {/* admin controls - these become inactive during editing*/}
        <BasicAction {...this.props}
          className="btn-share"
          onClick={onShareClick}
          icon="fa fa-share"
          label={shareButtonlabel}
          visible={isShareButtonVisible} />

        <EditorModeAction onClick={() => editorStore.onEditContentChanged()} visible={isEditorModeToggleVisible} />

        {/* invite control */}
        <InviteAction settings={settings} onClick={onInviteClick} disabled={disabled} visible={isInviteVisible} />

        {/* admin - edit mode active controls */}
        <BasicAction
          className="btn-done"
          onClick={onDoneClick}
          label={saveButtonLabel}
          disabled={disabled}
          visible={isDoneButtonVisible}
        />
        <BasicAction
          className="alt btn-cancel"
          onClick={handleCancelClick}
          label="Cancel"
          disabled={disabled}
          visible={isDoneButtonVisible && changesMade}
        />

        {!navbar_hideSettings &&
          <UserDetails
            {...this.props}
            isEditable={isEditable}
            disabled={disabled}
          />
        }
      </div>
    );
  }
}

export default NavbarActions;
