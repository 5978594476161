import { getParameterByName } from 'utilities/helper-methods';
import seedrandom from 'seedrandom';

const DEFAULT_THEME = {
  background:
    'https://s3.amazonaws.com/m3.appdataroom.com/custom_styles/images/general/followup_background.png',
  primaryColor: '#4182FF',
  secondaryColor: '#ffffff'
};

const BLANK_THEME = {
  background: null,
  primaryColor: '#000000',
  secondaryColor: '#ffffff'
};

export default class ThemeUtility {
  //Not Account Related - move to the Avatar class
  static getAvatarStyles(email) {
    //don't really need to pass the email - could build a different randomizer
    const isDarkMode = this.isDarkMode();
    const rgbs = ['#ff4080', '#4285f4', '#7e3794', '#67ae3f'];
    const rng = seedrandom(email);
    const value = Math.trunc((rng(email) * 10) % rgbs.length);
    return isDarkMode ? '#4d4d4d' : rgbs[value];
  }
  static isDarkMode() {
    const queryParam = getParameterByName('dark');
    if (queryParam) {
      const darkMode = parseInt(getParameterByName('dark')) === 1 ? true : false;
      localStorage.setItem('dark', darkMode);
      return darkMode;
    } else if (localStorage.getItem('dark')) {
      let localStorageValue = JSON.parse(localStorage.getItem('dark'));
      return localStorageValue;
    } else {
      return window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  }

  //Account Theme Stuff
  static getBlankTheme() {
    return BLANK_THEME;
  }
  static getValidTheme(theme) {
    if (!theme) {
      return DEFAULT_THEME;
    }

    const modusRed = '#4182FF';
    const white = '#ffffff';
    if (theme && !theme.primaryColor) theme.primaryColor = modusRed;
    if (theme && !theme.secondaryColor) theme.secondaryColor = white;
    if (theme.primaryColor === '#ffffff') theme.primaryColor = '#000000';
    return theme;
  }
  static getBackgroundImage(applicationState) {
    //NOTE: accountTheme.background is the new property in new DSR theme manager
    //NOTE: accountTheme.backgroundImage for legacy background, use if account doesn't have new one set

    const { isLoading, darkMode, accountTheme, isBackgroundImageVisible } = applicationState;
    let backgroundImage = null;

    if (!isLoading && !darkMode && accountTheme && isBackgroundImageVisible) {
      //cascade
      backgroundImage = accountTheme.background;
      if (!backgroundImage) {
        backgroundImage = accountTheme.backgroundImage;
      }

      //mobile - this isn't responsive but it's fine.
      if (window.innerWidth < 480 && accountTheme.phoneBackground) {
        backgroundImage = accountTheme.phoneBackground;
      }

      //ignore use the "default" image in MM
      if (backgroundImage && backgroundImage.indexOf("5e99d4f13dc6e.gif") > -1) {
        backgroundImage = null;
      }

      //set
      backgroundImage = backgroundImage ? 'url("' + backgroundImage + '")' : null;
    }

    return backgroundImage;
  }
}
