import React, { useEffect, useState } from 'react';
import Api from 'services/api';
import MediaList from '../media-list';
import { MediaUtility } from 'utilities';
import Spinner from '../../spinner';
import './_recent-tab.scss';

export default function RecentTab(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [recentlyAddedMedia, setRecentlyAddedMedia] = useState([]);

  useEffect(() => {
    MediaUtility.getRecentlyViewedMedia(props.pageActivity).then((media) => {
      setRecentlyAddedMedia(media);
      setIsLoading(false);
    });
  }, [props.mediaItemsToAdd]);

  return (
    <div className="recent-container">
      {isLoading ? (
        <div className="loader-container">
          <Spinner isActive={isLoading} />
        </div>
      ) : (
        <>{recentlyAddedMedia && <MediaList {...props} media={recentlyAddedMedia} />}</>
      )}
    </div>
  );
}
