import LocalStorageModule from 'modules/local-storage-module';
import { UsersUtility, EmailUtility } from "utilities";
import { Api } from "services"; // i don't really understand how this works because it's a circular reference...
import { getParameterByName, removeURLParameter } from 'utilities/helper-methods';

const magicTokenStorage = new LocalStorageModule("magic_tks", true);

export default class AuthenticationContext {

    //move this so it's only internal?
    static passcode = new LocalStorageModule("pcd", false, true);

    //Intialize
    static initialize() {
        //check for magic tokens
        let newMagicToken = getParameterByName('t');
        if (newMagicToken) {
            removeURLParameter('t');
            magicTokenStorage.set(newMagicToken);
            UsersUtility.setNewUserEmail();
        }

        //TODO: validate existing user access here validate if user email is present and valid?
    }

    static logout() {
        window.localStorage.clear();
        location.replace(location.origin + location.pathname);
    }

    //Tokens
    static getMagicToken() {
        return magicTokenStorage.get();
    }

    //Password
    static async validatePasscode(newUser, newPasscode) {
        console.log('hi');
        const isNew = newUser && newPasscode;
        const user = newUser || UsersUtility.getStoredUserEmail();
        const pcd = newPasscode || this.passcode.get();
        const validCredentials = await Api.grantUserDSRAccess(user, pcd);
        const isValid = validCredentials === 1;

        if (isValid && isNew) {
            UsersUtility.setNewUserEmail(newUser);
            this.passcode.set(newPasscode);
        }

        return isValid;
    }

    //Validators
    static async validateUserAccess(user, privacySetting) {
        const result = { valid: true, redirect: null };

        //if no user, don't need to validate anything
        if (!user.email || privacySetting === 'PUBLIC') {
            return result;
        }

        //if owner then valid
        if (user.isOwner) {
            return result;
        }

        //Domain that is banned or invalid email
        const emailValidation = await EmailUtility.validateEmail(user.email);
        if (!emailValidation.valid) {
            result.valid = false;
            result.redirect = emailValidation.reason === "DOMAIN" ? "403/FORBIDDEN" : `403/INVALIDEMAIL`;
        }

        //Removed Users- although this should probably happen server side
        if (user.status === 'removed') {
            result.valid = false;
            result.redirect = "403/REMOVED";
        }

        return result;
    }
}
