
import { getCurrentGuid, base64Decode, base64Encode } from 'utilities/helper-methods';

export default class LocalStorageModule {
    constructor(key, distinctByGuid = false, encodeData = false) {
        this.key = key;
        this.distinctByGuid = distinctByGuid;
        this.encodeData = encodeData;
        this.guid = getCurrentGuid();
    }

    getRaw() {
        let json = localStorage.getItem(this.key);

        try {
            json = JSON.parse(json);
        } catch (ex) {

        }

        return json
    }

    //General
    set(newData) {
        if (!newData) return;

        if (this.encodeData) {
            newData = base64Encode(newData);
        }

        if (this.distinctByGuid) {
            let existingData = this.getRaw();
            if (!existingData) { existingData = {} }

            existingData[this.guid] = newData;
            newData = existingData;
        }

        //save it
        localStorage.setItem(this.key, JSON.stringify(newData));
    }

    get() {
        let object = this.getRaw();

        //Handle user based data
        if (object && this.distinctByGuid) {
            object = object[this.guid];
        }

        //Handle encoding
        if (object && this.encodeData) {
            object = base64Decode(object);
        }

        return object;
    }
}
