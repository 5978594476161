import React from 'react';
import Modal from 'components/modal';
import './index.scss';

export default function DeleteModal(props) {
  const { media } = props || {};

  const yesClick = async function () {
    props.onMediaDeleted(media.id);
    props.onRequestClose();
  };

  const cancelClick = function () {
    props.onRequestClose();
  };

  return (
    <Modal isOpen={true} onRequestClose={props.onRequestClose}>
      <div className="modal-body">
        <div className="header">
          Remove <i>{media.title}?</i>
        </div>
        <div className="prompt">
          You must <b>Publish</b> your Digital Sales Room for changes to take effect.
        </div>
        <div className="row">
          <button className="btn btn-primary btn-cancel" onClick={cancelClick}>
            Cancel
          </button>
          <button className="btn btn-primary btn-yes" onClick={yesClick}>
            Remove
          </button>
        </div>
      </div>
    </Modal>
  );
}
