import React from 'react';
import './index.scss';
import { LanguageUtility } from 'utilities';

export default function ErrorView(props) {

  const localize = LanguageUtility.getLocalization;

  return (
    <div className="error-page">
      <p className="error-content">
        {localize(props, 'Error page text', "Sorry, the DSR that you're trying to access is no longer active. Please contact the original sender or recent owner if you have questions.")}
      </p>
    </div>
  );
}
