import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import Spinner from 'components/spinner';
import MediaViewer from 'components/media-viewer';
import MediaGrid from 'components/media-grid';
import ActionTray from 'components/action-tray';

import './index.scss';

export default class Main extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', (e) => {
      if (this.props.changesMade) {
        e.returnValue = 'Are you sure you want to leave?';
      }
    });
  }

  render() {
    return (
      <div className="main-page">
        <section className="content">
          {!this.props.isLoading && (
            <Switch>
              <Route path={`/:guid/:mediaId`} render={(props) => (
                <MediaViewer {...this.props} mediaId={props.match.params.mediaId} />
              )} />
              <Route path={'*'} render={(renderProps) => <MediaGrid {...this.props} {...renderProps} />} />
            </Switch>
          )}
          <Spinner isActive={this.props.isLoading} />
        </section>
        {!this.props.minimalPreviewMode &&
          <ActionTray {...this.props} isOpen={this.props.isActionTrayOpen} />
        }
      </div>
    );
  }
}
