import './_legacy-info-bar.scss';

import React, { useState, useContext } from 'react';
import filesize from 'filesize';
import TroubleDownloadingModal from 'components/trouble-downloading-modal';
import { UserContext } from 'contexts/user-context';
import ReactTooltip from 'react-tooltip';
import Logos from 'components/navbar/logo';
import { LanguageUtility } from 'utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faDownToBracket, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';

const localize = LanguageUtility.getLocalization;

export default function LegacyViewerInfoBar(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { email: userEmail } = useContext(UserContext);

  if (!props.media) { return null; }

  const media = props.media;
  const { title, size } = media;
  const showTitleTooltip = title.length >= 26;
  const formattedSize = size ? filesize(size, { round: 1 }) : null;
  const downloadable = media.url && (media.external_access_options?.download ?? true);
  
  return (
    <div className="legacy-info-bar animate fadeInDown">
      <div className="one">
        {props.totalMediaCount > 1 &&
          <button className="btn back-btn dark" onClick={props.onBackButtonClicked}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        }
        <Logos onClick={(e) => e.preventDefault()} {...props} />
      </div>

      <div
        className="title-container two"
        data-for="viewer-title-tooltip"
        data-tip={showTitleTooltip ? title : null}>
        "{title}"
      </div>

      <div className="three">
        {showTitleTooltip && <ReactTooltip id="viewer-title-tooltip" place="bottom" />}
        <div className="size-container">{formattedSize}</div>
        <div className="options-container">
          {downloadable && (
            <a className="download-button" href={media.url} download >
              <FontAwesomeIcon size="xs" icon={faDownToBracket} />
              <span>{localize(props, 'Download label', 'Download')}</span>
            </a>
          )}
        </div>
        <div className="question-btn" onClick={() => { setIsModalOpen(true); }}>
          <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
        </div>
      </div>

      {isModalOpen &&
        <TroubleDownloadingModal
          isOpen={true}
          media={props.media}
          settings={props.settings}
          accountTheme={props.accountTheme}
          language={props.language}
          onRequestClose={() => { setIsModalOpen(false); }}
        />
      }

    </div>
  );
}
