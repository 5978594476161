import React from 'react';
import './index.scss';
import Modal from 'components/modal';
import Logos from 'components/navbar/logo';
import { toast } from 'react-toastify';
import { useFormInput } from 'utilities/hooks';
import { EmailUtility, UsersUtility } from 'utilities';

export default function NewUserModal(props) {
  const [email, emailRef] = useFormInput('');
  const { accountSettings } = props;
  const showLogoInRegistrationModal = accountSettings.includeLogoInDSRRegistrationModal || false;

  const handleSubmit = async function (e) {
    e.preventDefault();
    const emailValidation = await EmailUtility.validateEmail(email.value);
    if (!emailValidation.valid) {
      toast.error('Please enter a valid email address.');
      return;
    }
    props.onRequestClose();
    UsersUtility.setNewUserEmail(email.value.toLowerCase());
    window.location.reload();
  };

  const onAfterOpen = function () {
    emailRef.current.focus();
  };

  return (
    <Modal {...props} onAfterOpen={onAfterOpen}>
      <form className="new-user-form" onSubmit={handleSubmit}>
        <div className="modal-body">
          {showLogoInRegistrationModal && 
            <div className='company-logo center'>
              <Logos {...props} />
            </div>
          }
          <div className="prompt">Your email address is required to continue.</div>
          <input
            ref={emailRef}
            placeholder="enter email address"
            type="email"
            required
            className={email.isValid ? 'new-user-input' : 'error'}
            {...email.bind}
            autoFocus
          />
          <button className="submit-button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
}
