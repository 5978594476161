import axios from 'axios';
import 'url-search-params-polyfill';
import { AuthenticationContext } from '.';
import { MediaUtility } from 'utilities';
import {
  base64Encode,
  getCurrentGuid,
  getParameterByName,
  hasAccessToken
} from '../utilities/helper-methods';

const apiEndpoint = 'https://api3.appdataroom.com';
const access_token = getParameterByName('access_token');
const guid = getCurrentGuid();

async function post(endpoint, obj) {
  const fullurl = apiEndpoint + endpoint;
  const data = await axios.post(fullurl, obj);
  return data.data;
}

async function get(path, headers) {
  const fullurl = apiEndpoint + path;
  const data = await axios.get(fullurl, headers);
  return data.data;
}

export default class Api {
  static async sendNotificationEmail(email) {
    const obj = {
      send_to: email.send_to,
      from: email.from,
      body: email.body,
      subject: email.subject,
      type: email.type
    };
    return await post(`/followups/${guid}/notify`, obj);
  }

  static async sendMediaEmail(email) {
    const obj = {
      email: email.email,
      guid: email.guid,
      media_id: email.media_id,
      media_name: email.media_name,
      media_size: email.media_size,
      media_url: email.media_url,
      related_media_name: null,
      related_media_size: null,
      related_media_url: null
    };
    const data = await post('/followups/email', obj);
    return data;
  }

  static async getPasscode() {
    let passcode = await axios.get(`${apiEndpoint}/followups/${guid}/passcode`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
    return passcode?.data ? passcode.data : null;
  }

  static async view(guid) {
    let url = `/followups/${guid}`;
    const magicToken = AuthenticationContext.getMagicToken();

    //figure out if we should have an auth header
    let authHeader = null;

    if (magicToken) {
      authHeader = `DSR-TOKEN ${magicToken}`;
    } else if (hasAccessToken()) {
      authHeader = `Bearer ${access_token}`
    }

    //append headers
    let headers;
    if (authHeader) {
      headers = { headers: { Authorization: authHeader } }
    }

    //append query params
    const groupId = getParameterByName('groupId');
    if (groupId) { url += `?groupId=${groupId}`; }

    //fetch
    let data = await get(url, headers);

    //sanitize data
    data.media = (data.media || []).filter((item) => item.removed !== 1);

    //add passcode to data if admin
    if (access_token !== null) {
      let passcode = await Api.getPasscode();
      data.ui.settings.passcode = passcode;
    }

    return data;
  }

  static async getNotificationPreferences(user, account) {
    user = btoa(user);
    account = btoa(account);
    const data = await get(`/notifications/settings?y=${user}&z=${account}&application=DSR`);
    return data;
  }

  static async saveNotificationPreferences(guid, user, account, settings) {
    const obj = {
      guid: guid,
      user: user,
      account_id: account,
      application: 'DSR',
      settings: settings
    };
    const data = await post('/notifications/settings', obj);
    return data;
  }

  static async removeNotificationRecipient(guid, recipientsEmail) {
    const base64_email = base64Encode(recipientsEmail);
    const data = await axios.delete(`${apiEndpoint}/followups/${guid}/notification_recipients/${base64_email}`, {
      headers: { Authorization: `Bearer ${access_token}` }
    });
    return data;
  }

  static async makeHeadRequest(url) {
    return axios.get(url).then((r) => {
      return {
        'ok': r.status === 200,
        'status': r.status,
        'content-type': r.headers['content-type'],
        'content-length': r.headers['content-length']
      };
    }).catch((ex) => {
      let status = ex?.response?.status || 0;
      return { ok: false, status: status, 'content-type': '', 'content-length': 0 };
    });
  }

  static async getPreviewUrl(media_id) {
    return await get('/followups/filestack?cachebust=1&media_id=' + media_id);
  }

  // Analytics
  static async sendMediaAction(media, type, userEmail) {
    let { id: media_id, isVpt, public_id, folder_id } = media || {};
    if (!guid || !media_id) {
      return 0;
    }
    let obj = {
      type: type
    };
    if (userEmail) obj.user = userEmail;
    if (isVpt) {
      obj.folder_id = folder_id;
      obj.public_id = public_id;
      obj.isVpt = isVpt;
    }
    const data = await post(`/followups/${guid}/media/${media_id}`, obj);
    return data;
  }

  static async sendCommentActions(payloads, guid) {
    const obj = {
      payloads: payloads,
      guid: guid
    };
    const data = await post('/followups/comment', obj);
    return data;
  }

  static async sendVisitAction(userEmail, guid) {
    const obj = {
      type: 'visit',
      guid: guid,
      user: userEmail
    };

    post('/followups/event', obj);
  }

  static async massDownload(assets) {
      let postObj = { assets: assets.map(x => x.url), guid: guid };
      const magicToken = AuthenticationContext.getMagicToken();
      let authHeader = null;

      if (magicToken) {
        authHeader = `DSR-TOKEN ${magicToken}`;
      } else if (hasAccessToken()) {
        authHeader = `Bearer ${access_token}`
      }

      //append headers
      let headers;
      if (authHeader) {
        headers = { headers: { Authorization: authHeader } }
      }

      const data = await axios.post(`${apiEndpoint}/followups/download/download.zip?export=0`, postObj, headers);
      return data.data;
  }

  static async getPspdfkitKey() {
    const data = await axios.get(`${apiEndpoint}/pdfkey`);
    return data.data && data.data.key ? data.data.key : null;
  }

  static async addItemsToFollowup(guid, user, mediaIds, vptIds) {
    const obj = {
      media_ids: mediaIds,
      vpts: vptIds,
      user: user
    };
    var data = await axios.put(`${apiEndpoint}/followups/${guid}/media`, obj, {
      headers: { Authorization: `Bearer ${access_token}` }
    });
    return data;
  }

  static async refreshMedia() {
    const data = await axios.get(`${apiEndpoint}/followups/${guid}/media`, {
      headers: { Authorization: `Bearer ${access_token}` }
    });

    //sanitize data
    let media = (data.data || []).filter((item) => item.removed !== 1);
    return media;
  }

  static async uploadExternalMedia(file, guid, user) {
    let formData = new FormData();
    formData.append('type', 'followup_media');
    formData.append('file', file);
    formData.append('guid', guid);
    formData.append('user', user);
    const data = await axios.post(`${apiEndpoint}/upload/create`, formData, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  }

  static async uploadLink(guid, user, name, url) {
    let formData = new FormData();
    formData.append('type', 'link');
    formData.append('guid', guid);
    formData.append('user', user);
    formData.append('name', name);
    formData.append('url', url);
    const data = await axios.post(`${apiEndpoint}/upload/create`, formData, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return data;
  }

  static async uploadFile(fileName, fileBlob, user) {
    const formData = new FormData();
    formData.append("type", "media_and_thumb");
    formData.append("user", user);
    formData.append("file", fileBlob, fileName);
    formData.append("original_file_name", fileName);

    return await axios.post(`${apiEndpoint}/upload`, formData, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => {
      return response.data;
    });
  }

  static async updateMemberStatus(user, status) {
    user = base64Encode(user);
    const obj = {
      status: status
    };
    const data = await axios.put(`${apiEndpoint}/followups/${guid}/users/${user}/status`, obj, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
    return data;
  }

  static async deleteMedia(media_ids) {
    const obj = {
      ids: media_ids,
      action: 'remove',
      value: 1
    };
    const data = await axios.put(`${apiEndpoint}/followups/${guid}/media/bulk-action`, obj, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
    return data;
  }

  static async addMedia(media_ids, user, vpts = []) {
    // {"media_ids":[309001,309010,309004],"vpts":[],"user":"snelson@gomodus.com"}
    const obj = {
      media_ids: media_ids,
      vpts: vpts,
      user: user
    };
    const data = await axios.put(`${apiEndpoint}/followups/${guid}/media/`, obj, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
    return data;
  }

  static async update(input) {
    const data = await axios.put(`${apiEndpoint}/followups/${guid}`, input, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
    return data;
  }

  static async getUserDetails() {
    const data = await axios.get(`${apiEndpoint}/users/me`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
    return data.data;
  }

  static async indexFollowupMedia(input) {
    return await axios.put(`${apiEndpoint}/followups/${guid}/media/index`,
      input,
      { headers: { Authorization: `Bearer ${access_token}` } }
    );
  }

  static async signForFilestack(lifetime) {
    const data =
      (await axios.get(`${apiEndpoint}/filestack/sign/${lifetime}`, {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      })) || {};
    return data;
  }

  static async validateWithSendgrid(email) {
    email = encodeURIComponent(email);
    let data = await axios.get(`${apiEndpoint}/sendgrid/validate?email=${email}`);
    data = data.data && data.data.result ? data.data.result : {};
    if (!data) {
      return false;
    } else {
      const verdict = data.verdict ? data.verdict : '';
      const score = data.score ? data.score : 1.0;
      if ((verdict == 'Risky' || verdict == 'Valid') && score > 0.1) {
        return true;
      } else {
        return false;
      }
    }
  }

  static async grantUserDSRAccess(email, passcode) {
    let body = { email, passcode };
    const data = await axios.post(`${apiEndpoint}/followups/${guid}/grant`, body).catch(ex => {
      return false
    });
    return data.data;
  }

  static async requestMagicLink(user) {
    const base64_user = base64Encode(user);
    const host = window.location.host;
    const isStage = host === 'followups-stage.gomodus.com';
    let endpoint = `${apiEndpoint}/followups/${guid}/users/${base64_user}/register`;
    if (isStage) endpoint += '?stage=1';
    let data = await axios.post(endpoint);
    return data.data;
  }

  static async validateLinkedIn(code, redirectURI, guid) {
    let inputs = { code: code, redirect_uri: redirectURI, guid: guid };
    const data = await axios.post(`${apiEndpoint}/followups/linkedin`, inputs);
    return data.data;
  }

  static async getSearchSuggestions(searchTerm) {
    const data = (await axios.get(`${apiEndpoint}/search/suggest?q=${searchTerm}`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })) || {};
    return data.data;
  }

  static async search(searchTerm, tag) {
    console.log('search');
    let searchEndpoint = `${apiEndpoint}/media/app-search-v2?q=${searchTerm}`;
    if (tag) {
      searchEndpoint = `${apiEndpoint}/media/app-search-v2?tag_name=${tag}`;
    }

    const data = (await axios.get(searchEndpoint, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })) || {};
    let mediaResults = data.data?.matches?.media || [];
    //strip media ids
    let mediaIds = MediaUtility.getMediaIds(mediaResults);
    return this.getMediaByIds(mediaIds);
  }

  static async getRecentlyViewedMedia() {
    const data = (await axios.get(`${apiEndpoint}/folders/smart-preview?metric=RECENTLY_VIEWED&group_id=-1`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })) || {};
    let mediaResults = data.data?.media || [];
    let mediaIds = MediaUtility.getMediaIds(mediaResults);
    return this.getMediaByIds(mediaIds);
  }

  static async getMediaByIds(mediaIds) {
    console.log('get media');
    const data = (await axios.get(`${apiEndpoint}/media/ids?media_ids=${mediaIds}`, {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    })) || {};

    //add media_type
    let results =  data.data.map((item) => {
      item.media_type = MediaUtility.getMediaType(item);
      return item;
    })

    return results;
  }
}
