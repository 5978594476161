import React, { Component } from 'react';
import './_activity-tab.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faEnvelope,
  faEye,
  faSortAmountDown,
  faSortAmountUp,
  faSlidersV,
  faFilePlus
} from '@fortawesome/pro-regular-svg-icons';
import { faDownload, faSearch, faLink } from '@fortawesome/free-solid-svg-icons';
import { truncate, localize } from 'utilities/helper-methods';

import { UserContext } from 'contexts/user-context';

import Switch from 'react-switch';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import Checkbox from '@material-ui/core/Checkbox';
import ReactTooltip from 'react-tooltip';

const defaultFilterState = [
  {
    label: 'Viewed Media',
    localizationKey: 'Viewed media filter text',
    action: 'media_view',
    value: false,
    index: 0
  },
  {
    label: 'Added Media',
    localizationKey: 'Added media filter text',
    action: 'media_added',
    value: false,
    index: 1
  },
  {
    label: 'Downloaded Media',
    localizationKey: 'Downloaded media filter text',
    action: 'download',
    value: false,
    index: 2
  },
  {
    label: 'Joined',
    localizationKey: 'Joined filter text',
    action: 'joined',
    value: false,
    index: 3
  },
  {
    label: 'Invited',
    localizationKey: 'Invited filter text',
    action: 'invited',
    value: false,
    index: 4
  }
];

export default class ActivityTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activitySortDesc: true,
      filterDropdown: false,
      filters: this.props.activeFilters ? this.props.activeFilters : defaultFilterState,
      hideMyActivity: this.props.hideMyActivity ? this.props.hideMyActivity : false,
      numFiltersActive: 0,
      activeFilters: false,
      pageActivity: this.props.pageActivity,
      searchTerm: ''
    };
  }

  setDropdownRef = (node) => {
    this.dropdownRef = node;
  };

  componentDidMount() {
    this.checkAllFilters();
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const element = document.getElementById('icons-container');
    if (
      this.dropdownRef &&
      !this.dropdownRef.contains(event.target) &&
      !element.contains(event.target)
    ) {
      this.setState({
        filterDropdown: false
      });
    }
  };

  handleSearchChange = (e) => {
    e.preventDefault();
    this.setState({
      searchTerm: e.target.value
    });
  };

  formatPageActivity = (activity) => {
    const { settings, language } = this.props;
    const labels = settings && settings.labels ? settings.labels : null;
    for (let i = activity.length - 1; i >= 0; i--) {
      // Format activity
      const item = activity[i];
      if (item.action === 'joined') {
        item.icon = faMapMarkerAlt;
        item.label = localize(labels, 'Joined filter text', language, 'Joined');
        item.description = `<u>(${item.user.toLowerCase()})</u> joined the conversation.`;
        item.plaintext = `${item.user.toLowerCase()} joined the conversation.`;
      } else if (item.action === 'invited') {
        item.icon = faEnvelope;
        item.label = localize(labels, 'Invited filter text', language, 'Invited');
        item.description = `<u>(${item.user.toLowerCase()})</u> was invited to the conversation.`;
        item.plaintext = `${item.user.toLowerCase()} was invited to the conversation.`;
      } else if (item.action === 'media_view') {
        item.icon = faEye;
        item.label = localize(labels, 'Viewed media filter text', language, 'Viewed Media');
        item.description = `<u>(${item.user.toLowerCase()})</u> viewed <i>${item.media_title}</i>`;
        item.plaintext = `${item.user.toLowerCase()} viewed ${item.media_title}`;
      } else if (item.action === 'media_added') {
        if (!item.link) {
          item.icon = faFilePlus;
          item.label = localize(labels, 'Added media filter text', language, 'Added Media');
          item.description = `<u>(${item.user.toLowerCase()})</u> added <i>${item.media_title ? item.media_title : `a media item`
            }</i>`;
          item.plaintext = `${item.user.toLowerCase()} added ${item.media_title ? item.media_title : `a media item`
            }`;
        } else {
          item.icon = faLink;
          item.label = 'Added Link';
          item.description = `<u>(${item.user.toLowerCase()})</u> added <i>${item.media_title ? item.media_title : `a link`
            }</i>`;
          item.plaintext = `${item.user.toLowerCase()} added ${item.media_title ? item.media_title : `a link`
            }`;
        }
      } else if (item.action === 'download') {
        item.icon = faDownload;
        item.label = localize(labels, 'Downloaded media filter text', language, 'Downloaded Media');
        item.description = `<u>(${item.user.toLowerCase()})</u> downloaded <i>${item.media_title ? item.media_title : `a media item`
          }</i>`;
        item.plaintext = `${item.user.toLowerCase()} downloaded ${item.media_title ? item.media_title : `a media item`
          }`;
      } else {
        activity.splice(i, 1);
      }
    }
    return activity;
  };

  filterAndSortPageActivity = (pageActivity) => {
    pageActivity = [...pageActivity];
    const { activitySortDesc, searchTerm, filters, hideMyActivity } = this.state;
    const user = this.context;
    const activeFilters = [];
    pageActivity = pageActivity ? this.formatPageActivity(pageActivity) : [];
    pageActivity = activitySortDesc
      ? pageActivity.sort(
        (a, b) =>
          new Date(b.timestamp.replace(/\s/, 'T')) - new Date(a.timestamp.replace(/\s/, 'T'))
      )
      : pageActivity.sort(
        (a, b) =>
          new Date(a.timestamp.replace(/\s/, 'T')) - new Date(b.timestamp.replace(/\s/, 'T'))
      );

    // Filter by search term if applicable
    pageActivity = searchTerm
      ? pageActivity.filter((activity) => {
        return (
          activity.user && activity.user.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        );
      })
      : pageActivity;
    // Filter by all activity except the current user if applicable
    pageActivity = hideMyActivity
      ? pageActivity.filter((activity) => {
        return activity.user && activity.user.toLowerCase().indexOf(user.toLowerCase()) === -1;
      })
      : pageActivity;
    // Gather all other active filter for action types
    filters.forEach((filter) => {
      if (filter.value) {
        activeFilters.push(filter.action.toLowerCase());
      }
    });
    // If there are active filters for action types, filter out the remaining activity
    if (activeFilters.length > 0) {
      pageActivity = pageActivity.filter(function (activity) {
        return activeFilters.includes(activity.action.toLowerCase());
      });
    }
    return pageActivity;
  };

  handleSortActivityClick = (e) => {
    e.preventDefault();
    this.setState({
      activitySortDesc: !this.state.activitySortDesc
    });
  };

  handleFilterActivityClick = (e) => {
    e.preventDefault();
    this.setState({
      filterDropdown: !this.state.filterDropdown
    });
  };

  handleFilterCheckboxClick = (e, index) => {
    const value = e.target.checked;
    let filters = [...this.state.filters];
    let filter = { ...filters[index] };
    filter.value = value;
    filters[index] = filter;
    this.setState({ filters }, () => {
      this.checkAllFilters();
    });
  };

  handleSwitchChange = (previousValue) => {
    this.setState(
      {
        hideMyActivity: !previousValue
      },
      () => {
        this.checkAllFilters();
      }
    );
  };

  handleClearFiltersClick = () => {
    this.setState({
      filters: defaultFilterState,
      activeFilters: false,
      hideMyActivity: false
    });
    this.props.preserveActivityFilters(defaultFilterState, false);
  };

  handleClearSingleFilterClick = (index) => {
    let filters = [...this.state.filters];
    let filter = filters[index];
    filter.value = false;
    filters[index] = filter;
    this.setState({ filters });
  };

  checkAllFilters = () => {
    let numFiltersActive = 0;
    const { filters, hideMyActivity } = this.state;
    filters.forEach((filter) => {
      if (filter.value) {
        numFiltersActive++;
      }
    });
    if (hideMyActivity) numFiltersActive++;
    const hasActiveFilters = numFiltersActive > 0;
    this.setState({
      numFiltersActive,
      activeFilters: hasActiveFilters
    });
    this.props.preserveActivityFilters(filters, hideMyActivity);
  };

  render() {
    let {
      activitySortDesc,
      pageActivity,
      searchTerm,
      filterDropdown,
      filters,
      activeFilters,
      numFiltersActive,
      hideMyActivity
    } = this.state;
    const { settings, language } = this.props;
    const accountTheme = this.props.accountTheme;
    const labels = settings && settings.labels ? settings.labels : null;

    //Theming
    const primaryColorBGStyle = { backgroundColor: accountTheme.primaryColor };

    //fallbacks
    pageActivity = pageActivity ? this.filterAndSortPageActivity(pageActivity) : [];

    return (
      <div className="activity-tab-container">
        <div className="activity-header">
          <div className="search-and-filter-container">
            <div className="search-container">
              <FontAwesomeIcon icon={faSearch} id="search-input-icon" size="1x" />
              <input
                id="email-input"
                value={searchTerm}
                onChange={(e) => this.handleSearchChange(e)}
                placeholder={localize(
                  labels,
                  'Activity search placeholder',
                  language,
                  'Search by email...'
                )}
              ></input>
            </div>
            <div className="search-and-filter-icons" id="icons-container">
              <div className="icon-container" id="filter-icon">
                {/*this should be a button*/}
                <FontAwesomeIcon
                  size="lg"
                  onClick={this.handleFilterActivityClick}
                  icon={faSlidersV}
                />
                {activeFilters && (
                  <div
                    className="active-filters-indicator animate__animated animate__flipInY"
                    style={primaryColorBGStyle}
                  >
                    {numFiltersActive}
                  </div>
                )}
              </div>
              {/* TODO: this should use the existing Dropdown Component */}
              {filterDropdown && (
                <div className="expand-container animate__animated animate__fadeIn">
                  <div className="filter-dropdown-container" ref={this.setDropdownRef}>
                    <div id="dropdown-arrow-up" />
                    <div className="filter-dropdown-content">
                      <div className="filter-dropdown-header">
                        {localize(labels, 'Activity type header', language, 'Activity Type')}
                      </div>
                      <div className="filter-toggles">
                        {filters.map((filter) => (
                          <div className="filter-row" key={filter.index}>
                            <Checkbox
                              style={{ color: accountTheme.primaryColor, transform: 'scale(0.9)' }}
                              size="small"
                              disableRipple={true}
                              onChange={(e) => this.handleFilterCheckboxClick(e, filter.index)}
                              checked={filter.value}
                            />
                            <div id="filter-label">
                              {localize(labels, filter.localizationKey, language, filter.label)}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="filter-dropdown-header">
                        {localize(
                          labels,
                          'Additional filters header',
                          language,
                          'Additional Filters'
                        )}
                      </div>
                      <div className="filter-toggles">
                        <div className="filter-row switch-row">
                          <div id="switch-label">
                            {localize(
                              labels,
                              'Hide my activity filter text',
                              language,
                              'Hide My Activity'
                            )}
                          </div>
                          <Switch
                            onChange={() => this.handleSwitchChange(hideMyActivity)}
                            checked={hideMyActivity}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor={accountTheme.primaryColor}
                            height={20}
                            width={40}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      style={{
                        backgroundColor: accountTheme.primaryColor,
                        color: accountTheme.secondaryColor
                      }}
                      className="btn btn-primary btn-clear"
                      onClick={() => this.handleClearFiltersClick()}
                    >
                      {localize(labels, 'Clear filter button', language, 'Clear Filters')}
                    </button>
                  </div>
                </div>
              )}
              <div className="icon-container">
                <FontAwesomeIcon
                  size="lg"
                  onClick={this.handleSortActivityClick}
                  icon={activitySortDesc ? faSortAmountDown : faSortAmountUp}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="activity-list-container"
        >
          {pageActivity.map((activity, index) => {
            const showTooltip = activity.description.length >= 130;
            return (
              <div className="activity-row" key={index}>
                <div className="activity-top-row">
                  <div className="activity-row-left">
                    <FontAwesomeIcon icon={activity.icon} />
                    <div id="action-type">{activity.label}</div>
                  </div>
                  <div className="activity-row-right">
                    <div id="action-timestamp">
                      {moment.utc(activity.timestamp).local().format('MM/DD/YY h:mm A')}
                    </div>
                  </div>
                </div>
                <div className="activity-details-row">
                  <div
                    data-for="description-tooltip"
                    data-tip={showTooltip ? activity.plaintext : null}
                  >
                    {ReactHtmlParser(truncate(activity.description, 130))}
                  </div>
                  {showTooltip && (
                    <ReactTooltip
                      id="description-tooltip"
                      effect="solid"
                      place="left"
                      multiline={true}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

ActivityTab.contextType = UserContext;
