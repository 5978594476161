import './_styles.scss';

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Api, AuthenticationContext } from 'services';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { LanguageUtility, EmailUtility } from 'utilities';

import PrivacyGateWelcomeLoader from "../welcome-loader";
import CenteredGateWrapper from '../centered-gate-wrapper';

export default function UserAccess(props) {
  const [showEnterBtn, setShowEnterBtn] = useState(false);
  const [email, setEmail] = useState('');
  const [passcode, setPasscode] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const localize = LanguageUtility.getLocalization;

  const validateEmail = (email) => {
    let validEmail = EmailUtility.validateEmailFormat(email);
    if (validEmail) {
      return true;
    } else {
      toast.error('Please enter a valid email');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passcode.length > 0 && validateEmail(email)) {
      getAccess();
    }
    if (validateEmail(email) && passcode.length === 0) {
      document.getElementById('passcode-input').focus();
    }
  }

  const handleEmailChange = async (e) => {
    setEmail(e.target.value);
  };

  const handlePasscodeChange = (e) => {
    setShowEnterBtn(passcode.length > 0 && email.length > 0);
    setPasscode(e.target.value);
  };

  const getAccess = async () => {
    if (!validateEmail(email)) {
      return
    }

    setIsLoading(true);

    AuthenticationContext.validatePasscode(email, passcode).then((valid) => {
      if (valid) {
        props.dismissPrivacyGate(email, passcode);
      } else {
        toast.error('Invalid email or passcode.');
        setIsLoading(false);
      }
    }).catch((ex) => {
      toast.error('Invalid email or passcode.');
      setIsLoading(false);
    });
  };

  //Render
  return (
    <div className='passcode-full-page'>
      {isLoading && <PrivacyGateWelcomeLoader {...props} name={email} />}

      {!isLoading &&
        <CenteredGateWrapper>
          <div className="passcode-view">
            <div className="information">
              <div className="title">
                <FontAwesomeIcon icon={faLock} className="lock-icon" />
                <span className="passcode-label">
                  {localize(props, 'Passcode protected Label', 'Passcode Protected')}
                </span>
              </div>
              <div className="message">
                {localize(
                  props,
                  'Passcode message',
                  'This is a private Digital Sales Room, you will need to enter your email and passcode or request access.'
                )}
              </div>
            </div>

            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="input-container ">
                <input
                  id="email-input"
                  className="email-input standard-size user-access-input"
                  placeholder={localize(props, 'Enter email placeholder')}
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="input-container ">
                <input
                  id="passcode-input"
                  className="email-input standard-size  user-access-input"
                  placeholder={localize(props, 'Enter passcode placeholder', 'Enter Passcode')}
                  type="password"
                  value={passcode}
                  onChange={handlePasscodeChange}
                />
              </div>
              <div className="action-btn">
                <button
                  className="btn btn-primary standard-size"
                  style={{ display: showEnterBtn ? 'block' : 'none' }}
                  type="submit">
                  {localize(props, 'Enter Button label', 'Enter')}
                </button>
              </div>
            </form>

            <div className="seperator standard-size">
              <div className="line-div"></div>
            </div>

            <div className="message">
              {localize(props, 'Passcode question', "Don't have a passcode?")}
              <button className="btn resend-link-btn" onClick={() => { props.requestAccess(true) }}>
                {localize(props, 'Request access label', 'Request Access Now')}
              </button>
            </div>
          </div>
        </CenteredGateWrapper>
      }
    </div>

  );
}
