import { Buffer } from 'buffer';

//Shallow Equality compare
export function isEqual(a, b) {
  // Create arrays of property names
  let aProps = Object.getOwnPropertyNames(a);
  let bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  // If we made it this far, objects
  // are considered equivalent
  return true;
}

//Encoding / Decoding
export function base64Encode(str) {
  // Create buffer object, specifying utf8 as encoding
  let bufferObj = str ? Buffer.from(str, 'utf8') : null;
  // Encode the Buffer as a base64 string
  return bufferObj ? bufferObj.toString('base64') : null;
}
export function base64Decode(str) {
  // Create a buffer from the string
  let bufferObj = str ? Buffer.from(str, 'base64') : null;
  // Encode the Buffer as a utf8 string
  return bufferObj ? bufferObj.toString('utf8') : null;
}

//Guid Helpers
//Put this in to the preferences page - doesn't need to be a helper
export function checkSettingsForGuid(settings) {
  let settingExists = false;
  const guid = this.getCurrentGuid();
  settings.forEach((setting) => {
    if (setting.key === guid) settingExists = true;
  });
  return settingExists;
}

export function getCurrentGuid() {
  const pieces = window.location.pathname.split('/');
  const guidRegex = new RegExp('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$');
  const isGuid = pieces[1] ? guidRegex.test(pieces[1]) : false;
  return isGuid ? pieces[1] : false;
}

//Other
export function getCacheBustKey() {
  if (!window.CACHE_BUST_KEY) {
    let stylesheets = document.querySelectorAll('link[rel=stylesheet]');
    let tag;

    for (let i = 0; i < stylesheets.length; i++) {
      let node = stylesheets[i];
      if (node.href.indexOf("/static/css/main.") > 0) {
        tag = node;
        break;
      }
    }

    if (tag) {
      let link = tag.href;
      let startIndex = link.indexOf("gomodus.com/") + 12;
      let endIndex = link.indexOf("/static");
      window.CACHE_BUST_KEY = link.substring(startIndex, endIndex);
    }
  }

  return window.CACHE_BUST_KEY;
}

/****************** URL Parameter Helpers ******************/

/**
 * Returns the value of a parameter if it exists, otherwise returns null.
 * @param {string} name of the parameter to return.
 * @return {string} value of the parameter.
 */
export function getParameterByName(name) {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}

export function removeURLParameter(parameter) {
  let url = new URL(window.location);
  let params = new URLSearchParams(url.search);
  if (params.has(parameter)) {
    params.delete(parameter);
    let path = window.location.href.split('?')[0];
    const numParams = Array.from(params).length || 0;
    const url = numParams > 0 ? `${path}?${params.toString()}` : path;
    window.history.replaceState({}, document.title, url);
  }
}


/**
 * Returns a boolean value of whether a url parameter exists
 * @param {string} name of the parameterm to look up
 * @return {boolean} if the parameter exists
 */
export function paramExists(name) {
  const param = getParameterByName(name);
  return Boolean(param);
}

/**
 * Returns an object with default settings for the microsite.
 * Enables comments by default, disables them for any ACP web bundle
 * that uses the bundle parameter.
 * @return {object} of settings.
 */
export function getSettingsFromParams() {
  const params = new URLSearchParams(window.location.search);
  let settings = {
    showComments: true,
    showInviteButton: true
  };
  params.forEach((value, key) => {
    switch (key) {
      case 'bundle':
        if (value === 'acp') {
          settings.showComments = false;
          settings.showInviteButton = false;
        }
        return;
      default:
        return;
    }
  });
  return settings;
}

/***** END URL PARAMETER HELPERS  ******/

//move to device utility?
export function isMobile() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}

export function hasAccessToken() {
  const token = getParameterByName('access_token');
  return token ? true : false;
}

export function getGenericErrorMessage() {
  return 'If this issue persists, please contact support@gomodus.com';
}

//TODO: remove this and just create a string object extension
export function truncate(str, maxLength) {
  return str.length >= maxLength ? str.substring(0, maxLength - 3) + '...' : str;
}

//Settings
export function mapNewSettingValues(existingObject, newObject) {
  let result = { ...existingObject };

  if (newObject) {
    Object.keys(newObject).map((key) => {
      let newValue = newObject[key];
      if (typeof existingObject[key] === 'boolean') {
        if (typeof newValue === 'string') newValue = parseInt(newValue);
        newValue = !!newValue;
      }
      result[key] = newValue;
    });
  }

  return result;
}

//TODO: remove
export function settingExists(settings, key) {
  return settings && settings[key] !== undefined;
}

//TODO: remove/replace all in favor of  languageUility.getLocalization
export function localize(labels, field, language, defaultValue, replace) {
  const langs = [
    'da',
    'de',
    'en',
    'es',
    'fr-fr',
    'it',
    'ja',
    'ko',
    'nl',
    'pl',
    'pt',
    'ru',
    'sv',
    'zh-hans'
  ];
  let label =
    labels && field && language && labels[field] && langs.includes(language)
      ? labels[field][language]
      : defaultValue;
  if (replace) label = label.replace(/\{.*?\}/g, replace);
  return label;
}

//TODO: put this directly in the contact-tab.js
export function getLanguageCodeForMoment(code) {
  // moment expects a different code for Chinese (Simplified)
  return code === 'zh-hans' ? 'zh_cn' : code;
}