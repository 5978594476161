import { AuthenticationContext } from 'services';

import SemiGatedImg from 'assets/privacy-setting-imgs/semi-gated.png';
import GatedImg from 'assets/privacy-setting-imgs/gated.png';
import PrivateImg from 'assets/privacy-setting-imgs/private.png';
import PrivatePasscodeImg from 'assets/privacy-setting-imgs/private-passcode.png';


export default class PrivacyUtility {
  //NOTES: SEMI-PRIVATE-CLASSIC is set ONLY in the backend when a DSR is created with no privacy level

  //Globals - Privacy Options
  static PRIVACY_OPTIONS = [
    {
      label: 'Public',
      key: "Public privacy label",
      value: 'PUBLIC',
      description: 'Viewable to any recipient.',
      img_path: null,
    },
    {
      label: 'Semi-Gated',
      key: "Semi-gated privacy label",
      value: 'SEMI-PRIVATE',
      description: 'Anyone can view. To download, comment, or share, recipients must enter a valid email address.',
      img_path: SemiGatedImg,
    },
    {
      label: 'Gated',
      key: "Gated privacy label",
      value: 'GATED',
      description: 'Anyone can view. Email gate upon entry to the Digital Sales Room.',
      img_path: GatedImg,
    },
    {
      label: 'Private',
      key: "Private privacy label",
      value: 'PRIVATE',
      description: 'Only invited viewers may view the content by validating their email address upon page loading. For extra security, add a passcode that recipients must enter along with an invited valid email address.',
      img_path: PrivateImg,
      onSave: (passcode) => { return passcode ? "PRIVATE-PASSCODE" : null; },
    },
    {
      label: 'Private Passcode',
      value: 'PRIVATE-PASSCODE',
      description: '2Only invited viewers may view the content by validating their email address upon page loading. For extra security, add a passcode that recipients must enter along with an invited valid email address.',
      img_path: PrivatePasscodeImg,
      hidden: true,
      _uiValue: 'PRIVATE',
    },
    //{ label: 'Semi-Private Classic', value: "SEMI-PRIVATE-CLASSIC", hidden: true }
  ];

  static DEFAULT_PRIVACY_SETTING = this.PRIVACY_OPTIONS[0];

  //Privacy Options
  static getPrivacyOptions = (props) => {
    let { hideDSRPrivacyOptions } = props?.accountSettings;
    hideDSRPrivacyOptions = hideDSRPrivacyOptions ? JSON.parse(hideDSRPrivacyOptions) : {};
    const intialPrivacySetting = props?.privacy_setting.toLowerCase();

    //if current privacy setting is hidden show nothing
    if (hideDSRPrivacyOptions[intialPrivacySetting] === 1) {
      //return [];
    }

    const result = this.PRIVACY_OPTIONS.filter((option) => {
      return hideDSRPrivacyOptions[option.value.toLowerCase()] !== 1;
    });

    return result;
  }

  static getPrivacyOptionByValue = (value) => {
    let result = this.PRIVACY_OPTIONS.find(x => { return x.value === value; });
    return result;
  }

  //Privacy Gates
  /**
   * returns information dicating whether or not a privacy gate (2.0 or legacy) should show
   * @param {Object} appState state object form App.js
   * @param {bool} actionRequiresUser flag to force a privacy gate even if the DSR is public
   * @return {Object} object with information on whether the privacy gate or "legacy-email-input" modal should show
   */
  static async getPrivacyGateProperties(appState, actionRequiresUser = false) {
    const result = { isGateEnabled: false, isLegacyGateEnabled: false }
    const { accountSettings, granted_magic_link_access, } = appState;
    let { privacy_setting } = appState;
    const isUserSignedIn = appState.user?.email;

    //Owner short circuit
    if (appState.isOwner) {
      return result;
    }

    //HACK
    if (accountSettings.disableDSREmailValidation && privacy_setting === "SEMI-PRIVATE") {
      accountSettings.enableDSR_2_0 = false;
      privacy_setting = "SEMI-PRIVATE-CLASSIC"
    }

    //Non nonexistent user short circuit;
    //TOOD: if nonexistent user OR invalid user
    if (!isUserSignedIn && actionRequiresUser) {
      result.isLegacyGateEnabled = !accountSettings.enableDSR_2_0;
      result.isGateEnabled = accountSettings.enableDSR_2_0;
      return result;
    }

    //Privacy Based Gating
    switch (privacy_setting) {
      case "GATED":
      case "PRIVATE":
        result.isGateEnabled = !granted_magic_link_access;
        break;
      case "PRIVATE-PASSCODE":
        const isValidPassocde = await AuthenticationContext.validatePasscode();
        result.isGateEnabled = !isValidPassocde
        break;
      case "SEMI-PRIVATE":
        result.isGateEnabled = !appState.isLoading && (!granted_magic_link_access);
        break;
      case "SEMI-PRIVATE-CLASSIC":
        result.isLegacyGateEnabled = !isUserSignedIn;
        break;
      case "PUBLIC":
        break;
    }

    return result;
  }

  //Helpers
  static getPreviewData(privacySettingValue, enablePasscode) {
    if (enablePasscode) privacySettingValue = 'PRIVATE-PASSCODE';
    const option = this.PRIVACY_OPTIONS.find(x => x.value === privacySettingValue);
    return option;
  }
}
