import DEFAULT_LOCALIZATIONS from "assets/configuration/localizations.json";

const SUPPORTED_LANGUAGES = [
  'da',
  'de',
  'en',
  'es',
  'fr-fr',
  'it',
  'ja',
  'ko',
  'nl',
  'pl',
  'pt',
  'ru',
  'sv',
  'zh-hans'
];

const getSetting = (props, key, fallback = null) => {
  let result = fallback;

  if (props && props.settings && props.settings[key]) {
    result = props.settings[key];
  }

  return result;
};

export default class LanguageUtility {
  static getLocalization(props, localizationKey, fallback = '') {
    const language = props.language || 'en';
    const labels = getSetting(props, 'labels', []);
    const apiLanguages = getSetting(props, 'languages', null);
    const supportedLanguages = apiLanguages ? Object.keys(apiLanguages) : SUPPORTED_LANGUAGES;
    let result = null;

    //api
    if (supportedLanguages.includes(language.toLowerCase()) && labels[localizationKey]) {
      result = labels[localizationKey][language] || result;
    }

    //api english
    if (!result && labels[localizationKey]) {
      result = labels[localizationKey]['en'];
    }

    //defaults
    if (!result) {
      result = DEFAULT_LOCALIZATIONS[localizationKey];
    }

    //fallback
    if (!result) {
      result = fallback;
    }

    return result;
  }

  //Language Getters
  static getLanguages(props) {
    let languages = getSetting(props, 'languages', []);
    languages = Object.values(languages);
    return languages;
  }

  //Helpers
  static parseLanguageCode(languageCode) {
    return languageCode && languageCode.length > 2 ? languageCode.substring(0, 2) : languageCode;
  }

  static getInitialLanguage(props) {
    let language = 'en';
    let locales = getSetting(props, 'browserLocales', null);

    //detemine the langauge based on prioritized local
    if (locales) {
      let prioritizedLocale = null;
      locales = Object.entries(locales);
      locales.forEach((locale) => {
        const quality = locale.q ? parseInt(locale.q) : 0;
        if (!prioritizedLocale || quality > prioritizedLocale.q) {
          prioritizedLocale = locale;
        }
      });
      language = prioritizedLocale[0];
      language = language.toLowerCase();
    }

    //hack for us language
    if (language.indexOf('-US') > -1) {
      language = 'en';
    }

    return language;
  }
}
