/* for I.E. < 11 */
/*import 'react-app-polyfill/ie9';*/
/*
import 'core-js';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/object';
import 'core-js/es/promise';
import 'core-js/es/promise';
/* */
import './styles/index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

//Temporary Polyfill
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (search, replace) {
    if (replace === undefined) {
      return this.toString();
    }
    return this.split(search).join(replace);
  }
}

ReactDOM.render(
  <BrowserRouter forceRefresh={false}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
