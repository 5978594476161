import React, { useState, useEffect } from 'react';
import api from 'services/api';
import TroubleDownloadingModal from 'components/trouble-downloading-modal';

export default function FilestackViewer(props) {
  let { thumbnail, media_id } = props;
  const [requestFails, setRequestFails] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [src, setSrc] = useState(props.src);
  const onError = function () {
    console.log('Error');
  };

  //mount
  useEffect(() => {
    //figure out source if not passed in
    if (!src) {
      api.getPreviewUrl(media_id).then(res => {
        console.log(res);
        if (res.fileUrl) {
          setSrc(res.fileUrl);
        }
      }).catch(function (err) {
        console.log(err);
        setRequestFails(true);
      })
    }
  }, []);

  const handleButtonClick = function (e) {
    setIsModalOpen(true);
  };

  const handleCloseModal = function (e) {
    setIsModalOpen(false);
  };

  return (
    (!requestFails && (
      <div className="filestack-viewer">
        <iframe title="filestack-iframe" src={src} onError={onError} />
      </div>
    )) ||
    (requestFails && (
      <div className="blocked-content-view">
        <div className="blocked-content-body">
          <p id="error-message">
            <img id="error-image-thumbnail" src={thumbnail} alt="thumbnail"></img>
            <p>Unable to access content. Our server might be blocked by your network. </p>
          </p>
          <TroubleDownloadingModal
            media={props.media}
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
          />
          <button className="btn btn-primary btn-md" type="submit" onClick={handleButtonClick}>
            Send via Email
          </button>
        </div>
      </div>
    ))
  );
}
