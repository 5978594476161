import { useState, useRef } from 'react';

export function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  const [isValid, setIsValid] = useState(true);
  const ref = useRef();

  const handleChange = function (e) {
    setValue(e.target.value);
    setIsValid(true);
  };

  const clearValue = function () {
    setValue('');
  };

  const validate = function () {
    const isValid = ref.current.checkValidity();
    const message = ref.current.validationMessage;
    setIsValid(isValid);
    return { isValid, message };
  };

  return [
    {
      value,
      isValid,
      clear: clearValue,
      validate,
      bind: {
        value,
        onChange: handleChange
      }
    },
    ref
  ];
}
