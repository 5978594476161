import React from 'react';
import Modal from 'components/modal';
import './index.scss';

export default function AppNotesModal(props) {
  if (!props.notes) {
    return null;
  }
  let notes = props.notes;
  return (
    <div>
      <Modal {...props} title="About">
        <div className="app-notes-modal">{notes}</div>
      </Modal>
    </div>
  );
}
