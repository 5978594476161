import './_styles.scss';
import React, { Component } from 'react';
import Dropdown from 'components/dropdown';
import Switch from 'react-switch';
import Api from 'services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from 'contexts/user-context';
import Spinner from '../spinner';
import { toast, ToastContainer } from 'react-toastify';
import {
  faCheck,
  faChevronUp,
  faChevronDown,
  faMoon
} from '@fortawesome/free-solid-svg-icons';

import { LanguageUtility, UsersUtility } from 'utilities';
import { AuthenticationContext } from 'services';
import {
  localize,
} from 'utilities/helper-methods';
import { useEditorStore } from 'stores/editorStore';

//helper components
class SettingsButton extends Component {
  render() {
    if (this.props.visible === false) return null;

    let icon = this.props.icon ? `icon ${this.props.icon}` : null;

    return (
      <button className="settings-btn basic-settings-container" onClick={this.props.onClick}>
        {(this.props.icon && !this.props.isLoading) && <i className={icon}></i>}
        {this.props.isLoading &&
          <div className="loader-container">
            <Spinner isActive={this.props.isLoading} />
          </div>
        }
        <span className="label">{this.props.label}</span>
      </button>
    );
  }
}

class SettingsSwitch extends Component {
  render() {
    if (this.props.visible === false) return null;

    return (
      <div className="basic-settings-container">
        <FontAwesomeIcon icon={this.props.icon} size="1x" className="icon" />
        <div className="label"> {this.props.label} </div>
        <div className="switch-container">
          <Switch
            onChange={this.props.onChange}
            checked={this.props.isChecked}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#4182FF"
            height={20}
            width={40}
          />
        </div>
      </div>
    );
  }
}

class DownloadAllButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDownloading: false
    }
  }

  urlHasExtension = (urlString) => {
    const urlRegex = /\.([A-Za-z0-9]+)$/;
    return urlRegex.test(urlString);
  };

  handleDownloadClick = async (e) => {
    let isValid = await this.props.validateOrGateUser(true);
    if (!isValid) {
      return;
    }

    const downloadableAssets = this.props.medias.filter(x => {
      let valid = this.urlHasExtension(x.url);
      return valid && x.external_access_options.download && x.url && !x.to
    });

    //TODO: add privacy validation
    if (downloadableAssets.length <= 0) return;

    if (downloadableAssets.length === 1) {
      window.open(downloadableAssets[0].url, "_blank");
      return;
    }

    this.setState({ isDownloading: true });

    try {
      let result = await Api.massDownload(downloadableAssets);
      if (result && result.url) { window.open(result.url); }
    } catch (e) {
      toast.error(`Failed to download media`);
    }

    this.setState({ isDownloading: false });
  }


  render() {
    const visible = this.props.visible;
    const downloadAssetsLabel = LanguageUtility.getLocalization(this.props, 'Download Assets label', 'Download');

    return <SettingsButton
      label={downloadAssetsLabel}
      icon="fa fa-download"
      isLoading={this.state.isDownloading}
      visible={visible}
      onClick={this.handleDownloadClick}
    />
  }
}

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLanguageList: false,
      isLoading: false
    }
  }
  handleClickOutside = () => {
    this.props.handleClickOutside();
    this.setState({ isOpen: false });
  };

  toggleLanguageList = () => {
    this.setState({ showLanguageList: !this.state.showLanguageList });
  };

  handleLanguageSelect = (e, language) => {
    e.preventDefault();
    this.props.onStatePropertyChanged('language', language.code);
    this.setState({ showLanguageList: false });
  };

  handleShareSettingsClick = (e) => {
    this.setState({ isOpen: false });
    this.props.onShareSettingsClick();
    this.handleClickOutside();
  };

  //Render
  render() {
    const { showLanguageList } = this.state;
    const { isOpen, isEditable, accountSettings, language } = this.props;
    const currentLanguage = this.props.language;
    const languages = LanguageUtility.getLanguages(this.props);
    const labels = this.props.settings ? this.props.settings.labels : null;
    const user = this.context;
    const showUserDependentBtn = user?.email !== null;
    const { darkMode } = useEditorStore.getState();

    //labels
    const shareSettingsLabel = LanguageUtility.getLocalization(this.props, 'Share Settings label', 'Share Settings');
    let darkModeLabel = 'Dark Mode';
    let languageLabel = 'Language';

    //render
    return (
      <Dropdown
        parent={this.props.parent}
        className='settings-dropdown'
        isOpen={isOpen}
        handleClickOutside={this.handleClickOutside}>

        <DownloadAllButton medias={this.props.medias} validateOrGateUser={this.props.validateOrGateUser} visible={showUserDependentBtn} />

        <SettingsButton
          label={shareSettingsLabel}
          icon="fa fa-cog"
          visible={isEditable && accountSettings.enableDSR_2_0}
          onClick={this.handleShareSettingsClick} />

        <SettingsSwitch
          label={darkModeLabel}
          icon={faMoon}
          isChecked={darkMode}
          onChange={() => { useEditorStore.getState().onDarkModeToggle() }}
        />

        <div className="language-container" onClick={this.toggleLanguageList}>
          <div className="language-menu-top-row">
            <div className="selected-language-icon">
              {LanguageUtility.parseLanguageCode(currentLanguage)}
            </div>
            <div className="label">{languageLabel}</div>
            <FontAwesomeIcon icon={showLanguageList ? faChevronUp : faChevronDown} size="1x" />
          </div>
          {showLanguageList && (
            <div className="language-list">
              {languages.map((language, index) => (
                <div
                  className="language-row"
                  key={index}
                  onClick={(e) => this.handleLanguageSelect(e, language)}
                >
                  {language.code === currentLanguage && (
                    <FontAwesomeIcon icon={faCheck} size="1x" />
                  )}
                  {language.name}
                </div>
              ))}
            </div>
          )}
        </div>


        <SettingsButton
          label={localize(labels, 'Log Out Label', language, 'Log Out')}
          icon=""
          visible={showUserDependentBtn}
          onClick={() => AuthenticationContext.logout()}
        />

        <div className="version">{process.env.REACT_APP_VERSION}</div>
      </Dropdown>
    );
  }
}

Settings.contextType = UserContext;
export default Settings;
