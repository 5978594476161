import React, { useState } from 'react';
import Modal from 'components/modal';
import Api from 'services/api';
import { useFormInput } from 'utilities/hooks';
import { toast } from 'react-toastify';
import './index.scss';
import {
  localize
} from 'utilities/helper-methods';

export default function TroubleDownloadingModal(props) {
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [email, emailRef] = useFormInput('');

  const onEmailChange = async function(e) {
    e.preventDefault();
    let emailValidation = email.validate();
    setIsEmailValid(emailValidation.isValid);
  }

  const submitForm = async function(e) {
    e.preventDefault();
    const pieces = window.location.pathname.split('/');
    const guid = pieces[1];
    const obj = {
      email: email.value,
      guid: guid,
      media_id: props.media.id,
      media_name: props.media.name,
      media_size: props.media.size,
      media_url: props.media.url
    };
    try {
      const result = await Api.sendMediaEmail(obj);
      if (result.status_code === 200) {
        toast.success('Email sent successfully');
        props.onRequestClose();
      } else {
        toast.error('Email failed to send. Please try again later.');
      }
    } catch (err) {
      toast.error(
        'An unexpected error occured while trying to send the email, please try again later.'
      );
    }
  };

  const handleKeyPress = function (e) {
    if (e.keyCode === 13 && e.shiftKey === false && input) {
      e.preventDefault();
      submitForm(e);
    }
  };

  const { language, accountTheme } = props;
  let backgroundColorStyling = { backgroundColor: `${accountTheme.primaryColor}` };
  const labels = props?.settings?.labels ? props.settings.labels : null;

  return (
    <Modal id="trouble-downloading-header" isOpen={true} onRequestClose={props.onRequestClose} title="Having trouble downloading this file?">
      <div className="trouble-downloading-modal">
        <div className="prompts">
          <p>We are sorry that you are having difficulty downloading this file.</p>
          <p>Your network or browser could be blocking the sites that contain the file.</p>
          <p>
            To send yourself an attachment, please add your email address to the send field below.
          </p>
        </div>

        <div className="modal-input-line">
          <form className="email-input-form" onSubmit={submitForm} noValidate onChange={(e) => onEmailChange(e)}>
            <input
              ref={emailRef}
              placeholder={localize(labels, 'Enter email placeholder', language, 'Enter Email')}
              type="email"
              required
              maximum-scale="1"
              onKeyDown={handleKeyPress}
              className="email-input"
              {...email.bind}
            />
            <button
              className={isEmailValid ? 'send-btn' : 'send-btn disabled'}
              style={backgroundColorStyling}
              type="submit"
              onClick={submitForm}
            >
              Send
            </button>
          </form>
        </div>

      </div>
    </Modal>
  );
}
