import './_styles.scss';
import React, { useState } from 'react';

export default function HamburgerButton(props) {
  const [isActive, setIsActive] = useState(false);

  let hamburgerMenuClasses = 'hamburger-menu ' + (isActive ? 'active' : '');

  const onHamburgerButtonClick = function () {
    const newValue = !isActive;
    setIsActive(newValue);

    if (props.onClick) {
      props.onClick(newValue);
    }
  };

  return (
    <div className={hamburgerMenuClasses} onClick={onHamburgerButtonClick}>
      <div className="hamburger-box">
        <div className="hamburger-inner"></div>
      </div>
    </div>
  );
}
