import React, { useState, useContext } from 'react';
import Api from "services/api";
import Spinner from "components/spinner";
import { UserContext } from 'contexts/user-context';
import { toast } from 'react-toastify';
import './index.scss';

export default function VideoThumbnailGenerator(props) {

  const user = useContext(UserContext);
  const [activeStage, setActiveStage] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const onVideoLoaded = (args) => {
    const input_video = document.getElementById('input_video');
    const placeholder = document.getElementById('output_placeholder');

    placeholder.style.width = input_video.offsetWidth + "px";
    placeholder.style.height = input_video.offsetHeight + "px";
  }

  const completeStageOne = () => {
    setActiveStage(1);
    const canvas = document.getElementById("canvas");
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
  }

  const getStageClass = (stage) => {
    if (stage === activeStage) return "active";
    if (stage < activeStage) return "complete";
    return null;
  }

  const takeSnapshot = () => {
    setActiveStage(2);

    var canvas = document.getElementById("canvas");
    var video = document.getElementById("input_video");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  }

  const save = () => {
    setIsSaving(true);

    const canvas = document.getElementById("canvas");
    canvas.toBlob(blob => {
      const filename = `header-thumb-${props.guid.replace("-", "")}.jpg`;
      Api.uploadFile(filename, blob, user).then(data => {
        data.temp = data.url;
        props.onThumbnailGenerated(data);
      }).catch(e => {
        console.error(e);
        toast.error("There was a problem saving your thumbnail, please try again later");
        setIsSaving(false);
      });
    }, "image/jpeg");
  }

  return (
    <div className="video-thumbnail-generator">

      <div className={`progress-container`}>

        <div className='space'></div>

        <div className={`text ${getStageClass(0)}`}>
          Pick a video frame
        </div>

        <div className={`momentum ${getStageClass(0)}`}>
          <div className="inactive-line">
          </div>
          <div className='arrow'>
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 100" preserveAspectRatio="xMaxYMid slice">
              <path className="draw-arrow" d="M12,50 H85" />
              <path className="draw-arrow tail-1" d="M70,35 q17,13 17,15" />
              <path className="draw-arrow tail-2" d="M70,65 q17,-13 17,-15" />
            </svg>
          </div>
        </div>


        <div className={`text ${getStageClass(1)}`}>
          Take a snapshot
        </div>

        <div className={`momentum ${getStageClass(1)}`}>
          <div className="inactive-line">
          </div>
          <div className='arrow'>
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 100 100" preserveAspectRatio="xMaxYMid slice">
              <path className="draw-arrow" d="M12,50 H85" />
              <path className="draw-arrow tail-1" d="M70,35 q17,13 17,15" />
              <path className="draw-arrow tail-2" d="M70,65 q17,-13 17,-15" />
            </svg>
          </div>
        </div>

        <div className={`text ${getStageClass(2)}`}>
          Review and save
        </div>

        <div className='space'></div>

      </div>

      <div className='main'>
        <div className='video-container'>
          <video id="input_video" src={props.videoSrc}
            crossOrigin='anonymous'
            controls
            onCanPlay={onVideoLoaded}
            onSeeked={completeStageOne}
            onPause={completeStageOne} />
        </div>

        <div className='photo-button-wrapper'>
          <button className='btn btn-round fa fa-camera' onClick={takeSnapshot}>
          </button>
        </div>

        <div className='output-container'>
          <div id='output_placeholder'>
            <i className='fa fa-image'></i>
          </div>
          <canvas id="canvas"></canvas>
        </div>
      </div>

      <div className='actions'>
        <button className='btn cancel' onClick={props.onCancel}>Cancel</button>
        <div className='space'></div>
        <button className='btn done' disabled={activeStage < 2} onClick={save}>Save</button>
      </div>

      {isSaving &&
        <div className='overlay' >
          <Spinner isActive={true} />
        </div>
      }
    </div >
  );
}
