import './_style.scss';
import React from 'react';
import Spinner from 'components/spinner';
import ModusLogo from 'assets/modus-logo.jpg';
import { LanguageUtility } from 'utilities';

export default function PrivacyGateWelcomeLoader(props) {

  const name = props.name || "";
  const message = props.message || `${LanguageUtility.getLocalization(props, 'Welcome message', 'Welcome')} ${name}!`;

  return (
    <div className="privacy-gate-welcome-loader">
      <img className="modus-logo" src={ModusLogo} />
      <Spinner isActive={true} />
      <div className="welcome-message">
        {message}
      </div>
    </div>
  )
}