import './index.scss';

import React, { useState, useContext } from 'react';
import { SalesContactContext } from 'contexts/sales-contact-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModusLogo from '../../assets/modus-logo.jpg';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { LanguageUtility } from 'utilities';
import { useEditorStore } from 'stores/editorStore';

export default function Footer(props) {
  const [isOpen, setIsOpen] = useState(true);
  const { darkMode } = useEditorStore();

  const context = useContext(SalesContactContext);
  const salesContact = context ? context : {};
  const url = encodeURI(
    'https://www.gomodus.com/follow-up-referral?clientName=' + salesContact.company
  );

  //kind of a hack but works well enough
  const fullWidthPages = ['/preferences', '/archived', '/error', '/403', '/grant'];
  const pageMatch = fullWidthPages.find((x) => {
    return window.location.pathname.indexOf(x) > -1;
  });
  const isFullWidth = !!pageMatch || props.isFullWidth;

  //functions
  const closeFooter = () => {
    setIsOpen(false);
  };

  //labels
  let poweredByLabel = LanguageUtility.getLocalization(props, 'Powered by text');
  let learnMoreLabel = LanguageUtility.getLocalization(props, 'Learn more button');

  //logo
  let logo = darkMode
    ? 'general/modus-logo-white%403x.png'
    : 'branding/modus-logo-no_tagline-30px.png';
  let fullLogo = `https://s3.amazonaws.com/resources.appdataroom.com/${logo}`;

  //footer classes
  let footerClasses = 'footer animated fadeInUp';
  if (props.isActionTrayOpen) footerClasses += ' closed'; //soft close
  if (isFullWidth) footerClasses += ' full-width';

  //Hide Footer result;
  if (!isOpen || props.settings.hideFooter) return null;

  if (props.privacy) {
    fullLogo = ModusLogo;
    footerClasses += ' privacy dark';
  }

  //Actual Footer result
  return (
    <div className={footerClasses}>
      <span>{poweredByLabel}</span>
      <img alt="modus logo" src={fullLogo} />
      <a className="btn-learn-more" href={url} target="_blank" rel="noopener noreferrer">
        {learnMoreLabel}
      </a>
      <button className="btn btn-no-style btn-close" onClick={closeFooter}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
}
