import './index.scss';

import React, { useState, useEffect } from 'react';
import { LanguageUtility, EmailUtility } from 'utilities';
import Modus from 'modus-javascript-bridge/dist/modus-js-bridge.js';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

export default function ShareTemplateSelector(props) {
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const [warning, setWarning] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [templates, setTemplates] = useState(props.templates);

  //non state variables
  const localize = LanguageUtility.getLocalization;
  const hasMultipleTemplates = templates.length > 1 ? true : false;

  //lifecycle - mount
  useEffect(() => {
    const { templates } = props;

    if (templates.length === 1) {
      let formattedTemplate = formatSelectedPreview(templates[0], templates);

      Modus.shareMailTo(formattedTemplate.mailto)
        .then(() => {
          toast.success('Mailing application opened.');
          //close containing modal if attached to app
          Modus.closeContainer();
        })
        .catch(() => {
          toast.error('Sharing Failed. ${e}');
        });

      props.onRequestClose();
      return;
    }

    if (templates && templates.length > 0) {
      setTemplates(templates);
      setSelectedTemplate(formatSelectedPreview(templates[0], templates));
    }
  }, []);

  //helpers
  const setWarningMessage = function (warning) {
    if (warning) {
      setIsWarningVisible(true);
      setWarning(warning);
    }
  };

  const formatSelectedPreview = function (selectedTemplate, templates) {
    if (!selectedTemplate || !selectedTemplate.mailto) return null;

    const data = selectedTemplate.mailto;
    const startKey = '&body=';
    const endKey = '&subject=';
    const startIndex = data.indexOf(startKey) + startKey.length;
    const endIndex = data.indexOf(endKey);
    let body = data.substring(startIndex, endIndex);
    body = body.replaceAll('%0D%0A', '<br />');
    body = body.replaceAll('%3F', '_');
    body = body.replaceAll(/%(?![0-9][0-9a-fA-F]+)/g, '%25');
    body = decodeURIComponent(body);

    if (props.privacy_setting === 'PRIVATE-PASSCODE' && props.settings.passcode !== '') {
      let passCode = props.settings.passcode;
      let passCodeTitle = localize(props, 'DSR passcode label', 'Digital Sales Room passcode:');
      let passcodeElement = `<div style='margin:20px 0px 20px 0px;background-color:#1b3c70;border-radius:5px;color:white;text-align:left;padding: 25px 20px 25px 20px;max-width:450px'><div style='padding-bottom:10px'>Digital sales Room Password:</div><div style='font-weight:bold;'>${passCode}</div></div>`;
      body = body.concat(passcodeElement);

      selectedTemplate.mailto = selectedTemplate.mailto.replace(
        '&subject',
        `%0D%0A${passCodeTitle}%0D%0A${passCode}&subject`
      );
    }

    let itemsToRemove = EmailUtility.validateMailTo(
      props.medias,
      selectedTemplate,
      templates,
      props.accountSettings
    );

    let warning = null;
    if (itemsToRemove.length > 0) {
      warning = localize(
        props,
        'Follow Up Outlook Warning',
        '<strong>Warning:</strong> Microsoft Outlook limits the amount of characters we can add to an email. Due to this restriction we recommend removing <strong>{LIMIT}</strong> item(s) from your follow up. If you choose not to remove any items, your email <i>may</i> be truncated by Outlook.'
      );
      warning = warning.replace('{LIMIT}', itemsToRemove);
      setIsWarningVisible(true);
      setWarningMessage(warning);
    }

    // return body;
    selectedTemplate.isActive = true;
    selectedTemplate.body = body;
    return selectedTemplate;
  };

  //ui
  const sendDSR = function () {
    Modus.shareMailTo(selectedTemplate.mailto)
      .then(() => {
        props.onRequestClose();
        toast.success(`Mailing application opened.`);
        //close containing modal if attached to app
        Modus.closeContainer();
      })
      .catch((e) => {
        toast.error(`Sharing Failed. ${e}`);
      });
  };

  const changeSelectedTemplate = function (template) {
    setSelectedTemplate((selectedTemplate.isActive = false));
    setSelectedTemplate(formatSelectedPreview(template, templates));
  };

  //render
  return (
    <div className="share-template-selector">
      <div className="email-container">
        {isWarningVisible && (
          <div className="warning-label" dangerouslySetInnerHTML={{ __html: warning }}></div>
        )}
        <h3 className="message-preview">Message Preview</h3>
        <div className="email-preview-container">
          {/* I had to put a little hack here during restyling because i could get many templates to show*/}
          {hasMultipleTemplates && (
            <ul className="email-templates">
              {templates.map((template, i) => (
                <li
                  key={i}
                  onClick={() => changeSelectedTemplate(template)}
                  className={template.isActive ? 'template-tab active' : 'template-tab'}>
                  {template.title}
                </li>
              ))}
            </ul>
          )}

          <div className={hasMultipleTemplates ? 'email-preview multiple' : 'email-preview'}>
            <div
              className="subject"
              dangerouslySetInnerHTML={{ __html: selectedTemplate.subject }}></div>
            <div
              className="body"
              dangerouslySetInnerHTML={{
                __html: selectedTemplate.body
              }}></div>
          </div>
        </div>
        <div className="email-actions">
          <button
            className="btn btn-cancel"
            style={{ backgroundColor: '#515151' }}
            onClick={() => props.setShareOption('default')}>
            {' '}
            Cancel{' '}
          </button>
          <button className="btn btn-share btn-primary" onClick={sendDSR}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
}

ShareTemplateSelector.propTypes = {
  privacy_setting: PropTypes.string,
  templates: PropTypes.array,
  medias: PropTypes.array,
  settings: PropTypes.object,
  accountSettings: PropTypes.object,
  onRequestClose: PropTypes.func,
  setShareOption: PropTypes.func
};
