import './_styles.scss';
import React, { Component } from 'react';
import Api from 'services/api';
import { getFilenameExtension } from 'utilities/file-utility';
import { toast } from 'react-toastify';
import { PickerOverlay } from 'filestack-react';

export default class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPickerOpen: false,
      policy: null,
      signature: null
    };

    this.registerFs = this.registerFs.bind(this);
    this.getFilestackLanguage - this.getFilestackLanguage.bind(this);
    this.onPickerClose = this.onPickerClose.bind(this);
    this.onUploadSuccess = this.onUploadSuccess.bind(this);
    this.onUploadFailed = this.onUploadFailed.bind(this);

    //run
    this.registerFs();
  }

  registerFs() {
    Api.signForFilestack(2400).then((res) => {
      const filestackPolicy = res.data.policy || null;
      const filestackSignature = res.data.signature || null;
      this.setState({
        isPickerOpen: filestackPolicy && filestackSignature,
        policy: filestackPolicy,
        signature: filestackSignature
      });
    });
  }

  getFilestackLanguage() {
    const language = this.props.language;

    let validCodes = ['en', 'es', 'it', 'pt', 'de', 'ru', 'ja', 'ko', 'nl', 'da', 'pl', 'sv'];
    if (validCodes.indexOf(language) > -1) {
      return language;
    }

    let mappedCodes = { 'zh-hans': 'zh', 'fr-fr': 'fr' };
    if (mappedCodes[language]) {
      return mappedCodes[language];
    }

    //default
    return 'en';
  }

  onPickerClose = () => {
    this.setState({ isPickerOpen: false });
    this.props.onClose();
  };

  processKey(fullKey){
    let processedKey;
    if (!fullKey || fullKey.length < 1) {
      processedKey = fullKey;
    } else {
      const splitKey = fullKey.split("_");
      const preSplit = splitKey[0];
      if (splitKey.length === 1) {
        // Underscore already stripped
        processedKey = preSplit;
      } else {
        // Underscore needs stripping
        const ext = fullKey.split(".").pop().toLowerCase();
        processedKey = `${preSplit}.${ext}`;
      }
    }

    return processedKey;
  }

  formatS3Url(key){
    return `https://cdn.appdataroom.com/${key}`;
  };

  formatTempUrl(tempUrl){
    return `${tempUrl}?policy=${this.state.policy}&signature=${this.state.signature}`;
  };

  onUploadSuccess(uploads) {
    const filesUploaded = [];
    uploads.filesUploaded.forEach((file) => {
      file.key = this.processKey(file.key);
      filesUploaded.push({
        url: this.formatS3Url(file.key),
        temp: this.formatTempUrl(file.url),
      });
    });

    this.props.onUploadSuccess(filesUploaded[0]);
  }

  onUploadFailed(uploads) {
    toast.error(`Failed to update logo. ${getGenericErrorMessage()}`);
  }

  render() {
    const subdomain = this.props.subdomain || 'anon';
    const acceptedSources = this.props.acceptedSources || 'image/*';
    let fromSources = [
      'local_file_system',
      'url',
      'googledrive',
      'dropbox',
      'box'
    ];
    if (acceptedSources === 'video/*') fromSources.push('video');
    if (acceptedSources === 'image/*') fromSources.push('imagesearch');

    return (
      <div>
        <div className="fs-temp-overlay animate fsFadeIn"></div>
        {this.state.isPickerOpen && (
          <PickerOverlay
            apikey="A5pKMkRZTS32ypmRpKQsrz"
            pickerOptions={{
              onClose: this.onPickerClose,
              accept: [acceptedSources],
              maxSize: 250 * 1024 * 1024,
              maxFiles: 1,
              fromSources: fromSources,
              lang: this.getFilestackLanguage(),
              transformations: {
                crop: true,
                circle: false,
                rotate: true
              },
              storeTo: {
                location: 's3',
                container: 'files.appdataroom.com',
                path: `${subdomain}/media/`,
                access: 'public',
                region: 'us-east-1'
              }
            }}
            clientOptions={{
              security: {
                policy: this.state.policy,
                signature: this.state.signature
              }
            }}
            onSuccess={this.onUploadSuccess}
            onError={this.onUploadFailed}
          />
        )}
      </div>
    );
  }
}
