import React, { useEffect, useState, useContext } from 'react';
import ViewerInfoBar from './_viewer-info-bar';
import LegacyInfoBar from './legacy-info-bar';
import ImageViewer from './_image-viewer';
import VideoViewer from './_video-viewer';
import FilestackViewer from './_filestack-viewer';
import PSPDFKitViewer from './_pspdfkit-viewer';
import ReactPlayer from 'react-player';
import { MediaUtility, LanguageUtility } from 'utilities';
import { UserContext } from 'contexts/user-context';

import './index.scss';
import Api from '../../services/api';
import { base64Encode } from '../../utilities/helper-methods';

export default function MediaViewer(props) {
  const { mediaId } = props;
  const user = useContext(UserContext);
  const guid = props.match.params.guid;
  const media = props.medias.find((m) => m.id.toString() === mediaId.toString()); //stringify so vpt ids can match
  const officeExtensions = ['ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx', 'pdf'];
  const isPDF = media?.ext === 'pdf';
  const isSupportedOfficeDocument = officeExtensions.includes(media?.ext);

  //state
  const [usePSPDFKit, setUsePSPDFKit] = useState(isPDF || isSupportedOfficeDocument);
  const [hasOpenedLinkOnLoad, setHasOpenedLinkOnLoad] = useState(false);

  //Lifecycle - mount
  useEffect(() => {
    //start pspdfkit
    tryInitializePSPDFKit();

    //1 time analytic event
    if (media && !media.deleted) {
      Api.sendMediaAction(media, 'media_view', user?.email);
    }
  }, []);

  const backClick = function () {
    if (media && props.guid) {
      Api.sendMediaAction(media, 'leave', user?.email);
    }

    props.navigate(`/${guid}`);
  };

  //do this in the pdf viwer
  const tryInitializePSPDFKit = () => {
    //exit
    if (!media) return;

    if (isSupportedOfficeDocument) {
      let url = media.url.replace(media.ext, 'pdf');
      Api.makeHeadRequest(url).then((res) => {
        if (!res.ok) setUsePSPDFKit(false);
      }).catch((res) => {
        setUsePSPDFKit(false);
        //console.log('Failed to obtain PDF: ', res);
      });
    }
  };

  const onPSPDFKitError = () => {
    setUsePSPDFKit(false);
  };

  const renderChildViewer = () => {
    if (!media) return null;

    const { preview_url, url, title, id } = media || {};
    const { settings } = props;
    const mediaType = MediaUtility.getMediaType(media);
    const user = useContext(UserContext);
    const base64User = user?.email ? base64Encode(user?.email) : null;
    const localize = LanguageUtility.getLocalization.bind(null, props);

    switch (mediaType) {
      case "image":
        return <ImageViewer media={media} src={preview_url} />;
      case "audio":
        return (
          <audio className="audio-player" controls>
            <source src={url} type="audio/mpeg" />
          </audio>
        );
      case "video":
        return <VideoViewer src={preview_url} />;
      case "pdf":
      case "powerpoint":
      case "document":
      case "spreadsheet":
      case "other":
        let pdfUrl = media.url;
        if (isSupportedOfficeDocument) pdfUrl = pdfUrl.replace(media.ext, "pdf");

        return usePSPDFKit ? (
          <PSPDFKitViewer {...props} media={media} document={pdfUrl} onPSPDFKitError={onPSPDFKitError} />
        ) : (
          <FilestackViewer media_id={id} preview={preview_url} src={preview_url} />
        );
      case "zip":
        const params = window.location.search;
        const src = params
          ? `${preview_url}${params}&y=${base64User}&guid=${guid}`
          : `${preview_url}?y=${base64User}&guid=${guid}`;
        return <iframe src={src} title={url}></iframe>;
      case "youtube":
        return <ReactPlayer className="youtube-player" url={media.preview_url} playing />;
      case "vpt":
        return <iframe src={media.preview_url} title={title} />;
      case "website":
        if (!hasOpenedLinkOnLoad) {
          window.open(media.preview_url, "_blank")
          setHasOpenedLinkOnLoad(true);
        }
        return (
          <div className="website-message-view">
            <div className="message">{localize('Website reopen label', 'This link will open in a new tab, click below to try opening it again')}</div>
            <button className="btn" onClick={() => window.open(media.preview_url, "_blank")}>Open Link</button>
          </div>
        )
      default:
        return null;
    }
  };

  return (
    <div className="media-viewer-container">
      {props.minimalPreviewMode ?
        <LegacyInfoBar
          media={media}
          totalMediaCount={props.medias.length}
          minimalPreviewMode={props.minimalPreviewMode}
          onBackButtonClicked={backClick}
          accountTheme={props.accountTheme}
          settings={props.settings}
          language={props.language}
          validateOrGateUser={props.validateOrGateUser}
        />
        :
        <ViewerInfoBar
          media={media}
          onBackButtonClicked={backClick}
          settings={props.settings}
          validateOrGateUser={props.validateOrGateUser}
        />
      }
      {renderChildViewer()}
    </div>
  );
}
