import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faStar } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import PulseLoader from 'react-spinners/PulseLoader';

import { MentionsInput, Mention } from 'react-mentions';

import ReactHtmlParser from 'react-html-parser';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import config from '../../../assets/configuration/firebase-config';
import Api from '../../../services/api';
import './_comments-tab.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';
import moment from 'moment';
import uniqid from 'uniqid';
import { UserContext } from 'contexts/user-context';
import { ThemeUtility, UsersUtility } from 'utilities';

class CommentsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      value: '',
      comments: '',
      editComment: false,
      editedComment: '',
      user: '',
      selectedComment: '',
      guid: '',
      fadeOutComment: false,
      mentionData: null,
      markupValue: '',
      typingTimeout: null
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }
    this.mesRef = React.createRef();
    this.timeout = 0;
  }

  getComments = async () => {
    const guid = this.state.guid;
    const ref = 'followups/' + guid + '/comments';
    const followupRef = firebase.database().ref(ref);
    followupRef.on('value', (snapshot) => {
      const comments = snapshot.val() ? Object.values(snapshot.val()) : [];
      this.setState({
        comments: comments
      });
    });
  };

  handleChange = (event, newValue, newPlainTextValue, mentions) => {
    this.setState({
      value: newValue,
      mentionData: { newValue, newPlainTextValue, mentions },
      newPlainTextValue: newPlainTextValue,
      markupValue: newValue
    });
    if (this.props.settings.typingIndicator) this.signalTyping();
  };

  signalTyping = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    const ref = 'followups/' + this.state.guid;
    const usersEmail = this.context.email;
    firebase.database().ref(ref).update({
      someone_typing: true,
      typing_user: usersEmail
    });
    this.timeout = setTimeout(() => {
      firebase.database().ref(ref).update({
        someone_typing: false
      });
    }, 1000);
  };

  async componentDidMount() {
    await this.setGuid();
    await this.getComments();
    const ref = 'followups/' + this.state.guid;
    const activityRef = firebase.database().ref(ref);
    let activity = {};
    activityRef.on('value', (snapshot) => {
      activity = snapshot.val();
      if (activity) {
        const someone_typing = activity.hasOwnProperty('someone_typing')
          ? activity.someone_typing
          : false;
        const typing_user = activity.hasOwnProperty('typing_user') ? activity.typing_user : null;
        this.setState({
          someone_typing: someone_typing,
          typing_user: typing_user
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.comments.length > prevState.comments.length) && (prevState.comments.length !== 0)) {
      var commentContainer = document.getElementById("comments-container");
      commentContainer.scrollTop = commentContainer.scrollHeight;
    }
  }

  // TODO: Remove after mentions deploy
  handleNonMentionsChange = (e) => {
    this.setState({
      input: e.target.value
    });
  };

  handleEditedCommentChange = (event, newValue, newPlainTextValue, mentions) => {
    this.setState({
      editedValue: newValue,
      mentionData: { newValue, newPlainTextValue, mentions },
      editedNewPlainTextValue: newPlainTextValue,
      editedMarkupValue: newValue
    });
  };

  // TODO: Remove after mentions deploy
  handleNonMentionCommentChange = (e) => {
    this.setState({
      editedComment: e.target.value
    });
  };

  setGuid = async () => {
    // Delimit the path and always grab the first part, since we know that will be the guid
    const pieces = window.location.pathname.split('/');
    const guid = pieces[1];
    this.setState({
      guid: guid
    });
  };

  submitCommentActions(plaintext, mentionData, comment_id, markupValue, timestamp) {
    const followupSender = this.props.salesContact.email;
    let notificationPayloads = {
      activity: [],
      comment: [],
      user_mentioned: [],
      author_mentioned: [],
      third_party_mention: [],
      everyone: []
    };
    const hasMentions = mentionData.mentions.length > 0;
    const { guid } = this.state;
    const { user } = this.props;
    let authorWasMentioned, isEveryone = false;

    if (hasMentions) {
      const mentions = mentionData.mentions;
      mentions.forEach((mention) => {
        const mentionedUser = mention.id;
        // Notify all users
        if (mentionedUser === 'everyone') {
          isEveryone = true;
          const usersToNotify = this.props.users.all;
          usersToNotify.forEach((userToNotify) => {
            notificationPayloads.user_mentioned.push({
              author: user,
              comment: plaintext,
              mentionedUser: userToNotify,
              comment_id: comment_id,
              markupValue: markupValue,
              timestamp: timestamp
            });
          });
          // Author was mentioned
        } else if (mentionedUser === followupSender && mentionedUser !== user) {
          authorWasMentioned = true;
          notificationPayloads.author_mentioned.push({
            author: user,
            comment: plaintext,
            mentionedUser: followupSender,
            comment_id: comment_id,
            markupValue: markupValue,
            timestamp: timestamp
          });
          // User was mentioned (but not the author)
        } else if (mentionedUser !== user) {
          notificationPayloads.user_mentioned.push({
            author: user,
            comment: plaintext,
            mentionedUser: mentionedUser,
            comment_id: comment_id,
            markupValue: markupValue,
            timestamp: timestamp
          });
        }
        if (user !== followupSender && !authorWasMentioned && !isEveryone) {
          // TODO: Possibly only send the notification once for a third party mention
          notificationPayloads.third_party_mention.push({
            //user: user,
            comment: plaintext,
            mentionedUser: mentionedUser,
            author: user,
            followupSender: followupSender,
            comment_id: comment_id,
            markupValue: markupValue,
            timestamp: timestamp
          });
        }
      });
    }
    // Only send the activity notification if the comment was not from the author, the author was not mentioned, and there are no mentions
    if (!authorWasMentioned && !hasMentions) {
      notificationPayloads.comment.push({
        author: user,
        comment: plaintext,
        followupSender: followupSender,
        comment_id: comment_id,
        markupValue: markupValue,
        timestamp: timestamp
      });
    }
    Api.sendCommentActions(notificationPayloads, guid);
    this.setState({
      input: '',
      value: ''
    });
  }

  formatCommentHTML(raw) {
    // This will give us the content (HTML) to post to firebase
    // Strip our markup from react-mentions
    let input = raw.replace(/\(([^)]+)\)/g, '');
    input = input.replace(/@\[/g, "<b style='font-weight:700;'>");
    input = input.replace(/\]/g, '</b>');
    return input;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const user = this.context ? this.context : this.props.children;
    let input = this.state.value;
    if (!input) return;
    if (!user) {
      toast.error(
        'There was a problem submitting the comment: Could not identify user. Please try clearing your cache and reload the page.'
      );
      return;
    }
    const { guid, markupValue } = this.state;
    input = this.state.value;
    const mentionData = this.state.mentionData;
    const plaintextComment = this.state.newPlainTextValue;
    // Parse and send notifications
    input = this.formatCommentHTML(input);
    const comment_id = uniqid();
    const ref = 'followups/' + guid + '/comments/' + comment_id;
    //var now = moment.utc().toDate().toString();
    var now = new Date().toUTCString();
    this.submitCommentActions(plaintextComment, mentionData, comment_id, markupValue, now);
    firebase.database().ref(ref).set({
      id: comment_id,
      email: user?.email,
      body: input,
      markupValue: markupValue,
      timestamp: now
    });
    this.setState({
      input: ''
    });
  };

  handleDeleteComment = (e) => {
    const guid = this.state.guid;
    const comment_id = e.target.id;
    const ref = 'followups/' + guid + '/comments/' + comment_id;
    try {
      firebase.database().ref(ref).remove();
    } catch (err) {
      toast.error(
        'There was a problem deleting the comment. If this persists please contact support@gomodus.com'
      );
    }
  };

  toggleEditMode = (comment) => (e) => {
    e.preventDefault();
    this.setState({
      selectedComment: e.target.id,
      editComment: true,
      editedComment: comment,
      editedValue: comment,
      editedMarkupValue: comment
    });
  };

  saveComment = (comment) => (e) => {
    e.preventDefault();
    const guid = this.state.guid;
    let { editedValue, editedMarkupValue } = this.state;
    editedValue = this.formatCommentHTML(editedValue);
    const comment_id = e.target.id;
    const ref = 'followups/' + guid + '/comments/' + comment_id;
    firebase.database().ref(ref).update({
      body: editedValue,
      markupValue: editedMarkupValue
    });
    // TODO: Parse HTML of comment
    this.setState({
      editComment: false,
      selectedComment: ''
    });
  };

  //TODO: Remove after deploy of mentions
  saveNonMentionComment = (comment) => (e) => {
    e.preventDefault();
    const guid = this.state.guid;
    let updates = {};
    const comment_id = e.target.id;
    const ref = 'followups/' + guid + '/comments/' + comment_id + '/body';
    updates[ref] = this.state.editedComment;
    firebase.database().ref().update(updates);
    this.setState({
      editComment: false,
      selectedComment: ''
    });
  };

  onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false && this.state.value) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  validateInputAllowed = () => {
    this.props.validateOrGateUser(true);
  };

  render() {
    const hasComments = this.state.comments;
    const { mentionableUsers, isLoading, settings } = this.props;
    const { typing_user, someone_typing } = this.state;
    const accountTheme = this.props.accountTheme;
    const buttonColor = accountTheme.primaryColor; //use the modus color instead of the account
    const sendButtonTextColor = accountTheme.secondaryColor;
    const currentUser = this.context;
    const userDetails = this.props.userDetails;
    const userTyping = someone_typing && typing_user !== currentUser;

    const CommentAvatar = function (props) {
      const { email, name } = props;
      const text = UsersUtility.getInitials(name, email);
      const color = ThemeUtility.getAvatarStyles(email);
      const avatarHighlight = email === currentUser?.email;
      let { profile_picture: profilePicture } = userDetails[email] || false;
      const backgroundImageSrc = { backgroundImage: 'url(' + profilePicture + ')' };
      let presenceIndicator = userDetails[email] ? userDetails[email].presence : false;

      let indicatorColor = '';
      presenceIndicator = 'away';
      if (presenceIndicator === 'online') {
        indicatorColor = '#54c771';
      } else if (presenceIndicator === 'away') {
        indicatorColor = '#FBBD08';
      } else if (presenceIndicator === 'offline') {
        presenceIndicator = false;
      }

      return (
        <div
          className="member-avatar"
          style={{
            color: '#ffffff',
            backgroundColor: color,
            height: '31px',
            width: '31px',
            borderRadius: '50%',
            textAlign: 'center',
            lineHeight: '31px',
            fontSize: '18px',
            fontWeight: '600',
            marginTop: '4px',
            border: avatarHighlight ? '2px solid #4182FF' : '',
            padding: '2px',
            backgroundClip: 'content-box'
          }}
        >
          {profilePicture && (
            <div className="user-avatar" style={backgroundImageSrc} alt={text}></div>
          )}
          {!profilePicture && text}
          {presenceIndicator && (
            <div
              className="member-avatar presence-indicator"
              style={{
                position: 'relative',
                backgroundColor: indicatorColor,
                left: '20px',
                top: '-10px',
                height: '30%',
                width: '30%',
                padding: '2px'
              }}
            ></div>
          )}
        </div>
      );
    };

    return (
      <div className="comments-tab-container">
        <div className="comments-container fade" id="comments-container">
          {hasComments &&
            this.state.comments.map((comment, index) => (
              <div
                key={comment.id}
                className="comment-row animate__animated animate__fadeInLeft animate__repeat-1"
              >
                <div className="comment-left">
                  <CommentAvatar className="comment-avatar" email={comment.email} />
                </div>
                <div className="comment-right">
                  <div className="comment-header">
                    <div className="comment-user">
                      {comment.email
                        ? '@' + comment.email.slice(0, comment.email.indexOf('@'))
                        : 'Unknown'}
                      {comment.email === this.props.owner && (
                        <FontAwesomeIcon
                          id="star-icon"
                          icon={faStar}
                          color="#ffffff"
                          style={{
                            fontSize: '8px',
                            borderRadius: '50%',
                            padding: '4px',
                            marginLeft: '3px',
                            backgroundColor: '#4182FF'
                          }}
                        />
                      )}
                    </div>
                    <div className="comment-timestamp">
                      {comment.timestamp ? moment(comment.timestamp).local().fromNow() : ""}
                    </div>
                  </div>
                  {this.state.selectedComment !== comment.id && (
                    <div className="comment-body">{ReactHtmlParser(comment.body)}</div>
                  )}
                  {this.state.selectedComment === comment.id && (
                    // <textarea onChange={this.handleCommentChange} value={this.state.editedComment} className="edit-comment"/>
                    <MentionsInput
                      placeholder="Add a comment... use @ to mention someone"
                      onChange={this.handleEditedCommentChange}
                      value={this.state.editedValue}
                      onClick={this.validateInputAllowed}
                      onKeyDown={this.onEnterPress}
                      className="edit-comment-textarea"
                      markup="@{{__id__||__display__}}"
                    >
                      <Mention
                        trigger="@"
                        data={mentionableUsers ? mentionableUsers : []}
                        className="mentions__mention"
                      />
                    </MentionsInput>
                  )}
                  {comment.email === currentUser?.email && (
                    <div className="comment-footer">
                      {this.state.selectedComment !== comment.id && (
                        <button
                          disabled={this.state.editComment && !this.state.editedComment}
                          className="comment-action-button"
                          id={comment.id}
                          onClick={this.toggleEditMode(
                            comment.markupValue ? comment.markupValue : comment.body
                          )}
                        >
                          {/* TODO: localize this */}
                          Edit
                        </button>
                      )}
                      {/* TODO: localize this */}
                      {this.state.selectedComment === comment.id && (
                        <button
                          disabled={this.state.editComment && !this.state.editedComment}
                          className="comment-action-button"
                          id={comment.id}
                          onClick={this.saveComment()}
                        >
                          Save
                        </button>
                      )}
                      {/* TODO: localize this */}
                      <button
                        className="comment-action-button"
                        id={comment.id}
                        onClick={this.handleDeleteComment}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          <div className="last-comment" ref="lastComment"></div>
        </div>
        {settings && settings.typingIndicator && userTyping && (
          <div className="typing-container animate__animated animate__fadeIn">
            <PulseLoader size={6} color={buttonColor} />
            {/* TODO: localize this */}
            <small id="someone-typing-text">
              <b>
                {typing_user ? '@' + typing_user.slice(0, typing_user.indexOf('@')) : 'Someone'}
              </b>{' '}
              is typing...
            </small>
          </div>
        )}
        <div
          className="comment-input-container"
          onSubmit={this.handleSubmit}
        >
          <div className="comment-form-container">
            <form className="comment-form" onSubmit={this.handleSubmit}>
              <MentionsInput
                /* TODO: localize this */
                placeholder="Add a comment... use @ to mention someone"
                onChange={this.handleChange}
                value={this.state.value}
                onKeyDown={this.onEnterPress}
                onClick={this.validateInputAllowed}
                className="comments-textarea"
                disabled={isLoading}
                markup="@{{__id__||__display__}}"
              >
                <Mention
                  trigger="@"
                  data={mentionableUsers ? mentionableUsers : []}
                  className="mentions__mention"
                />
              </MentionsInput>
              <button
                onClick={this.handleSubmit}
                disabled={!this.state.value}
                className="submit-comment-button"
              >
                <FontAwesomeIcon
                  className="submit-icon"
                  size="sm"
                  icon={faPaperPlane}
                  style={{ color: sendButtonTextColor }}
                />
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

CommentsTab.contextType = UserContext;
export default CommentsTab;
