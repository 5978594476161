import './media-item.scss';
import React, { useState, useEffect, useContext } from 'react';
import { useEditorStore } from 'stores/editorStore';
import Api from 'services/api';
import { MediaUtility, LanguageUtility } from 'utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faLink, faTimes } from '@fortawesome/free-solid-svg-icons';
import PulseLoader from 'react-spinners/PulseLoader';
import ReactTooltip from 'react-tooltip';
import DeleteModal from 'components/delete-modal';
import { UserContext } from 'contexts/user-context';

const delays = [
  '',
  'animate-delay-40',
  'animate-delay-80',
  'animate-delay-120',
  'animate-delay-160',
  'animate-delay-200'
];

export default function MediaItem(props) {
  //state
  const [isFlipAnimationEnabled, setIsFlipAnimationEnabled] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  //variables
  const { company, media} = props;
  //store variables
  const { editContent, deletedMediaIds, isMediaReloading } = useEditorStore();

  const { title, thumbnail_url, removed, id } = media;
  const queuedForDelete = deletedMediaIds && id && deletedMediaIds.includes(id);

  const mediaType = MediaUtility.getMediaType(props.media);
  const mediaTypeLabel = MediaUtility.getMediaTypeLabel(props, media);
  const isWebsite = mediaType === 'website';
  const link = isWebsite ? (media.link || media.url || null) : null;
  const pending = media?.pending || false;

  const isDeleteButtonVisible = editContent && !queuedForDelete;
  const deleted = removed === 1;
  const deletedTooltipText = LanguageUtility.getLocalization(props, 'Media removed tooltip').replace("{COMPANY}", company);

  const delay = delays[Math.floor(Math.random() * 10 * (6 / 10))];
  const intialAnimation = `flipInY animate ${delay}`;

  const { email: userEmail } = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => { setIsFlipAnimationEnabled(false); }, 2000);
  }, [])

  //UI functions
  const onDeleteModalRequestClose = (e) => {
    setIsDeleteModalOpen(false);
  };

  const handleClick = function (e) {

    if (queuedForDelete || pending) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    if (isWebsite) {
      Api.sendMediaAction(media, 'media_view', userEmail);
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    props.onClick(props.media);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDeleteModalOpen(true);
  }

  //-- Render --//
  let mediaClasses = `media-item`;
  const thumbnailStyles = !pending ? 
    { 
      backgroundImage: `url(${thumbnail_url})` 
    } : 
    { 
      display: 'flex', 
      justifyContent: 'center',
      alignItems: 'center'
    }
  ;
  //Initial Animation
  if (isFlipAnimationEnabled) {
    mediaClasses += ' ' + intialAnimation;
  }

  //Delete
  if (queuedForDelete) {
    mediaClasses += ` ${isMediaReloading ? "zoom-out-delete" : "deleted"} `;
  }

  return (
    <div>
      <a className={mediaClasses} onClick={handleClick}
        href={link} target={link ? '_blank' : null} rel="noreferrer">

        <div className='media-thumb' style={thumbnailStyles}>

          {link && !thumbnail_url && (
            <div className="fallback-icon">
              <FontAwesomeIcon size="5x" icon={faLink} />
            </div>
          )}

          {pending && (
            <PulseLoader size={10} className='pending-spinner' color='#ffffff' />
          )}

          {isDeleteButtonVisible &&
            <button className="btn-delete" onClick={handleDeleteClick}>
              <FontAwesomeIcon icon={faTimes} size="1x" />
            </button>
          }

        </div>

        <div className="title">
          <span>{title}</span>
        </div>
        <div className="media-type">{mediaTypeLabel}</div>


        {/* I don't think this is used anymore */}
        {deleted && (
          <>
            <FontAwesomeIcon className="deleted-icon" size="lg" icon={faBan}
              data-tip data-for="deleted-info" />
            <ReactTooltip id="deleted-info" effect="solid" className="deleted-tooltip">
              {deletedTooltipText}
            </ReactTooltip>
          </>
        )}

      </a>


      {isDeleteModalOpen &&
        <DeleteModal
          isOpen={true}
          media={media}
          onRequestClose={onDeleteModalRequestClose}
          onMediaDeleted={props.onMediaDeleted}
          accountSettings={props.accountSettings} />
      }
    </div>
  );
}
