import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import './index.scss';


export default function PressHoldButton(props) {

  const duration = 1600;
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [internalTimeout, setInternalTimeout] = useState(null);

  const onSuccess = () => {
    setIsSuccessful(true);
    if (props.onSuccess) { props.onSuccess(); }
  };

  const onConfirming = e => {
    e.stopPropagation();
    if (isSuccessful) return;
    const timeout = setTimeout(onSuccess, duration);
    setInternalTimeout(timeout);
    setIsProcessing(true);
  }

  const onCancel = e => {
    e.stopPropagation();
    if (isSuccessful) return;
    clearTimeout(internalTimeout);
    setIsProcessing(false);
  }

  let containerClasses = isProcessing ? "process" : "";
  let buttonClasses = isSuccessful ? " success" : "";

  return (
    <div className={`press-hold-button ${containerClasses}`} id={props.id}>

      <div className={`circle__box ${containerClasses}`}>
        <div className="circle__wrapper circle__wrapper--right">
          <div className="circle__whole circle__right"></div>
        </div>
        <div className="circle__wrapper circle__wrapper--left">
          <div className="circle__whole circle__left"></div>
        </div>
      </div>


      <button
        onMouseDown={onConfirming}
        onMouseLeave={onCancel}
        onMouseUp={onCancel}
        className={`press-hold-button ${buttonClasses}`}>
        <FontAwesomeIcon className="times icon" icon={faTimes} size="1x" />
        <FontAwesomeIcon className="check icon" icon={faCheck} size="1x" />
      </button>
    </div>
  )

}
