import React, { useState, useEffect } from 'react';
import './index.scss';
import Api from 'services/api';
import { LanguageUtility } from 'utilities';
import PrivacyGateWelcomeLoader from "../welcome-loader";

export default function LinkedinCallback(props) {
  const [isValidating, setIsValidating] = useState(true);
  const [firstName, setFirstName] = useState('');

  useEffect(() => {
    checkForLinkedInAuth();
  }, []);

  const checkForLinkedInAuth = () => {
    let url = new URL(window.location.href);
    let urlParams = new URLSearchParams(url.search);

    if (urlParams.has('state') && urlParams.has('code') && sessionStorage.state == urlParams.get('state')) {
      let code = urlParams.get('code');
      let redirectURI = `${window.location.origin}/linkedin/callback`;

      let url = sessionStorage.linkedin_callback_url;
      let queryAppend = '?t=';
      if(url.indexOf('?') > -1) queryAppend = '&t=';
      let guid = sessionStorage.guid;
      sessionStorage.clear();

      Api.validateLinkedIn(code, redirectURI, guid).then((data) => {
        setIsValidating(false);
        setFirstName(data.localizedFirstName);
        location.href = url + queryAppend + data.magic_token;
      }).catch(() => {
        location.href = url + queryAppend + 'failed_linkedin';
      });
    } else { //authentication cancelled or error
      let previousURL = sessionStorage.linkedin_callback_url
      sessionStorage.clear();
      location.href = previousURL;
    }
  };

  //Render
  let message = isValidating ? LanguageUtility.getLocalization(props, 'Linkedin authorization message', 'Validating your credentials') : null;

  return (
    <div className="linkedin-callback-view">
      <PrivacyGateWelcomeLoader {...props} message={message} name={firstName} />
    </div>
  );
}
