import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Modal from 'components/modal';
import Switch from 'react-switch';
import { withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import { LanguageUtility, PrivacyUtility } from 'utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modus from 'modus-javascript-bridge/dist/modus-js-bridge.js';
import PropTypes from 'prop-types';
import Api from '../../services/api';
import { faChevronUp, faChevronDown, faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import './index.scss';

const localize = LanguageUtility.getLocalization;

const CustomRadio = withStyles({
  root: { color: '#666', '&$checked': { color: '#4182ff' } },
  checked: {}
})((props) => <Radio color="default" {...props} />);

const CustomCheckBox = withStyles({
  root: {
    color: '#828282',
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        color: '#4182ff'
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1
      },
      '& .MuiTouchRipple-root': {
        color: '#828282'
      }
    }
  },
  checked: {}
})((props) => <Checkbox  {...props} />);

export default function ShareSettingModal(props) {
  //instance variables
  const privacyOptions = PrivacyUtility.getPrivacyOptions(props);

  //state
  const [privacySetting, setPrivacySetting] = useState(PrivacyUtility.DEFAULT_PRIVACY_SETTING);
  const [passcode, setPasscode] = useState(props.settings?.passcode || '');
  const [enablePasscode, setEnablePasscode] = useState(false);
  const [passcodeError, setPasscodeError] = useState(false);

  //Lifecycle
  useEffect(() => {
    //set the intial privacy settings
    let initialPrivacySetting = PrivacyUtility.getPrivacyOptionByValue(props.privacy_setting);
    setPrivacySetting(initialPrivacySetting);
    setEnablePasscode(initialPrivacySetting.value === "PRIVATE-PASSCODE");
  }, []);

  const handleCancelClick = () => {
    if (window.parent) {
      Modus.closeContainer();
    }
    props.onRequestClose();
  }

  //api
  const saveSettings = () => {
    //validate
    if (enablePasscode && passcode === '') {
      setPasscodeError(true);
      return;
    }

    //start data building
    let settingsObj = {
      default: false,
      user_id: props.ownerUserId ? props.ownerUserId : null, //change this to use the salescontact context?
      passcode: enablePasscode ? passcode : null,
      enablePasscode: enablePasscode
    };

    //Save
    Api.update({ settings: settingsObj, privacy_setting: privacySetting.value }).then(() => {
      props.updateShareableSettings(settingsObj, privacySetting.value);
      props.onRequestClose();
      toast.success('Settings saved.');


      //NOTE: i don't like asking window.parent (what if you are attached to iOS).
      //TODO: create a bridge method called like Modus.isBridgeConnected
      if (window.parent) {
        Modus.closeContainer();
      }

    }).catch((ex) => {
      console.error(ex)
      toast.error(`Failed to save settings.`);
    });
  };

  //Event Handlers
  const handlePrivacyChange = (value) => {
    let newPrivacySetting = PrivacyUtility.getPrivacyOptionByValue(value);
    setPasscode('');
    setEnablePasscode(false);
    setPrivacySetting(newPrivacySetting);
  };
  const handlePasscodeChange = (value) => {
    setPasscode(value);
    setPasscodeError(false);
  };
  const onEnablePasscodeChanged = (value) => {
    if (!value) {
      return handlePrivacyChange("PRIVATE");
    }

    let newPrivacySetting = PrivacyUtility.getPrivacyOptionByValue("PRIVATE-PASSCODE");
    setEnablePasscode(value);
    setPrivacySetting(newPrivacySetting);
  }

  const previewData = PrivacyUtility.getPreviewData(privacySetting.value, enablePasscode);
  const showFullScreen = props.fullScreenModalView === 'privacy_setting';
  const passcodeIsEnabled = !!privacyOptions.find(x => x.value === "PRIVATE-PASSCODE");

  //Render
  return (
    <Modal id="share_settings_modal_base" isOpen={true} hideCloseButton={true} fullScreen={showFullScreen} onRequestClose={props.onRequestClose} v2={true} >
      <div className="share-settings-modal-header">
        <div className="title">
          {localize(props, 'Share Setting Title', 'Settings')}
        </div>
        <button className="btn-no-style btn-close" onClick={props.onRequestClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <div className="share-settings-modal">

        <div className="settings-container">
          <div className="privacy-setting-container">
            <FormControl className="radio-group-root">
              <RadioGroup
                value={privacySetting._uiValue || privacySetting.value}
                onChange={(e) => handlePrivacyChange(e.target.value)}>

                {privacyOptions.filter(x => !x.hidden).map((option, i) => {
                  return (

                    <div key={i}>
                      <FormControlLabel
                        className='radio-group'
                        value={option._uiValue || option.value}
                        control={<CustomRadio className='radio-check-container' />}
                        label={<Typography className="radio-label">{localize(props, option.key, option.label)}</Typography>} />

                      {(passcodeIsEnabled && option.value.startsWith("PRIVATE") && privacySetting.value.startsWith("PRIVATE")) &&
                        <OptionalPrivacyPasscode
                          passcode={passcode}
                          passcodeError={passcodeError}
                          onPasscodeChange={handlePasscodeChange}
                          enablePasscode={enablePasscode}
                          onEnablePasscodeChanged={onEnablePasscodeChanged}
                        />
                      }
                    </div>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>

          <div className="privacy-setting-description-container">
            <div className="header">Preview: {privacySetting.label}</div>
            <div className='image-container'>
              {previewData.img_path === null ?
                <div className="placeholder"> No Gate </div> :
                <img src={previewData.img_path} />
              }
            </div>
            <div className="description" >{previewData.description}</div>
          </div>
        </div>

        <div className="modal-action-btns">
          <button className="btn btn-cancel" onClick={handleCancelClick}>
            Cancel
          </button>
          <button className="btn btn-share btn-primary" onClick={saveSettings}>
            Save Changes
          </button>
        </div>
      </div>
    </Modal>
  );
}


const OptionalPrivacyPasscode = ({ passcode, passcodeError, onPasscodeChange, enablePasscode, onEnablePasscodeChanged, ...props }) => {
  const revealPasscode = () => {
    let elem = document.getElementById('passcode');
    elem.type = elem.type === 'password' ? 'text' : 'password';
  };

  return (
    <div className='optional-passcode'>
      <div className="passcode-switch">
        <span className="label">
          {localize(props, "Passcode Protect Label", "Passcode Protect")}
        </span>
        <Switch
          onChange={onEnablePasscodeChanged}
          checked={enablePasscode}
          onColor="#4182FF"
          checkedIcon={false}
          uncheckedIcon={false}
          height={20}
          width={40}
        />
      </div>

      {enablePasscode &&
        <div className="passcode-input">
          <input id="passcode"
            className={passcodeError ? 'error' : null}
            value={passcode}
            type="password"
            onChange={(e) => onPasscodeChange(e.target.value)} />
          <FontAwesomeIcon
            className="reveal-icon"
            icon={faEye}
            onClick={(e) => revealPasscode(e)}
            size="1x"
          />
        </div>
      }
    </div>
  )
}

ShareSettingModal.propTypes = {
  settings: PropTypes.object,
  updateShareableSettings: PropTypes.func,
  onRequestClose: PropTypes.func,
  userDetails: PropTypes.object
};
