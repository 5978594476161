import './_avatar.scss';
import './_dark.scss';

import React from 'react';
import { UsersUtility, ThemeUtility } from 'utilities';

export default function Avatar(props) {
  const { user, userDetails, profilePicture, isLoading, accountTheme, isHighlighted, hasRenderedBefore = true, useRandomColor = true } = props;
  let { fullName = null } = props;

  //generate intials
  if (userDetails) {
    let userObject = userDetails[user];
    fullName = userObject?.first_name ? `${userObject.first_name} ${userObject.last_name}` : null;
  }

  let initials = UsersUtility.getInitials(fullName, user);

  //Generate coloring
  let bgColor = accountTheme?.primaryColor || "#4d4d4d";
  if (useRandomColor) {
    bgColor = ThemeUtility.getAvatarStyles(user)
  }

  //Base component classes
  let otherClasses = `${props.className || ""}`;
  if (isLoading) otherClasses += 'skeleton ';
  if (!hasRenderedBefore) otherClasses += 'animate fadeIn ';
  if (isHighlighted) otherClasses += "highlighted ";

  const hasAvatar = !!props.profilePicture;

  return (
    <div className={`avatar-container ${otherClasses}`}>
      {!isLoading && !hasAvatar &&
        <div className={`avatar`}>
          <div className="initials" style={{ backgroundColor: `${bgColor}` }}>
            {initials}
          </div>
        </div>
      }
      {!isLoading && hasAvatar && <img id="user-avatar" src={profilePicture} />}
    </div >
  );
}
