import React, { useEffect, useRef, useState, useCallback } from 'react';

export default function VideoViewer({
  maxAttempts = 3,
  delay = 1500,
  src,
  disabled = false,
  autoplay = false,
  loop = false,
  ...props
}) {
  const [attempts, setAttempts] = useState(maxAttempts);
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isVideoError, setIsVideoError] = useState(false);
  const videoRef = useRef();

  useEffect(() => {
    setIsVideoError(false);
    setAttempts(maxAttempts);
  }, [src]);

  const reload = useCallback(() => {
    setAttempts((prev) => prev - 1);
    if (videoRef.current) {
      setTimeout(() => {
        if (attempts > 0) {
          videoRef?.current?.load();
          videoRef?.current?.pause();
          videoRef.current.currentTime = 0;
        } else {
          setIsVideoError(true);
        }
      }, delay);
    }
  });

  const handleOnError = (e) => {
    reload();
  };

  const handleOnLoadStart = () => {
    setIsVideoLoaded(false);
  };

  const handleOnCanPlay = () => {
    setIsVideoLoaded(true);
  };

  useEffect(() => {
    if (disabled && videoRef?.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [disabled]);

  return (
    <div className="video-viewer">
      {src && !isVideoError && (
        <>
          {!isVideoPlaying && (
            <div className="video-overlay" />
          )}
          <video
            className={`${isVideoPlaying || videoRef?.current?.currentTime != 0 ? 'is-playing' : 'is-paused'
              }`}
            onCanPlay={handleOnCanPlay}
            onError={handleOnError}
            onLoadStart={handleOnLoadStart}
            disabled
            src={src}
            ref={videoRef}
            preload="auto"
            controls={isVideoPlaying}
            autoPlay={autoplay}
            loop={loop}
            controlsList="nodownload"
            playsInline
          />
        </>
      )}
      {(!src || isVideoError) && (
        <div className="no-source">
          <p>{isVideoError ? 'Failed to load/play video' : 'No source was found'}</p>
        </div>
      )}
    </div>
  );
}
