import React, { useState, useRef, useEffect, useCallback } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Spinner from 'components/spinner';
import './index.scss';

export const Video = ({ maxAttempts = 3, delay = 1500, poster = null, ...props }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isVideoError, setIsVideoError] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [attempts, setAttempts] = useState(maxAttempts);

  const { options } = props;

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;
      playerRef.current = videojs(videoElement, options);
    } else {
      let player = playerRef.current;
      player.src(options.sources);
      player.poster(options.poster);
    }
  }, [options, videoRef, isVideoError]);

  useEffect(() => {
    let playerElement = playerRef.current || {};
    playerElement.on('error', function() {
      handleOnError();
    });
  }, []);

  useEffect(() => {
    setIsVideoError(false);
    setAttempts(maxAttempts);
  }, [options]);

  const handleOnError = (e) => {
    props.onVideoError();
  };

  const handleOnCanPlay = () => {
    setIsVideoLoading(false);
  };

  return (
    <>
      {isVideoLoading ? (
        <div className="video-spinner">
          <Spinner isActive />
        </div>
      ) : null}
      <div data-vjs-player>
        <video
          id="header-video"
          onCanPlay={handleOnCanPlay}
          onError={handleOnError}
          ref={videoRef}
          className="video-js vjs-big-play-centered"
        />
      </div>
    </>
  );
};

export default Video;
