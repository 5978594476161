import './_styles.scss';

import React, { useState, useContext } from 'react';
import Avatar from 'components/action-tray/avatar';
import Settings from 'components/settings';
import { UserContext } from 'contexts/user-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronUp,
  faChevronDown,
  faEllipsisVertical
} from '@fortawesome/free-solid-svg-icons';

const UserButton = ({ visible, isMenuOpen, onClick, user, ...props }) => {
  //TODO: fix the avatar crap, too many things being passed in
  //TODO: fix the avatar so the highlight can work based on hover

  //exit clause
  if (!visible) return null;
  let userEmail = user?.email || null;

  //classes
  let classes = userEmail ? "has-user" : "";
  if (isMenuOpen) classes += " open";

  return (
    <button className={`user-details-button ${classes}`} onClick={onClick}>

      <div className='inactive-user'>
        Sign In / Sign Up
      </div>

      <div className='active-user'>
        <Avatar user={userEmail} useRandomColor={false} />
        <div className="email">{userEmail}</div>
        <FontAwesomeIcon icon={isMenuOpen ? faChevronUp : faChevronDown} size="1x" />
      </div>
    </button>
  );
}


const UserDetails = ({ disabled, isOwner, isEditable, ...props }) => {
  //TODO: fix the avatar crap, too many things being passed in
  //TODO: fix the avatar so the highlight can work based on hover
  //TOOD: don't pass full props object just pass certain things
  const [isOpen, setIsOpen] = useState(false);
  const user = useContext(UserContext);

  const isUserSignedIn = user?.email;
  const isUserButtonVisible = !isOwner;
  const isSettingButtonVisible = !isUserButtonVisible || !isUserSignedIn

  //UI interactions
  const handleToggleSettingsClick = (e) => {
    setIsOpen(!isOpen);
  };

  const onUserButtonClick = () => {
    if (!isUserSignedIn) {
      props.validateOrGateUser(true, "sign-in");
      return;
    }

    handleToggleSettingsClick();
  };

  return (
    <div id="user_details_container" className='user-details-container'>

      {isSettingButtonVisible && (
        <button
          disabled={disabled}
          className="settings-icon-btn"
          onClick={handleToggleSettingsClick}       >
          <FontAwesomeIcon size="1x" icon={faEllipsisVertical} />
        </button>
      )}

      <UserButton {...props}
        onClick={onUserButtonClick}
        isMenuOpen={isOpen}
        visible={isUserButtonVisible} />

      <Settings
        {...props}
        parent="user_details_container"
        isOpen={isOpen}
        isEditable={isEditable}
        handleClickOutside={() => { setIsOpen(false) }}
      />

    </div>
  );
}

export default UserDetails;
