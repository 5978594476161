import "./_pdf-viewer.scss";
import React, { useEffect } from 'react';
import { useEditorStore } from "stores/editorStore";
import Api from 'services/api';
import PSPDFKit from 'pspdfkit';

export default function PSPDFKitViewer(props) {
  const { darkMode } = useEditorStore();

  const getToolbarItems = function () {
    const { media, settings } = props;
    const annotationControls = [
      'annotate',
      'sidebar-annotations',
      'ink',
      'highlighter',
      'text-highlighter',
      'ink-eraser',
      'signature',
      'image',
      'stamp',
      'note',
      'text',
      'line',
      'arrow',
      'rectangle',
      'ellipse',
      'polygon',
      'cloudy-polygon',
      'polyline',
      'document-editor',
      'document-crop'
    ];
    //const allowDownloads = settings.enableDownloadContent && media?.external_access_options?.download;
    const allowPrinting = settings.enableDownloadContent && media?.external_access_options?.print;
    let items = PSPDFKit.defaultToolbarItems

    //always remove download control
    items = items.filter(item => item.type !== "export-pdf");

    //hide print if printing is disabled
    if (!allowPrinting) {
      items = items.filter(item => item.type !== "print");
    }

    //hide all the annotation tools if annotations are disabled (which is the default);
    if (!settings.showPSPDFKitAnnotationTools) {
      items = items.filter(item => !annotationControls.includes(item.type));
    }

    return items;
  };

  useEffect(() => {
    const container = "#pspdfkit_container";
    const environment = window.location.host;

    Api.getPspdfkitKey(environment).then(license => {
      const PSPDFKitTheme = darkMode ? PSPDFKit.Theme.DARK : PSPDFKit.Theme.LIGHT;
      const toolbarItems = getToolbarItems();
      const initialViewState = new PSPDFKit.ViewState({
        readOnly: true
      });

      PSPDFKit.load({
        licenseKey: license,
        container,
        document: props.document,
        disableWebAssemblyStreaming: false,
        baseUrl: `${window.location.protocol}//${window.location.host}/javascripts/`,
        theme: PSPDFKitTheme,
        toolbarItems: toolbarItems,
        initialViewState
      }).catch((error) => {
        console.error(error);
        props.onPSPDFKitError();
      });
      return () => PSPDFKit && PSPDFKit.unload(container);
    });

  }, []);

  return (
    <div id="pspdfkit_container" />
  );
}
