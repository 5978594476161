export default {
  apiKey: 'AIzaSyAhUKhGdG1cC-jjLlVp80LLl_yQA-o-jYg',
  authDomain: 'follow-up-landing-page.firebaseapp.com',
  databaseURL: 'https://follow-up-landing-page.firebaseio.com',
  projectId: 'follow-up-landing-page',
  storageBucket: 'follow-up-landing-page.appspot.com',
  messagingSenderId: '803025064283',
  appId: '1:803025064283:web:0ca618a1677c81e24d8a7e',
  measurementId: 'G-W5HPTG6TTZ'
};
