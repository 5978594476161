import './_style.scss';

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageUtility, EmailUtility } from 'utilities';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft, faShare } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import CenteredGateWrapper from '../centered-gate-wrapper';
import Spinner from 'components/spinner';

import { RequestAccessEmailTemplate } from 'models/email-templates';

export default function RequestAccessGate({ email = "", ...props }) {
  const localize = LanguageUtility.getLocalization;
  const validateEmail = EmailUtility.validateEmailFormat;

  const [usersEmail, setUsersEmail] = useState(email);
  const [emailBody, setEmailBody] = useState('');

  const [isEmailValid, setIsEmailValid] = useState(validateEmail(email));
  const [isSending, setIsSending] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);


  const sendRequestEmail = async () => {
    let { owner, title, accountTheme } = props;
    let requestAccessEmailProps = new RequestAccessEmailTemplate(usersEmail, owner, title, accountTheme?.logo, emailBody);
    let response = await EmailUtility.sendRequestAccessEmail(requestAccessEmailProps);

    setIsRequestSent(response.success);

    if (response.success) {
      toast.success('Access request sent!');
    } else {
      toast.error('Email failed to send');
    }

    setIsSending(false);
  }

  const handleEmailChange = async (e) => {
    setUsersEmail(e.target.value);
    setIsEmailValid(validateEmail(e.target.value));
  };

  const handleEmailBodyChange = async (e) => {
    setEmailBody(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(usersEmail)) {
      sendRequestEmail();
    }
  }

  //render
  const showEnterButton = isEmailValid && !isSending;

  return (
    <CenteredGateWrapper isLogoHidden={!isRequestSent}>
      <div className="request-access-gate">
        {!isRequestSent && (
          <div>
            <button className="btn back-btn" disabled={isSending} onClick={() => { props.requestAccess(null, true); }}>
              <FontAwesomeIcon icon={faArrowLeft} className="arrow-icon" />
              {localize(props, 'Back Button label', 'Back')}
            </button>
            <div className="information">
              <div className="title">
                <FontAwesomeIcon icon={faLock} className="lock-icon" />
                <span className="request-access-label">
                  {localize(props, 'Request Access Label', 'You need to Request Access')}
                </span>
              </div>
              <div className="message">
                {localize(
                  props,
                  'Request Access message',
                  'This is a private digital sales room, you will need to request access if you would like to access this content.'
                )}
              </div>
            </div>

            <form onSubmit={(e) => handleSubmit(e)}>
              <input className="email-input"
                placeholder={localize(props, 'Enter email placeholder')}
                type="email"
                value={usersEmail}
                onChange={handleEmailChange}
              />
              <textarea
                id="email-body"
                className="email-body"
                placeholder={localize(
                  props,
                  'Enter email body placeholder',
                  'Add message here... (optional)'
                )}
                onChange={(e) => handleEmailBodyChange(e)}
              />
              <div className="actions">
                <button
                  className="btn btn-primary btn-send"
                  style={{ display: showEnterButton ? 'block' : 'none' }}
                  type="submit">
                  {localize(props, 'Enter Button label', 'Enter')}
                </button>

                {isSending && <Spinner isActive={true} />}

              </div>
            </form>
          </div>
        )}

        {isRequestSent && (
          <div className="information">
            <div className="title">
              <FontAwesomeIcon icon={faShare} className="share-icon" />
              <span className="passcode-label">
                {localize(props, 'Request Send Label', 'Request Sent')}
              </span>
            </div>
            <div className="message">
              {localize(
                props,
                'Request Sent Message',
                "You'll receive an email letting you know once your access has been granted by the Digital Sales Room admin."
              )}
            </div>
          </div>
        )}
      </div>
    </CenteredGateWrapper>
  )
}

RequestAccessGate.propTypes = {
  owner: PropTypes.string,
  title: PropTypes.string
};
