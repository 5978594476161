import './index.scss';

import React, { Component, } from 'react';
import MediaItem from 'components/media-grid/media-item/media-item.js';
import { useEditorStore } from 'stores/editorStore';
import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus as darkFaPlus } from '@fortawesome/pro-regular-svg-icons';

export default (props) => {
  const editorStore = useEditorStore();
  return <Sortable editorStore={editorStore} {...props}></Sortable>
}

class Sortable extends Component {
  constructor(props) {
    super(props);
  }

  onEnd = () => {
    const idList = this.$node.sortable('toArray');
    this.setState({ idList });
    this.props.onChange(idList);
  };

  componentDidUpdate(prevProps) {

    const { editContent } = this.props.editorStore;

    if (editContent !== prevProps.editorStore.editContent) {
      this.$node = $(this.refs.sortable);
      this.$node.sortable({
        opacity: this.props.opacity,
        disabled: editContent ? false : true,
        stop: () => this.onEnd(),
        items: '> li',
        tolerance: 'pointer',
        placeholder: 'sortable-placeholder'
      });
    }
  }

  render() {
    const { data = [], company } = this.props;

    return (
      <ul className="sortable-media-list" ref="sortable">
        {this.props.allowAddContent &&
          <div className="add-media-tile">
            <button className="add-media-button" onClick={this.props.onAddMediaClicked}>
              <FontAwesomeIcon size="sm" icon={darkFaPlus} />
            </button>
            <h3>Add New Assets</h3>
            <p>Upload Assets Now</p>
          </div>
        }

        {data.sort((a, b) => a.index - b.index).map((media) => (
          <li className="media-item-container" key={media.id} id={media.id} >
            <MediaItem
              guid={this.props.guid}
              media={media}
              company={company}
              settings={this.props.settings}
              accountSettings={this.props.accountSettings}
              onClick={this.props.onMediaItemClicked}
              onMediaDeleted={this.props.onMediaDeleted} />
          </li >
        ))
        }


      </ul>
    );
  }
}

Sortable.defaultProps = {
  opacity: 1,
  enable: true,
  tolerance: 'pointer'
};
