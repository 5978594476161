import { ThemeUtility } from 'utilities';
import create from 'zustand';

export const useEditorStore = create((set) => ({

  //Interaction States
  changesMade: false,
  editContent: false,
  //action tray open
  //isActionTrayOpen

  //Theme States
  darkMode: ThemeUtility.isDarkMode(),

  //Media Editing States
  isMediaReloading: false,
  deletedMediaIds: [],

  onDarkModeToggle: () => {
    set((state) => {
      const newDarkState = !state.darkMode;
      localStorage.setItem('dark', newDarkState);
      return { ...state, darkMode: newDarkState };
    });
  },

  //Functions
  onChangesMade: () => set({ changesMade: true }),
  onEditingComplete: () => set({
    editContent: false,
    changesMade: false,
    deletedMediaIds: []
  }),
  onEditContentChanged: () => set((state) => ({ editContent: !state.editContent }))
}));
