import React, { useContext } from 'react';
import { SalesContactContext } from 'contexts/sales-contact-context';
import './_dealer-logo.scss';

export default function DealerLogo(props) {
  const context = useContext(SalesContactContext);
  let isLoading = !context;

  if (isLoading) {
    return null;
  }

  let hasDealerLogo = context.hasOwnProperty('dealer_logo');
  let hasDealerName = context.hasOwnProperty('dealer_name');
  return (
    <span className="dealer-container">
      {(hasDealerLogo || hasDealerName) && <div className="drawDown" />}
      <img
        id="dealer-logo"
        className="logo animate"
        alt=""
        src={context.dealer_logo ? context.dealer_logo : ''}
      />
      {!hasDealerLogo && hasDealerName && (
        <div className="logo animate fadeInLeft"> {context.dealer_name} </div>
      )}
    </span>
  );
}
