import './index.scss';
import React, { useState, useEffect } from 'react';
import Api from 'services/api';
import Modal from 'components/modal';
import Modus from 'modus-javascript-bridge/dist/modus-js-bridge.js';
import Avatar from 'components/action-tray/avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { LanguageUtility } from 'utilities';
import PressHoldButton from 'components/press-hold-button';

const localize = LanguageUtility.getLocalization;

export default function MembersModal(props) {
  const filteredMembers = props.users.detailed.filter((x) => { return x.status !== 'removed' });
  const [members, setMembers] = useState(filteredMembers);
  const [membersCount, setMembersCount] = useState(filteredMembers.length);

  const handleCloseModal = () => {
    Modus.closeContainer();
  }

  const onRemoveMember = async (member) => {

    if (member) {
      setTimeout(async () => {
        try {

          const response = await Api.updateMemberStatus(member.email, 'removed');
          let newMembmers = members.filter(x => x.email !== member.email);
          setMembers(newMembmers);
          setMembersCount(newMembmers.length);
        }
        catch (e) {
          console.log(e);
          toast.error(`Failed to remove ${member.email}.`);

        }
      }, 500);
    }
  }

  const getMembersRole = (member, isOwner) => {
    let isManager = props.managers.includes(member.users_id);
    if (isOwner && isManager) return 'Owner/Manager';
    if (isManager) return 'Author/Manager';
    return "Invited Member";
  }

  //render
  return (
    <Modal id="members_list_modal_base" isOpen={true} hideCloseButton={true} fullScreen={true} onRequestClose={props.onRequestClose} v2={true} >
      <div className="members-modal-header">
        <div className="title-bar">
          <div className="title">
            {localize(props, 'Members list label', 'Members')}
            <div className="members-count">{membersCount}</div>
          </div>
          <div className="dsr-name">{props.title}</div>
        </div>
        <button className="btn-close" onClick={() => handleCloseModal()}>
          <i className="fa fa-times"></i>
        </button>
      </div>
      <div className="members-list">
        {members.map((member, i) => {
          let isOwner = props.user.email === member.email;
          let isManager = props.managers.includes(member.users_id);
          let membersRole = getMembersRole(member, isOwner);
          let fullName = null;
          let nameLabel = member.email;
          if (member.first_name) {
            fullName = `${member.first_name} ${member.last_name}`;
            nameLabel = `${fullName} (${member.email})`;
          }

          return (
            <div id={`member-item-${member.email}`} className="member-item" key={member.email}>
              <Avatar fullName={fullName} user={member.email} isHighlighted={isOwner} useRandomColor={false} />
              <div className="info-container">
                <span className="name">{nameLabel}</span>
                <div className="role-container">
                  {(isOwner || isManager) && (
                    <div
                      className="admin-star-tooltip"
                      data-for="admin-tooltip"
                      data-tip={'Digital Sales Room Admin'}
                      place={'top'}>
                      <FontAwesomeIcon
                        id="star-icon"
                        size="xs"
                        icon={faStar}
                        color="#ffffff"
                        style={{ borderRadius: '50%', padding: '4px', backgroundColor: '#4182FF' }}
                      />
                    </div>
                  )}
                  <span className="role">{membersRole}</span>
                </div>
              </div>
              {member?.is_manager &&
                <div className="manager-bubble"></div>
              }

              <PressHoldButton id={`member-btn-${member.email}`} onSuccess={onRemoveMember.bind(null, member)} />
            </div>
          )
        })}
      </div>
    </Modal>
  );
}